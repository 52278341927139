import { LockContent } from "../../components/auth";

export default function Lock() {

    return(
        <>
            <LockContent/>
        </>
    )

}