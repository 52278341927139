import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToaster, Message, Modal } from "rsuite";

import { dictionaryCRUD } from "../../../actions/dictionary";
import { selector } from "../../../api";
import { Divider, Input, notification } from "antd";

const dictionarySchema = Yup.object().shape({
  dictionary_name: Yup.string().required("This field is required"),
});

const initialValues = {
  dictionary_code: "",
  dictionary_name: "",
};

export const DictionaryAdd = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: dictionarySchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { dictionary_code, dictionary_name } = values;
      const recorded_by = user.id;
      const data = {
        dictionary_code,
        dictionary_name,
        recorded_by,
      };
      setLoading(true);
      setTimeout(() => {
        dictionaryCRUD
          .addDictionary([data])
          .then(() => {
            api.success({
              message: "Thank you",
              description:
                "🎉 Congratulations! Your data has been successfully saved!",
              placement: "topRight",
            });
            setIndex(index + 1);
            resetForm();
            setLoading(false);
            setSubmitting(false);
            setOpen(false);
          })
          .catch((error) => {
            setLoading(false);
            setSubmitting(false);
            api.error({
              message: error.message,
              description:
                "Oops! It looks like there is some errors. Please correct your error and try again",
              placement: "topRight",
            });
          });
      }, 2000);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {contextHolder}
      <Modal open={open} onClose={handleClose} size="md">
        <Modal.Header>
          <Modal.Title>New Dictionary</Modal.Title>
        </Modal.Header>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="text-body mb-2">Dictionary Code</label>
                    <Input
                      className="form-control"
                      placeholder="Dictionary code"
                      {...formik.getFieldProps("dictionary_code")}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="text-body mb-2">
                      Dictionary Name{" "}
                      <span className="text-danger">&nbsp;*</span>
                    </label>
                    <Input
                      className="form-control"
                      placeholder="Dictionary name"
                      {...formik.getFieldProps("dictionary_name")}
                      status={
                        formik.touched.dictionary_name &&
                        formik.errors.dictionary_name &&
                        "error"
                      }
                    />
                    {formik.touched.dictionary_name &&
                      formik.errors.dictionary_name && (
                        <div
                          className="fw-light mt-1"
                          style={{ fontSize: "12px" }}
                        >
                          <span role="alert" className="text-danger">
                            {formik.errors.dictionary_name}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Divider style={{ marginTop: -10 }} />
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                  <span className="fs-18 text-danger"> * &nbsp;</span> Required
                  field
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                  <button
                    onClick={handleClose}
                    type="button"
                    className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                  >
                    <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Save
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
