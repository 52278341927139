import { LoginContent } from "../../components/auth";

export default function Login() {

    return(
        <>
            <LoginContent/>
        </>
    )

}