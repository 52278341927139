import { useSelector } from "react-redux";

export const User = () => {
  const user = useSelector((state) => state.authReducer.signUser);
  return user;
};
export const Auth = () => {
  const auth = useSelector((state) => state.authReducer.isAuthenticated);
  return auth;
};

export const Lock = () => {
  const lock = useSelector((state) => state.authReducer.isLocked);
  return lock;
};

export const Token = () => {
  const token = useSelector((state) => state.authReducer.signToken);
  return token;
};
export const AccessToken = () => {
  const access = useSelector((state) => state.authReducer.signToken.access);
  return access;
};
