import { useEffect, useState } from "react";
import { reportCRUD } from "../../../actions/report";
import { char, number } from "../../../utils";
import Chart from "react-apexcharts";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const QuarterCollection = (props) => {

    const { year } = props;

    const [labels, setLabels] = useState([]);
    const [series, setSeries] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
      setLoading(true);
      reportCRUD
        .loadQuarterCollectionData(year)
        .then((resp) => {
          setLabels(resp.data.map((quarter) => quarter.quarter));
          setSeries(resp.data.map((quarter) => quarter.total_paid_amount));
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }, [year]);

    console.log(labels);

    const state = {
      series: [
        {
          name: "Units",
          data: series,
        },
      ],
      options: {
        chart: {
          height: 3500,
          type: "bar",
          //   events: {
          //     dataPointSelection: (event, chartContext, config) => {
          //       const dataPointIndex = config.dataPointIndex;
          //       const label = state.options.xaxis.categories[dataPointIndex];
          //       setStatus(char.toLowerCase(label));
          //       setOpen(true);
          //     },
          //   },
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return char.toUpperCase(number.fShortenNumber(val));
          },
          offsetY: -25,
          style: {
            fontSize: "18px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          categories: labels,
          position: "bottom",
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              },
            },
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
          text: "Quarterly Collection of " + year,
          floating: true,
          offsetY: -5,
          align: "center",
          style: {
            color: "#2fb2e6",
          },
        },
      },
    };

    return (
      <>
        {loading ? (
          <div className="text-center mt-4 mb-4">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
            />
          </div>
        ) : (
          <Chart
            options={state.options}
            series={state.series}
            type="bar"
            height={350}
          />
        )}
      </>
    );

}