import { useEffect, useRef, useState } from "react";
import { selector } from "../../../../api";
import { useReactToPrint } from "react-to-print";
import { Button, DateRangePicker, Loader, Pagination, SelectPicker } from "rsuite";
import FilePdfOIcon from "@rsuite/icons/legacy/FilePdfO";
import FileExcelOIcon from "@rsuite/icons/legacy/FileExcelO";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { char, number } from "../../../../utils";
import { reportCRUD } from "../../../../actions/report";

const { afterToday } = DateRangePicker;


export const GeneralCollection = () => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [postsPerPage, setePostPerPage] = useState(10);
    const [nextPage, setNextPage] = useState(0);
    const [yearList, setYearList] = useState([]);
    const [year, setYear] = useState("");
    const [date, setDate] = useState("");
    const [searchYear, setSearchYear] = useState("");
    const user = selector.User();

    const tableRef = useRef();
    const dataRef = useRef();

    useEffect(() => {
      setLoading(true);
      setTimeout(() => {
        reportCRUD
          .loadPaymentLogsReportInvoice(postsPerPage, nextPage, date, "", "", "id")
          .then((resp) => {
            setData(resp.data.results);
            console.log(resp.data.results);
            setCount(resp.data.count);
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          });
      }, 1000);
    }, [postsPerPage, nextPage, page, date]);


    const handleChangePage = (event) => {
      setData([]);
      setPage(event);
      setNextPage((event - 1) * postsPerPage);
    };

    const handleChange = (selectedOption) => {
      setData([]);
      setePostPerPage(selectedOption);
      setNextPage(0);
      setPage(1);
    };

    const handleGeneratePdf = useReactToPrint({
      content: () => dataRef.current,
    });

    return (
      <>
        <div className="row">
          <div className="col-12 card">
            <div className="card-body">
              <div className="row">
                {/* <div className="col-md-2 col-sm-6">
                  <SelectPicker
                    data={yearList.map((dt) => ({
                      value: dt.id,
                      label: dt.name,
                    }))}
                    block={true}
                    placeholder={"Select financial year"}
                    onSearch={(e) => setSearchYear(e)}
                    onChange={(e) => setYear(e)}
                    onClean={() => setYear("")}
                  />
                </div> */}
                <div className="col-md-2 col-sm-6">
                  {" "}
                  <DateRangePicker
                    cleanable
                    placement="bottomEnd"
                    onClean={() => setDate([])}
                    onShortcutClick={(e) => {
                      setDate([
                        moment(e.value[0]).format("YYYY-MM-DD"),
                        moment(e.value[1]).add(1, "days").format("YYYY-MM-DD"),
                      ]);
                    }}
                    onOk={(e) =>
                      setDate([
                        moment(e[0]).format("YYYY-MM-DD"),
                        moment(e[1]).add(1, "days").format("YYYY-MM-DD"),
                      ])
                    }
                    shouldDisableDate={afterToday()}
                    placeholder="Select date range"
                    className="w-100"
                  />
                </div>
                <div className="col-md-6"></div>
                {data?.length ? (
                  <>
                    <div className="col-md-2 col-sm-6">
                      <Button
                        color="green"
                        appearance="primary"
                        startIcon={<FileExcelOIcon />}
                        block={true}
                        disabled={!data.length}
                      >
                        <DownloadTableExcel
                          filename="Report"
                          sheet="sheet1"
                          currentTableRef={tableRef.current}
                        >
                          Download Excel
                        </DownloadTableExcel>
                      </Button>
                    </div>
                    <div className="col-md-2 col-sm-6">
                      <Button
                        color="red"
                        appearance="primary"
                        startIcon={<FilePdfOIcon />}
                        block={true}
                        onClick={handleGeneratePdf}
                        disabled={!data.length}
                      >
                        Download PDF
                      </Button>
                    </div>
                  </>
                ) : null}
              </div>
              <hr />
              <div className="row">
                <div className="col-12" ref={dataRef}>
                  <div className="printHead">
                    <p className="text-center">
                      <img
                        width={100}
                        className="mt-0 mb-2"
                        src="assets/images/suza.jpeg"
                        alt=""
                      />
                    </p>
                    <p className="text-center fw-bold head1">
                      {char.toTitleCase("state university of Zanzibar")}
                    </p>
                    <p className="text-center mt-3 mb-3 head2">
                      {char.toUpperCase("general collection report")}
                    </p>
                    <p className="text-start">
                      Periord :{" "}
                      <span className="fw-bold">
                        {date &&
                          moment(date[0]).format("ddd. DD MMM YYYY") +
                            " to " +
                            moment(date[1]).format("ddd. DD MMM YYYY")}
                      </span>
                    </p>
                    <p className="text-end">
                      Printed By :{" "}
                      <span className="fw-bold">
                        {char.toUpperCase(user.username)}
                      </span>
                    </p>
                    <p className="text-end mb-2">
                      Printed at :{" "}
                      <span className="fw-bold">
                        {moment().format("ddd DD MMM YYYY")}
                      </span>
                    </p>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-bordered table-sm">
                      <thead className="table-head">
                        <tr>
                          <th style={{ width: 50 }}>#</th>
                          <th>Date</th>
                          <th>Payer Name</th>
                          <th>Contol No</th>
                          <th>Receipt No</th>
                          <th>Bank Cannel</th>
                          <th>Bank Name</th>
                          <th>Account No.</th>
                          <th className="text-end">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.map((dt, i) => (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              {moment(dt.created_at).format("ddd. DD MMM YYYY")}
                            </td>
                            <td>
                              {dt.pyrname
                                ? char.toUpperCase(dt.pyrname)
                                : "N/A"}
                            </td>
                            <td>
                              {dt.logs
                                ? char.toUpperCase(dt.logs.control_number)
                                : "N/A"}
                            </td>
                            <td>
                              {dt.pspreceiptnumber
                                ? char.toTitleCase(dt.pspreceiptnumber)
                                : "N/A"}
                            </td>
                            <td>
                              {dt.usdpaychnl
                                ? char.toUpperCase(dt.usdpaychnl)
                                : "N/A"}
                            </td>
                            <td>
                              {dt.pspname
                                ? char.toUpperCase(dt.pspname)
                                : "N/A"}
                            </td>
                            <td>
                              {dt.ctraccnum
                                ? char.toUpperCase(dt.ctraccnum)
                                : "N/A"}
                            </td>
                            <td className="text-end">
                              {number.fNumberDoublePoint(dt.paidamount)}
                            </td>
                          </tr>
                        ))}
                        {!data.length && (
                          <tr>
                            <td colSpan={8} className="text-center">
                              {char.toLowerCase("no data to print")}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div hidden>
                    <div className="table-responsive" ref={tableRef}>
                      <table className="table table-bordered table-sm">
                        <thead className="table-head">
                          <tr>
                            <th style={{ width: 50 }}>#</th>
                            <th>Transaction Date</th>
                            <th>Transaction ID</th>
                            <th>SP Code</th>
                            <th>PSP Receipt Number</th>
                            <th>Payment Reference ID</th>
                            <th>Paid Control No</th>
                            <th>Currency</th>
                            <th>Paid Channel</th>
                            <th>Bank Name</th>
                            <th>Account Number</th>
                            <th>Payer Name</th>
                            <th>Payer Phone No</th>
                            <th>Payer Email</th>
                            <th className="text-end">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((dt, i) => (
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                {moment(dt.created_at).format(
                                  "ddd. DD MMM YYYY"
                                )}
                              </td>
                              <td>
                                {dt.trxid ? char.toUpperCase(dt.trxid) : "N/A"}
                              </td>
                              <td>
                                {dt.spcode
                                  ? char.toUpperCase(dt.spcode)
                                  : "N/A"}
                              </td>
                              <td>
                                {dt.pspreceiptnumber
                                  ? char.toUpperCase(dt.pspreceiptnumber)
                                  : "N/A"}
                              </td>
                              <td>
                                {dt.payrefid
                                  ? char.toUpperCase(dt.payrefid)
                                  : "N/A"}
                              </td>
                              <td>
                                {dt.logs ? dt.logs.control_number : "N/A"}
                              </td>
                              <td>
                                {dt.logs
                                  ? char.toUpperCase(dt.logs.bill_currency)
                                  : "N/A"}
                              </td>
                              <td className="text-end">
                                {dt.usdpaychnl
                                  ? char.toUpperCase(dt.usdpaychnl)
                                  : "N/A"}
                              </td>
                              <td className="text-end">
                                {dt.pspname
                                  ? char.toUpperCase(dt.pspname)
                                  : "N/A"}
                              </td>
                              <td className="text-end">
                                {dt.ctraccnum
                                  ? char.toUpperCase(dt.ctraccnum)
                                  : "N/A"}
                              </td>
                              <td className="text-end">
                                {dt.pyrname
                                  ? char.toUpperCase(dt.pyrname)
                                  : "N/A"}
                              </td>
                              <td className="text-end">
                                {dt.pyrCellNum
                                  ? char.toUpperCase(dt.pyrCellNum)
                                  : "N/A"}
                              </td>
                              <td className="text-end">
                                {dt.pyremail
                                  ? char.toUpperCase(dt.pyremail)
                                  : "N/A"}
                              </td>
                              <td className="text-end">
                                {number.fNumberDoublePoint(dt.paidamount)}
                              </td>
                            </tr>
                          ))}
                          {!data.length && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                {char.toLowerCase("no data to print")}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-2 mt-3">
                  <span>Total rows : &nbsp; {count}</span>
                </div>
                <div className="col-10">
                  <div className="row g-2 mt-2 justify-content-start justify-content-md-end align-items-center">
                    <div className="col-auto paginate">
                      <Pagination
                        prev
                        last
                        next
                        first
                        ellipsis
                        boundaryLinks={true}
                        maxButtons={5}
                        size="sm"
                        limitOptions={[5, 10, 20, 50, 100, count]}
                        layout={["limit", "|", "pager", "|", "skip"]}
                        total={count}
                        limit={postsPerPage}
                        activePage={page}
                        onChangePage={handleChangePage}
                        onChangeLimit={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {loading && (
              <Loader
                size="lg"
                speed="slow"
                center
                backdrop
                style={{ color: "orange" }}
                content="loading..."
                vertical
              />
            )}
          </div>
        </div>
      </>
    );

}