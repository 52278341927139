import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { authCRUD, authActions } from "../../../actions/auth";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { selector } from "../../../api";
import { useFormik } from "formik";
import * as Icon from "react-feather";
import { char } from "../../../utils";
import { Input, Card } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

const loginSchema = Yup.object().shape({
  password: Yup.string().required("Password is required"),
});

const initialValues = {
  password: "",
};

export const LockContent = () => {
  const user = selector.User();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("persist:root");
    navigate("/", { replace: true });
    document.location.reload();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        const data = { username: user.username, password: values.password };
        authCRUD
          .loadUser(user.username)
          .then((resp) => {
            setLoading(false);
            if (!resp.data.is_active) {
              setStatus("Sorry....! You are not active user");
            } else {
              dispatch(authActions.getUser(resp.data));
              authCRUD
                .loadToken(data)
                .then((resp) => {
                  dispatch(authActions.setAuth(true));
                  dispatch(authActions.setLock(null));
                  dispatch(authActions.getToken(resp.data));
                  navigate(-1);
                  setLoading(false);
                })
                .catch((error) => {
                  if (error) {
                    setLoading(false);
                    setSubmitting(false);
                    setStatus("Sorry....! You are not active user");
                  }
                });
            }
          })
          .catch(() => {
            setLoading(false);
            setSubmitting(false);
            setStatus("The login detail is incorrect");
          });
      }, 1000);
    },
  });
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4 position-absolute top-50 start-50 translate-middle">
            <Card bordered={false}>
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 text-center">
                      <img
                        width={150}
                        className="mt-0 mb-2"
                        src="assets/images/suza.jpeg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <p className="text-center h3">
                        The State University of Zanzibar
                      </p>
                      <p className="text-center h3 mt-3 fw-bold ff-primary">
                        SUZA BILLING PORTAL
                      </p>
                      <p className="text-center h4 mt-3 fw-bold ff-primary">
                        LOCK SCREEN
                      </p>
                      {formik.status ? (
                        <div className="alert alert-danger mt-3">
                          <div className="alert-text fw-light text-center">
                            {formik.status}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <hr />
                      <form onSubmit={formik.handleSubmit}>
                        <div className="row ms-5 me-5 mt-3">
                          <div className="col-12">
                            <div className="form-group">
                              <label className="form-label fw-bold">
                                Password
                                <span className="ms-2 text-danger">*</span>
                              </label>
                              <Input.Password
                                iconRender={(visible) =>
                                  visible ? (
                                    <EyeTwoTone />
                                  ) : (
                                    <EyeInvisibleOutlined />
                                  )
                                }
                                size="large"
                                variant="filled"
                                placeholder="Password"
                                {...formik.getFieldProps("password")}
                                status={
                                  formik.touched.password &&
                                  formik.errors.password &&
                                  "error"
                                }
                              />
                              {formik.touched.password &&
                                formik.errors.password && (
                                  <div
                                    className="fw-light mt-1"
                                    style={{ fontSize: "15px" }}
                                  >
                                    <span role="alert" className="text-danger">
                                      {formik.errors.password}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="row ms-5 me-5 mt-3">
                          <div className="col-12">
                            <button
                              type="submit"
                              className="btn btn-success waves-effect waves-light w-100"
                              disabled={formik.isSubmitting}
                            >
                              {!loading ? (
                                <span className="indicator-label">
                                  <span className="bi bi-box-arrow-in-right"></span>{" "}
                                  Unlock
                                </span>
                              ) : (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  Please wait.....
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                              )}
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className="text-center mt-3">
                          <p>
                            <span className="fw-bold">Not you ? &nbsp;</span>{" "}
                            <Link to={"#"} onClick={handleLogout}>
                              Please Login
                            </Link>
                          </p>
                        </div>
                        <hr />
                        <div className="row mt-1">
                          <div className="col-12 text-center">
                            <p className="mb-0">
                              © {new Date().getFullYear()} &nbsp;{" "}
                              <span className="fw-bold text-dark">
                                SUZA | BiMS
                              </span>
                            </p>
                            <p className="fw-light h6 text-success fs-10 fst-italic mt-2">
                              {" "}
                              ---- catalist for social change ----
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </>
  );
};
