import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { Modal, Divider, useToaster, Message, Loader } from "rsuite";
import { invoiceCRUD } from "../../../../actions/invoice";
import moment from "moment";
import { amountWord, char, number } from "../../../../utils";
import { gatewayCRUD } from "../../../../actions/gateway";

export const PaymentPrint = (props) => {
  const { open, setOpen, dataId } = props;
  const toaster = useToaster();
  const [data, setData] = useState({});
  const [invo, setInvo] = useState({});
  const [loading, setLoading] = useState(false);
  const dataRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (dataId !== null) {
        gatewayCRUD
          .loadInvoicePaymentDetails(dataId)
          .then((resp) => {
            setData(resp.data);
          })
          .catch((error) => {
            toaster.push(
              <Message showIcon type={"error"} header={"Error"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Oooh Sorry...!</p>
                  <p className="text-dark fs-12">
                    Something went wrong, Check your network connection.
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }, 500);
  }, [dataId]);

  useEffect(() => {
    if (data && data.logs) {
      setLoading(true);
      setTimeout(() => {
        invoiceCRUD
          .invoicePrint(data.logs.invoice_id)
          .then((resp) => {
            setInvo(resp.data);
          })
          .catch((error) => {})
          .finally(() => {
            setLoading(false);
          });
      }, 500);
    }
  }, [data]);

  const handleGeneratePdf = useReactToPrint({
    content: () => dataRef.current,
  });

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="lg">
        <Modal.Header>
          <Modal.Title>
            <div className="row">
              <div className="col-md-6 col-sm-12">Invoice</div>
              <div className="col-md-6 col-sm-12 d-flex flex-row-reverse">
                <button
                  className="btn btn-soft-success btn-sm waves-effect waves-light"
                  onClick={handleGeneratePdf}
                >
                  <i className="bi bi-printer-fill m-r-5" /> Print
                </button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          <div
            className="container-fluid pt-3"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div ref={dataRef}>
                      <div className="clearfix">
                        <div className="text-center">
                          <div
                            className="mb-2 text-center"
                            style={{ marginTop: -5 }}
                          >
                            <span>
                              <img
                                src="assets/images/suza.jpeg"
                                height={100}
                                alt=""
                              />
                            </span>
                          </div>
                          <p className="h6 fs-16 text-primary">
                            Revolution Government of Zanzibar
                          </p>
                          <p className="h6 fs-15 text-primary fw-bold">
                            State University of Zanzibar
                          </p>
                          <p className="h6 fs-15 text-primary">
                            Exchequer Receipt
                          </p>
                        </div>
                      </div>
                      <div
                        className="mt-2"
                        style={{ borderBottom: "1px dashed #000000" }}
                      ></div>
                      <div className="row mt-2">
                        <div className="col-3">
                          <p className="fs-6">
                            <span>Receipt Number</span> &emsp; &emsp;{" "}
                          </p>
                          <p className="fs-6">
                            <span>Received From</span> &emsp; &emsp;{" "}
                          </p>
                          <p className="fs-6">
                            <span>Amount</span> &emsp; &emsp;{" "}
                          </p>
                          <p className="fs-6">
                            <span>Amount in Words</span> &emsp; &emsp;{" "}
                          </p>
                          <p className="fs-6">
                            <span>Outstanding Balance</span> &emsp; &emsp;{" "}
                          </p>
                          <p className="fs-6">
                            <span>In respect of</span> &emsp; &emsp;{" "}
                          </p>
                        </div>
                        <div className="col-6">
                          <p className="fs-6">
                            <span className="fw-bold">
                              : &nbsp; {data ? data.payrefid : "N/A"}
                            </span>
                          </p>
                          <p className="fs-6">
                            <span className="fw-bold">
                              : &nbsp;{" "}
                              {data.pyrname
                                ? char.toUpperCase(data.pyrname)
                                : "N/A"}
                            </span>
                          </p>
                          <p className="fs-6">
                            <span>
                              : &nbsp;{" "}
                              {data
                                ? number.fNumberDoublePoint(data.paidamount)
                                : "N/A"}
                            </span>
                          </p>
                          <p className="fs-6">
                            <span>
                              : &nbsp;{" "}
                              {data
                                ? char.toLowerCase(
                                    amountWord.toWords(
                                      number.fNumber(data.paidamount)
                                    )
                                  )
                                : "N/A"}
                            </span>
                          </p>
                          <p className="fs-6">
                            <span>
                              : &nbsp;{" "}
                              {data
                                ? number.fNumberDoublePoint(
                                    data.billamount - data.paidamount
                                  )
                                : "N/A"}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: -18 }}>
                        <div className="col-3"></div>
                        <div className="col-6">
                          <p className="fw-bold fs-6">Item Description(s)</p>
                        </div>
                        <div className="col-3">
                          <p className="fw-bold fs-6 text-end">Item Amount</p>
                        </div>
                      </div>
                      <hr />
                      <div className="row" style={{ marginTop: -20 }}>
                        <div className="col-3"></div>
                        <div className="col-9">
                          {invo.invoice?.map((dt, i) => (
                            <div className="row mt-2" key={i}>
                              <div className="col-9">
                                {":"} &nbsp;{dt.description}
                              </div>
                              <div className="col-3 text-end">
                                {number.fNumberDoublePoint(
                                  dt.equivalent_amount
                                )}
                              </div>
                            </div>
                          ))}
                          <hr />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6"></div>
                        <div className="col-3 fw-bold">
                          {"Total Billed Amount"}
                        </div>
                        <div className="text-end col-3 fw-bold">
                          {number.fNumberDoublePoint(invo.equivalent_amount)}{" "}
                          &nbsp;({"TZS"})
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6"></div>
                        <div className="col-6">
                          <hr />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-3">{"Bill Reference"}</div>
                        <div className="col-6 fw-bold">
                          {":"} &nbsp; {1}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3">{"Payment Control Number"}</div>
                        <div className="col-6 fw-bold">
                          {":"} &nbsp;{" "}
                          {data.logs ? data.logs.control_number : "N/A"}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3">{"Payment Date"}</div>
                        <div className="col-6">
                          {":"} &nbsp;{" "}
                          {moment(data.trxdttm).format("D MMM YYYY")}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3">{"Issued By"}</div>
                        <div className="col-6">
                          {":"} &nbsp;{" "}
                          {invo.created_by
                            ? invo.created_by.first_name
                              ? char.toUpperCase(invo.created_by.first_name)
                              : char.toUpperCase(invo.created_by.username)
                            : "N/A"}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3">{"Issued Date"}</div>
                        <div className="col-6">
                          {":"} &nbsp;{" "}
                          {moment(data.created_at).format("D MMM YYYY")}
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-3">{"Signature"}</div>
                        <div className="col-6">
                          {":"} &nbsp;{" "}
                          {
                            "........................................................................"
                          }
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-12">
                          <div className="text-center text-primary">
                            <p>
                              BiMIS © {new Date().getFullYear()} All Rights
                              Reserved (SUZA)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-print-none">
                      <div className="text-end"></div>
                    </div>
                  </div>
                </div>{" "}
                {/* end card*/}
              </div>{" "}
              {/* end col */}
            </div>
            {loading && (
              <Loader
                size="lg"
                speed="slow"
                center
                backdrop
                style={{ color: "cyan" }}
                content="loading..."
                vertical
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
