import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { selector } from "../../../api";
import { invoiceCRUD } from "../../../actions/invoice";
import { TableShared } from "../../../shared";
import { char, number } from "../../../utils";
import { Button, Dropdown, Space, Tooltip } from "antd";
import {
  DollarOutlined,
  MoreOutlined,
  PrinterOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { InvoicePrint } from "./print/invoice";
import { CustomerInvoiceAdd } from "./add/customer";
import { Message, useToaster } from "rsuite";
import { InvoicePayment } from "./payment";

export const StudentInvoiceContent = () => {
  const [open, setOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [studentOpen, setStudentOpen] = useState(false);
  const [printOpen, setPrintOpen] = useState(false);
  const [dataId, setDataId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [postsPerPage, setePostPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(0);
  const [value, setValue] = useState("");
  const [index, setIndex] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortType, setSortType] = useState("");
  const navigate = useNavigate();

  const toaster = useToaster();

  const user = selector.User();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      invoiceCRUD
        .loadStudentInvoice(postsPerPage, nextPage, value, "-id")
        .then((resp) => {
          setData(resp.data.results);
          setCount(resp.data.count);
        })
        .catch(() => {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">
                  Something went wrong, Check your network connection.
                </p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  }, [page, postsPerPage, value, index, nextPage]);

  const defaultColumns = [
    {
      key: "sn",
      title: "#",
      dataIndex: "sn",
      fixed: true,
      width: 70,
    },
    {
      key: "reg",
      title: "Reg No.",
      dataIndex: "reg",
      sortable: true,
      width: 150,
    },
    {
      key: "name",
      title: "Full Name",
      dataIndex: "name",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "ref",
      title: "Ref No.",
      dataIndex: "ref",
      sortable: true,
      width: 150,
    },
    {
      key: "control_number",
      title: "Control No",
      dataIndex: "control_number",
      sortable: true,
      width: 130,
    },
    {
      key: "control_number_expiry_date",
      title: "Control No Expiry",
      dataIndex: "control_number_expiry_date",
      sortable: true,
      width: 140,
    },
    {
      key: "currency",
      title: "Currency",
      dataIndex: "currency",
      sortable: true,
      width: 90,
    },
    {
      key: "equivalent_amount",
      title: "Amount",
      dataIndex: "equivalent_amount",
      sortable: true,
      width: 140,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sortable: true,
      width: 80,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 70,
      render: (record) => (
        <Dropdown
          menu={{
            items: record.paid
              ? [
                  {
                    label: "Print",
                    key: "print",
                    icon: <PrinterOutlined />,
                  },
                  {
                    label: "Check Payment",
                    key: "payment",
                    icon: <DollarOutlined />,
                  },
                ]
              : [
                  {
                    label: "Print",
                    key: "print",
                    icon: <PrinterOutlined />,
                  },
                ],
            onClick: (event) => {
              switch (event.key) {
                case "print":
                  setPrintOpen(true);
                  setDataId(record.id);
                  break;
                case "payment":
                  setOpen(true);
                  setDataId(record.id);
                  break;
                default:
              }
            },
          }}
          trigger={["click"]}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <Space>
            <Button type="dashed" icon={<MoreOutlined />} size={"middle"} />
          </Space>
        </Dropdown>
      ),
    },
  ];

  const dataItem = data?.map((dt, i) => ({
    id: dt.id,
    paid: dt.is_paid,
    sn: i + 1,
    post: dt.is_posted,
    reg: dt.customer
      ? char.toUpperCase(dt.customer.registration_number)
      : "N/A",
    name: dt.customer ? char.toUpperCase(dt.customer.name) : "N/A",
    ref: dt.name ? char.toUpperCase(dt.name) : "N/A",
    control_number: dt.control_number ? dt.control_number : "N/A",
    control_number_expiry_date: dt.control_number_expiry_date
      ? moment(dt.control_number_expiry_date).format("DD MMM YYYY")
      : "N/A",
    currency: dt.currency ? char.toUpperCase(dt.currency.code) : "N/A",
    equivalent_amount: dt.equivalent_amount ? (
      <span className="fw-bold">
        {number.fNumberDoublePoint(dt.equivalent_amount)}
      </span>
    ) : (
      "N/A"
    ),
    status: (
      <Tooltip
        title={
          dt.is_paid ? (
            char.toUpperCaseFirst("Invoice paid")
          ) : dt.status ? (
            <span className="h6 fs-15 text-white">
              {char.toUpperCaseFirst(dt.status)}
            </span>
          ) : (
            <span className="h6 fs-15 text-white">{"No status yet"}</span>
          )
        }
        color={
          dt.is_paid
            ? "lime"
            : dt.status_code
            ? dt.status_code === 7101
              ? "green"
              : dt.status_code === 200 || dt.status_code === 201
              ? "cyan"
              : dt.status_code === 7201
              ? "red"
              : "orange"
            : "orange"
        }
        key={"green"}
      >
        <Button
          onClick={() =>
            toaster.push(
              <Message
                showIcon
                type={
                  dt.is_paid
                    ? "success"
                    : dt.status_code === 7101
                    ? "success"
                    : dt.status_code === 200
                    ? "info"
                    : dt.status_code === 201
                    ? "info"
                    : dt.status_code === 7201
                    ? "error"
                    : "warning"
                }
                closable
              >
                {dt.is_paid
                  ? "Invoice paid successfully"
                  : dt.status_code
                  ? dt.status_code === 7101
                    ? "7101 : Control number successfully received"
                    : dt.status_code === 200 || dt.status_code === 201
                    ? "200 : Waiting control number"
                    : dt.status_code === 7201
                    ? "7201 : Control number failed to be received"
                    : `${dt.status_code} : No controll number receive`
                  : `${dt.status_code} : No controll number receive`}
              </Message>,
              { placement: "topCenter", duration: 7000 }
            )
          }
        >
          {dt.is_paid ? "Paid" : dt.status_code}
        </Button>
      </Tooltip>
    ),
  }));

  const button = (
    <Dropdown
      menu={{
        items: [
          {
            label: "SUZA",
            key: "suza",
            icon: <PlusCircleOutlined />,
          },
          {
            label: "Student",
            key: "student",
            icon: <PlusCircleOutlined />,
          },
          {
            label: "Customer",
            key: "customer",
            icon: <PlusCircleOutlined />,
          },
          {
            label: "Loan Board",
            key: "loan",
            icon: <PlusCircleOutlined />,
          },
        ],
        onClick: (event) => {
          switch (event.key) {
            case "suza":
              break;
            case "student":
              setStudentOpen(true);
              break;
            case "customer":
              setCustomerOpen(true);
              break;
            case "loan":
              break;
            default:
          }
        },
      }}
      trigger={["click"]}
      placement="bottomRight"
      arrow={{ pointAtCenter: true }}
    >
      <Space>
        <button className="btn btn-primary bg-gradient btn-border">
          <span className="bi bi-plus-circle la-lg"></span> &nbsp; New Invoice
        </button>
      </Space>
    </Dropdown>
  );

  return (
    <>
      <CustomerInvoiceAdd
        open={customerOpen}
        setOpen={setCustomerOpen}
        index={index}
        setIndex={setIndex}
      />
      <InvoicePrint open={printOpen} setOpen={setPrintOpen} dataId={dataId} />
      <InvoicePayment open={open} setOpen={setOpen} dataId={dataId} />
      <div className="container-fluid">
        <div className="row mb-2" style={{ marginTop: -30 }}>
          <div className="col-12">
            <p className="h6 fs-18 fw-bold text-dark">Invoice</p>
          </div>
          <hr />
        </div>
        <TableShared
          setOpen={setOpen}
          loading={loading}
          data={dataItem}
          setData={setData}
          page={page}
          setPage={setPage}
          count={count}
          postsPerPage={postsPerPage}
          setePostPerPage={setePostPerPage}
          setNextPage={setNextPage}
          value={value}
          setValue={setValue}
          defaultColumns={defaultColumns}
          button={button}
          link={"#"}
          showButton={true}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortType={sortType}
          setSortType={setSortType}
          setLoading={setLoading}
        />
      </div>
    </>
  );
};
