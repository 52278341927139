import { useLocation } from "react-router-dom";
import { AdmissionInvoiceContent, CustomerInvoiceContent, InvoiceContent, StudentInvoiceContent } from "../../components/invoice";

export default function Invoice() {
  const location = useLocation();
  return (
    <>
      {location.state && location.state.page === "invoice" ? (
        <InvoiceContent />
      ) : location.state && location.state.page === "student" ? (
        <StudentInvoiceContent />
      ) : location.state && location.state.page === "admission" ? (
        <AdmissionInvoiceContent />
      ) : location.state && location.state.page === "customer" ? (
        <CustomerInvoiceContent />
      )  : (
        <InvoiceContent />
      )}
    </>
  );
}
