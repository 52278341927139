import axios from "axios";
import { adjustment as apiUrl } from "../../api";

//============================================================ validation crud =========================================================================
export function loadAllocation(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/allocation/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadLoanBoardAllocation(
  pageLimit,
  page,
  board,
  invoice,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/allocation/list?limit=${pageLimit}&offset=${page}&loan_board__id=${board}&is_invoiced=${invoice}&search=${search}&ordering=${order}`
  );
}

export function loadAllocationId(id) {
  return axios.get(`${apiUrl}/allocation/id/list?id__in=${id}`);
}

export function loadAllocationAmount(id) {
  return axios.get(`${apiUrl}/allocation/list?id__in=${id}`);
}

export function addAllocation(data) {
  return axios.post(`${apiUrl}/allocation`, data);
}

export function addAllocationInvoiced(id, data) {
  return axios.put(`${apiUrl}/allocation/invoiced/${id}`, data);
}

//============================================================ validation crud =========================================================================
export function loadAllocationItem(pageLimit, page, allocation, search, order) {
  return axios.get(
    `${apiUrl}/allocation/item/list?limit=${pageLimit}&offset=${page}&allocation__id__in=${allocation}&search=${search}&ordering=${order}`
  );
}

export function loadAllocationItemList(allocation) {
  return axios.get(
    `${apiUrl}/allocation/item/list?allocation__id__in=${allocation}`
  );
}

export function addAllocationItem(data) {
  return axios.post(`${apiUrl}/allocation/item`, data);
}

//============================================================ allocated crud =========================================================================
export function loadAllocatedStudent(
  pageLimit,
  page,
  allocation,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/allocated-student/list?limit=${pageLimit}&offset=${page}&allocation__id__in=${allocation}&search=${search}&ordering=${order}`
  );
}

export function addAllocatedStudent(data) {
  return axios.post(`${apiUrl}/allocated-student`, data);
}

//============================================================ validation crud =========================================================================
export function loadAllocationType(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/allocation/type/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function addAllocationType(data) {
  return axios.post(`${apiUrl}/allocation/type`, data);
}

//============================================================ validation crud =========================================================================
export function loadAllocationJournal(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/journal/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function addJournal(data) {
  return axios.post(`${apiUrl}/journal`, data);
}

//============================================================ validation crud =========================================================================

export function loadAllocationJournalId(allocation) {
  return axios.get(
    `${apiUrl}/journal/allocation/id/list?allocation__id__in=${allocation}`
  );
}

export function addAllocationJournal(data) {
  return axios.post(`${apiUrl}/journal/allocation`, data);
}

//============================================================ debit crud =========================================================================
export function loadDebitJournal(pageLimit, page, journal, search, order) {
  return axios.get(
    `${apiUrl}/journal/debit/list?limit=${pageLimit}&offset=${page}&journal__id=${journal}&search=${search}&ordering=${order}`
  );
}

export function addDebitJournal(data) {
  return axios.post(`${apiUrl}/journal/debit`, data);
}

//============================================================ credit crud =========================================================================
export function loadCreditJournal(pageLimit, page, journal, search, order) {
  return axios.get(
    `${apiUrl}/journal/credit/list?limit=${pageLimit}&offset=${page}&journal__id=${journal}&search=${search}&ordering=${order}`
  );
}

export function addCreditJournal(data) {
  return axios.post(`${apiUrl}/journal/credit`, data);
}

//============================================================ credit crud =========================================================================
export function addJournalTransaction(data) {
  return axios.post(`${apiUrl}/journal/transaction`, data);
}

//============================================================ validation crud =========================================================================
export function loadPostedAllocation(batch, academic, financial, board) {
  const respo = axios
    .get(
      `${apiUrl}/allocation/list?batch_no=${batch}&academic_year__id=${academic}&financial_year__id=${financial}&loan_board__acronym__in=${board}`
    )
    .then((resp) => {
      if (resp.data.length >= 1) {
        return true;
      } else {
        return false;
      }
    })
    .catch(() => {});

  return respo;
}

export function loadCheckAllocationType(code) {
  return axios.get(`${apiUrl}/allocation/type/list?code__in=${code}`);
}
