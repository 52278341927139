import axios from "axios";
import { gateway as apiUrl } from "../../api";

export function addInterInvoice(data) {
  return axios.post(`${apiUrl}/invoice`, data);
}

export function addInterInvoiceCancellation(data) {
  return axios.post(`${apiUrl}/cancel`, data);
}

export function addInvoiceRequest(data) {
  return axios.post(`${apiUrl}/invoice/request`, data);
}

export function loadInvoicePayment(id) {
  return axios.get(`${apiUrl}/invoice/payment?logs__invoice_id=${id}`);
}

export function loadInvoicePaymentDetails(id) {
  return axios.get(`${apiUrl}/invoice/payment/details/${id}`);
}

export function loadInvoiceLogsDetails(id) {
  return axios.get(`${apiUrl}/invoice/logs/details/${id}`);
}

export function loadInvoiceReconciliation(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/reconciliation/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function addReconciliationRequest(data) {
  return axios.post(`${apiUrl}/reconciliation/request`, data);
}
