import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selector } from "../../../../api";
import { accountingCRUD } from "../../../../actions/accounting";
import { TableShared } from "../../../../shared";
import { char, number } from "../../../../utils";
import { Message, useToaster } from "rsuite";
import {
  DollarOutlined,
  MoreOutlined,
  PrinterOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { CenterFeeAdd } from "./add";
import { Dropdown, Space } from "antd";
import { CenterOtherFeeAdd } from "./addOther";

export const CenterFeeContent = () => {
  const [openProg, setOpenProg] = useState(false);
  const [openOther, setOpenOther] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [postsPerPage, setePostPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(0);
  const [value, setValue] = useState("");
  const [index, setIndex] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortType, setSortType] = useState("");
  const navigate = useNavigate();
  const toaster = useToaster();

  let location = useLocation();

  const centerId = location.state && location.state.center;
  const schoolId = location.state && location.state.school;

  const user = selector.User();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      accountingCRUD
        .loadCenterFee(
          postsPerPage,
          nextPage,
          centerId,
          value,
          "programme__code"
        )
        .then((resp) => {
          setData(resp.data.results);
          setCount(resp.data.count);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">
                  Something went wrong, Check your network connection.
                </p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  }, [page, postsPerPage, value, index, nextPage]);

  const defaultColumns = [
    {
      key: "sn",
      title: "#",
      dataIndex: "sn",
      fixed: true,
      width: 150,
    },
    {
      key: "cost_type",
      title: "Cost Type",
      dataIndex: "cost_type",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "programme",
      title: "programme",
      dataIndex: "programme",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "collection",
      title: "Collection Item",
      dataIndex: "collection",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "amount_tzs",
      title: "Amount TZS",
      dataIndex: "amount_tzs",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "amount_usd",
      title: "Amount USD",
      dataIndex: "amount_usd",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sortable: true,
      width: 100,
    },
  ];

  const dataItem = data?.map((dt, i) => ({
    id: dt.id,
    sn: i + 1,
    cost_type: dt.cost_type ? char.toTitleCase(dt.cost_type) : "N/A",
    programme: dt.programme ? char.toUpperCase(dt.programme.code) : "N/A",
    collection: dt.collection ? char.toUpperCase(dt.collection.code) : "N/A",
    amount_tzs: dt.amount_tzs
      ? number.fNumberDoublePoint(dt.amount_tzs)
      : "N/A",
    amount_usd: dt.amount_usd
      ? number.fNumberDoublePoint(dt.amount_usd)
      : "N/A",
    status: dt.is_active ? (
      <span class="badge badge-soft-success badge-border">
        {char.toTitleCase("active")}
      </span>
    ) : (
      <span class="badge badge-soft-danger badge-border">
        {char.toTitleCase("inactive")}
      </span>
    ),
  }));

  const button = (
    <Dropdown
      menu={{
        items: [
          {
            label: "Programme Cost",
            key: "prog",
            icon: <PlusCircleOutlined />,
          },
          {
            label: "Other Cost",
            key: "other",
            icon: <PlusCircleOutlined />,
          },
        ],
        onClick: (event) => {
          switch (event.key) {
            case "prog":
              setOpenProg(true);
              break;
            case "other":
              setOpenOther(true);
              break;
            default:
          }
        },
      }}
      trigger={["click"]}
      placement="bottomCenter"
      arrow={{ pointAtCenter: true }}
    >
      <Space>
        <button className="btn btn-primary bg-gradient btn-border">
          <span className="bi bi-plus-circle la-lg"></span> &nbsp; New Fee
          Structure
        </button>
      </Space>
    </Dropdown>
  );

  return (
    <>
      <CenterFeeAdd
        open={openProg}
        setOpen={setOpenProg}
        centerId={centerId}
        schoolId={schoolId}
        index={index}
        setIndex={setIndex}
      />
      <CenterOtherFeeAdd
        open={openOther}
        setOpen={setOpenOther}
        centerId={centerId}
        index={index}
        setIndex={setIndex}
      />
      <div className="container-fluid">
        <div className="row mb-2" style={{ marginTop: -30 }}>
          <div className="col-12">
            <p className="h6 fs-18 fw-bold text-dark">New Fee Structure</p>
          </div>
          <hr className="mt-3" />
        </div>
        <TableShared
          loading={loading}
          data={dataItem}
          setData={setData}
          page={page}
          setPage={setPage}
          count={count}
          postsPerPage={postsPerPage}
          setePostPerPage={setePostPerPage}
          setNextPage={setNextPage}
          value={value}
          setValue={setValue}
          defaultColumns={defaultColumns}
          button={button}
          link={"#"}
          showButton={true}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortType={sortType}
          setSortType={setSortType}
          setLoading={setLoading}
        />
      </div>
    </>
  );
};
