import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToaster, Message, Modal } from "rsuite";

import { accountingCRUD } from "../../../actions/accounting";
import { selector } from "../../../api";
import { Divider, Input, notification } from "antd";

const codeSchema = Yup.object().shape({
  spcode: Yup.string().required("This field is required"),
  name: Yup.string().required("This field is required"),
  code: Yup.string().required("This field is required"),
  description: Yup.string().required("This field is required"),
});

const { TextArea } = Input;

const initialValues = {
  spcode: "",
  code: "",
  name: "",
  description: "",
  is_active: true,
};

export const GFSCodeAdd = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();

  const formik = useFormik({
    initialValues,
    validationSchema: codeSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { spcode, code, name, description, is_active } = values;
      const created_by = user.id;
      const data = {
        spcode,
        code,
        name,
        description,
        is_active,
        created_by,
      };
      setLoading(true);
      setTimeout(() => {
        accountingCRUD
          .addGFSCode(data)
          .then(() => {
            setIndex(index + 1);
            toaster.push(
              <Message type="success" showIcon header={"Success"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Thank you...!</p>
                  <p className="text-dark fs-12">
                    GFS Code has been added successfully.
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
            resetForm();
            setSubmitting(false);
            setLoading(false);
            setOpen(false);
          })
          .catch((error) => {
            if (error.message === "Network Error") {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">{error.message}</p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            } else if (error.response.data.code) {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">
                      {error.response.data.code[0]}
                    </p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            } else {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">{error.message}</p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            }
            setSubmitting(false);
            setLoading(false);
          });
      }, 2000);
    },
  });

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="md">
        <Modal.Header>
          <Modal.Title>New GFS Code</Modal.Title>
        </Modal.Header>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="text-body mb-2">
                      SP Code <span className="text-danger">&nbsp;*</span>
                    </label>
                    <Input
                      className="form-control"
                      placeholder="SP Code"
                      {...formik.getFieldProps("spcode")}
                      status={
                        formik.touched.spcode && formik.errors.spcode && "error"
                      }
                    />
                    {formik.touched.spcode && formik.errors.spcode && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.spcode}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="text-body mb-2">
                      GFS Code
                      <span className="text-danger">&nbsp;*</span>
                    </label>
                    <Input
                      className="form-control"
                      placeholder="GFS Code"
                      {...formik.getFieldProps("code")}
                      status={
                        formik.touched.code && formik.errors.code && "error"
                      }
                    />
                    {formik.touched.code && formik.errors.code && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.code}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="text-body mb-2">
                      Name
                      <span className="text-danger">&nbsp;*</span>
                    </label>
                    <Input
                      className="form-control"
                      placeholder="Name"
                      {...formik.getFieldProps("name")}
                      status={
                        formik.touched.name && formik.errors.name && "error"
                      }
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "12px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="form-group">
                    <label>Description</label>
                    <TextArea
                      rows={5}
                      className="form-control"
                      placeholder="GFS Code Description"
                      {...formik.getFieldProps("description")}
                      status={
                        formik.touched.description &&
                        formik.errors.description &&
                        "error"
                      }
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div
                          className="fw-light mt-1"
                          style={{ fontSize: "12px" }}
                        >
                          <span role="alert" className="text-danger">
                            {formik.errors.description}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Divider style={{ marginTop: -10 }} />
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                  <span className="fs-18 text-danger"> * &nbsp;</span> Required
                  field
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                  <button
                    onClick={() => setOpen()}
                    type="button"
                    className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                  >
                    <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                    disabled={formik.isSubmitting}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Save
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
