import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Divider, Modal, useToaster, Message, Input } from "rsuite";
import * as Yup from "yup";
import { selector } from "../../../../api";
import { adjustmentCRUD } from "../../../../actions/adjustment";
import { configurationCRUD } from "../../../../actions/configuration";
import { ExcelRenderer } from "react-excel-renderer";
import { ExcelTable } from "../../../../shared";
import { char, number } from "../../../../utils";
import { allocationValidation } from "../../../../validation";
import moment from "moment";
import { accountingCRUD } from "../../../../actions/accounting";

const defaultColumns = [
  {
    key: "sn",
    label: "#",
    fixed: true,
    width: 70,
  },
  {
    key: "board",
    label: "Loan Board",
    fullText: true,
    width: 100,
  },
  {
    key: "date",
    label: "Date",
    fullText: true,
    width: 150,
  },
  {
    key: "batch_no",
    label: "Batch No.",
    fullText: true,
    width: 100,
  },
  {
    key: "student",
    label: "Student",
    sortable: true,
    fullText: true,
    flexGrow: 1,
  },
  {
    key: "description",
    label: "Description",
    fullText: true,
    flexGrow: 2,
  },
  {
    key: "amount",
    label: "Amount",
    align: "right",
    sortable: true,
    fullText: true,
    flexGrow: 1,
  },
  {
    key: "collection_item",
    label: "Collection Item",
    fullText: true,
    sortable: true,
    flexGrow: 1,
  },
];

export const AllocationUpload = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();
  const [file, setFile] = useState(null);
  const [sum, setSum] = useState(0);
  const [item, setItem] = useState([]);
  const [description, setDescription] = useState(null);
  const [academic, setAcademic] = useState({});
  const [financial, setFinancial] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [paymentItem, setPaymentItem] = useState([]);
  const [paymentCode, setPaymentCode] = useState([]);
  const [board, setBoard] = useState([]);
  const [boardCode, setBoardCode] = useState([]);
  const [boardValid, setBoardValid] = useState(false);
  const [type, setType] = useState([]);
  const [typeValid, setTypeValid] = useState(false);
  const [paymentItemValid, setPaymentItemValid] = useState(false);
  const [studentReg, setStudentReg] = useState([]);
  const [reg, setReg] = useState([]);
  const [studentAccount, setStudentAccount] = useState([]);
  const [accountBoard, setAccountBoard] = useState([]);
  const [studentValid, setStudentValid] = useState(false);
  const [indexA, setIndexA] = useState(0);
  const [indexValid, setIndexValid] = useState(0);
  const [creditData] = useState([]);
  const [creditTransaction] = useState([]);
  const [allocationItem] = useState([]);

  const fileHandler = (e) => {
    setLoading(true);
    setIsValid(false);
    setPaymentItemValid(false);
    setPaymentItem([]);
    setStudentReg([]);
    setStudentAccount([]);
    setStudentValid(false);
    setBoard([]);
    setBoardValid(false);
    setType([]);
    setTypeValid(false);
    setTimeout(() => {
      let fileObj = e.target.files[0];

      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          setFile({
            cols: resp.cols,
            rows: resp.rows,
          });
        }
      });
      setLoading(false);
    }, 2000);
  };

  useEffect(() => {
    setTimeout(() => {
      configurationCRUD
        .loadActiveAcademicYear()
        .then((resp) => {
          setAcademic(resp.data);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type="error" closable>
              {error.message}
            </Message>,
            { placement: "topCenter", duration: 7000 }
          );
        })
        .finally(() => {});
      configurationCRUD
        .loadActiveFinancialYear()
        .then((resp) => {
          setFinancial(resp.data);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type="error" closable>
              {error.message}
            </Message>,
            { placement: "topCenter", duration: 7000 }
          );
        })
        .finally(() => {});
    }, 50);

    const data = file && file.rows;

    const sumValue = data
      ?.slice(1)
      .map((datum) => datum[5])
      .reduce((a, b) => a + b);
    setSum(sumValue);

    if (file && file.rows) {
      var holder = {};

      data.slice(1).forEach(function (d) {
        if (holder.hasOwnProperty(d[6])) {
          holder[d[6]] = holder[d[6]] + d[5];
        } else {
          holder[d[6]] = d[5];
        }
      });

      for (var prop in holder) {
        item.push({ name: prop, value: holder[prop] });
      }
    }
  }, [file]);

  const dataItem = () => ({
    sn: 1,
    board: null,
    date: null,
    batch_no: null,
    student: null,
    description: null,
    amount: null,
    payment_item: null,
  });

  const data =
    file &&
    file.cols.length === 7 &&
    file.rows?.slice(1).map((dt, i) => ({
      sn: i + 1,
      board: dt[0] ? char.toUpperCase(dt[0]) : "N/A",
      date: dt[1] ? moment(dt[1]).format("D MMM YYYY") : "N/A",
      batch_no: dt[2] ? char.toUpperCase(dt[2].toString()) : "N/A",
      student: dt[3] ? char.toUpperCase(dt[3]) : "N/A",
      description: dt[4] ? char.toUpperCase(dt[4]) : "N/A",
      amount: dt[5] ? (
        <span className="fw-bold">{number.fNumberDoublePoint(dt[5])}</span>
      ) : (
        "N/A"
      ),
      collection_item: dt[6] ? char.toUpperCase(dt[6]) : "N/A",
    }));

  const handleValidate = () => {
    setLoading(true);

    if (!file) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Something went wrong, No file Uploaded.
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
    } else if (!description) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Something went wrong, Please fill description.
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
    } else {
      setTimeout(() => {
        if (file.cols.length !== 7) {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">
                  This document does not has valid column.
                </p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
          setLoading(false);
        } else if (
          char.toLowerCase(file.rows[0][0]) !== "loan_board" ||
          char.toLowerCase(file.rows[0][1]) !== "date" ||
          char.toLowerCase(file.rows[0][2]) !== "batch_no" ||
          char.toLowerCase(file.rows[0][3]) !== "student" ||
          char.toLowerCase(file.rows[0][4]) !== "description" ||
          char.toLowerCase(file.rows[0][5]) !== "amount" ||
          char.toLowerCase(file.rows[0][6]) !== "collection_item"
        ) {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">
                  This document hasn't valid header.{" "}
                </p>
                <p className="fw-bold fs-12">
                  Please check sample and make sure no space between header
                  word.
                </p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
          setLoading(false);
        } else {
          for (let i = 1; i < file.rows.length; i++) {
            if (!paymentItem.includes(file.rows[i][6])) {
              paymentItem.push(file.rows[i][6]);
            }
            if (!studentReg.includes(file.rows[i][3])) {
              studentReg.push(file.rows[i][3]);
            }
            if (!board.includes(file.rows[i][0])) {
              board.push(file.rows[i][0]);
            }
            reg.push(file.rows[i][3]);
          }

          const empty = allocationValidation.empty(
            file.rows,
            toaster,
            setLoading
          );

          if (empty === "valid") {
            setIndexA(indexA + 1);
            const date = allocationValidation.date(
              file.rows,
              toaster,
              setLoading
            );
            if (date === "valid") {
              const amount = allocationValidation.amount(
                file.rows,
                toaster,
                setLoading
              );
              if (amount === "valid") {
                allocationValidation
                  .batch(
                    file.rows,
                    toaster,
                    setLoading,
                    academic.id,
                    financial.id,
                    board
                  )
                  .then((resp) => {
                    if (resp === "valid") {
                      if (board.length >= 2) {
                        toaster.push(
                          <Message
                            showIcon
                            type={"error"}
                            header={"Error"}
                            closable
                          >
                            <p className="h6">
                              <p className="fw-bold fs-14">Oooh Sorry...!</p>
                              <p className="text-dark fs-12">
                                Multiple loan`s board is not allowed.
                              </p>
                            </p>
                          </Message>,
                          { placement: "topEnd", duration: 7000 }
                        );
                        setLoading(false);
                      } else {
                        setIndexValid(indexValid + 1);
                        allocationValidation.board(
                          file.rows,
                          board,
                          toaster,
                          setBoardValid,
                          setLoading
                        );
                      }
                    }
                  });
              }
            }
          }
        }
      }, 500);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (studentReg.length) {
        accountingCRUD.loadCheckStudent(studentReg).then((resp) => {
          setStudentAccount(resp.data);
        });
      }
      if (paymentItem.length) {
        accountingCRUD.loadCheckPaymentItem(paymentItem).then((resp) => {
          setPaymentCode(resp.data);
        });
      }
      if (board.length) {
        accountingCRUD.loadCheckLoanBoard(board, true).then((resp) => {
          setBoardCode(resp.data);
        });
        accountingCRUD.loadAccountCode(board).then((resp) => {
          setAccountBoard(resp.data);
        });
      }
    }, 500);
  }, [indexA]);

  useEffect(() => {
    if (boardValid) {
      allocationValidation.paymentItem(
        file.rows,
        paymentItem,
        toaster,
        setPaymentItemValid,
        setLoading
      );
    }

    if (paymentItemValid) {
      allocationValidation.student(
        file.rows,
        studentReg,
        reg,
        toaster,
        setLoading,
        setStudentValid
      );

      if (studentValid) {
        toaster.push(
          <Message showIcon type={"success"} header={"Sucess"} closable>
            <p className="h6">
              <p className="fw-bold fs-14">Thank you...!</p>
              <p className="text-dark fs-12">
                Your document is valid for allocation.
              </p>
            </p>
          </Message>,
          { placement: "topEnd", duration: 7000 }
        );
        setLoading(false);
        setIsValid(true);
      }
    }
  }, [paymentItemValid, studentValid, boardValid, indexValid]);

  const handleSubmit = () => {
    setLoading(true);
    const dataRow = file.rows;

    const dataList =
      file &&
      file.cols.length === 7 &&
      file.rows?.slice(1).map((dt, i) => ({
        sn: i + 1,
        board: dt[0] ? char.toUpperCase(dt[0]) : "N/A",
        date: dt[1] ? moment(dt[1]).format("D MMM YYYY") : "N/A",
        batch_no: dt[2] ? char.toUpperCase(dt[2].toString()) : "N/A",
        student: dt[3] ? char.toUpperCase(dt[3]) : "N/A",
        description: dt[4] ? char.toUpperCase(dt[4]) : "N/A",
        amount: dt[5] ? dt[5] : "N/A",
        collection_item: dt[6] ? char.toUpperCase(dt[6]) : "N/A",
      }));

    const brd = boardCode.find(
      (br) => char.toUpperCase(br.acronym) === char.toUpperCase(dataRow[1][0])
    );

    const allocationData = {
      description: description,
      amount: sum,
      currency: 1,
      exchange_rate: 1,
      equivalent_amount: sum,
      date: moment(dataRow[1][1]).format("yyyy-MM-DD"),
      batch_no: "00" + dataRow[1][2],
      attachment: null,
      is_posted: true,
      is_invoiced: false,
      academic_year: academic.id,
      financial_year: financial.id,
      loan_board: brd.id,
      created_by: user.id,
    };

    adjustmentCRUD
      .addAllocation(allocationData)
      .then((resp) => {
        const allocation = resp.data.id;

        for (let it = 0; it < item.length; it++) {
          const acc = paymentCode.find((itm) => itm.code === item[it].name);

          const itemData = {
            allocation: allocation,
            collection_item: acc.id,
            description: acc.description + " 00" + dataRow[1][2],
            amount: item[it].value,
            equivalent_amount: item[it].value,
            created_by: user.id,
          };
          allocationItem.push(itemData);
        }

        adjustmentCRUD
          .addAllocationItem(allocationItem)
          .then((respI) => {
            const dataStudent = [];
            for (let itm = 0; itm < respI.data.length; itm++) {
              const itemId = respI.data[itm].id;

              for (let itc = 0; itc < studentAccount.length; itc++) {
                let acc = dataList.find(
                  (itmd) =>
                    itmd.student ===
                    char.toUpperCase(studentAccount[itc].registration_number)
                );

                const student = {
                  allocation: itemId,
                  student: studentAccount[itc].id,
                  amount: parseFloat(acc.amount),
                  equivalent_amount: parseFloat(acc.amount),
                  created_by: user.id,
                };
                const existingObject = dataStudent.find(
                  (obj) =>
                    obj.allocation === student.allocation &&
                    obj.student === student.student
                );

                if (!existingObject) {
                  dataStudent.push(student);
                }
              }
            }
            adjustmentCRUD
              .addAllocatedStudent(dataStudent)
              .then(() => {
                toaster.push(
                  <Message showIcon type={"success"} header={"Sucess"} closable>
                    <p className="h6">
                      <p className="fw-bold fs-14">Thank you...!</p>
                      <p className="text-dark fs-12">
                        Allocation for batch{" "}
                        <span className="text-success fw-bold">
                          {"00" + dataRow[1][2]}
                        </span>{" "}
                        has been done successfully.
                      </p>
                    </p>
                  </Message>,
                  { placement: "topEnd", duration: 7000 }
                );

                setLoading(false);
                setFile(null);
                document.getElementById("file").value = "";
                setPaymentItemValid(false);
                setIsValid(false);
                setPaymentItem([]);
                setStudentReg([]);
                setStudentValid(false);
                setBoard([]);
                setBoardValid(false);
                setType([]);
                setTypeValid(false);
                setOpen(false);
                setIndex(index + 1);
                setReg([]);
              })
              .catch((error) => {
                toaster.push(
                  <Message showIcon type={"error"} header={"Error"} closable>
                    <p className="h6">
                      <p className="fw-bold fs-14">Oooh Sorry...!</p>
                      <p className="text-dark fs-12">
                        Something went wrong, Allocation not uploaded.
                      </p>
                    </p>
                  </Message>,
                  { placement: "topEnd", duration: 7000 }
                );
              });
          })
          .catch((error) => {
            toaster.push(
              <Message showIcon type={"error"} header={"Error"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Oooh Sorry...!</p>
                  <p className="text-dark fs-12">
                    Something went wrong, Allocation not uploaded.
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
          });
      })
      .catch((error) => {
        toaster.push(
          <Message showIcon type={"error"} header={"Error"} closable>
            <p className="h6">
              <p className="fw-bold fs-14">Oooh Sorry...!</p>
              <p className="text-dark fs-12">
                Something went wrong, Allocation not uploaded.
              </p>
            </p>
          </Message>,
          { placement: "topEnd", duration: 7000 }
        );
      });
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="full">
        <Modal.Header>
          <div className="row">
            <div className="col-md-6 col-sm-12">New Allocation</div>
            <div className="col-md-6 col-sm-12 d-flex flex-row-reverse">
              <input
                type={"file"}
                className="form-contorl"
                accept=".xlsx, .xls, .csv"
                onChange={fileHandler}
                id="file"
              />
              <Input
                onChange={(e) => setDescription(e)}
                placeholder="Allocation description"
                className="me-5"
              />
            </div>
          </div>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          <ExcelTable
            data={file ? data : dataItem}
            defaultColumns={defaultColumns}
            loading={loading}
          />
        </Modal.Body>
        <Divider />
        <Modal.Footer>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-12 d-flex justify-content-start h6 fs-14">
                <span className="fw-bold">Available rows</span> &nbsp; : &nbsp;{" "}
                <span className="fw-bold text-success">
                  {file ? number.fNumber(file.rows.length - 1) : 0}
                </span>
                &emsp; | &emsp;
                <span className="fw-bold">Total Amount</span> &nbsp; : &nbsp;{" "}
                <span className="fw-bold text-success">
                  {sum ? number.fNumberDoublePoint(sum) : 0}
                </span>
              </div>
              <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-danger me-3"
                  onClick={() => {
                    setFile(null);
                    document.getElementById("file").value = "";
                    setIsValid(false);
                    setPaymentItem([]);
                    setLoading(false);
                    setPaymentItemValid(false);
                    setBoard([]);
                    setBoardValid(false);
                    setType([]);
                    setTypeValid(false);
                    setReg([]);
                  }}
                >
                  <span className="bi bi-slash-circle"></span> Clear
                </button>
                {isValid ? (
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={loading}
                    onClick={handleSubmit}
                  >
                    {!loading ? (
                      <span className="indicator-label">
                        <span className="bi bi-check-circle"></span> Save
                      </span>
                    ) : (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait.....
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                    onClick={handleValidate}
                  >
                    {!loading ? (
                      <span className="indicator-label">
                        <span className="bi bi-arrow-repeat"></span> Validate
                      </span>
                    ) : (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait.....
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
