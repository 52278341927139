import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { selector } from "../../../api";
import { Divider, Input, notification } from "antd";
import { formComponent } from "../../../utils";
import { accountingCRUD } from "../../../actions/accounting";
import { Modal } from "rsuite";

const customerSchema = Yup.object().shape({
  name: Yup.string().required("This field is reuired"),
  address: Yup.string().required("This field is reuired"),
  phone_number: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must be a digits")
    .min(12, "must be 12 digits and please start with 255")
    .max(12, "must be 12 digits and please start with 255")
    .required("This field is required"),
  email: Yup.string().email("This is not email format"),
  tin: Yup.string().required("This field is reuired"),
});

const initialValues = {
  name: "",
  phone_number: "",
  address: "",
  email: "",
  tin: "",
  vrn: "",
  credit_limit: "",
  is_active: true,
};

export const CustomerAdd = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      accountingCRUD
        .loadCustomerInfoLast()
        .then((resp) => {
          setCustomerId(resp.data);
        })
        .catch(() => {});
    }, 500);
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: customerSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const {
        name,
        phone_number,
        address,
        email,
        tin,
        vrn,
        credit_limit,
        is_active,
      } = values;
      const number = customerId ? customerId.id.toString() : "1";
      const data = {
        customer_number:
          number.length <= 2
            ? "CMT00" + number
            : number.length >= 4
            ? "CMT" + number
            : "CMT0" + number,
        name,
        phone_number,
        address,
        email,
        tin,
        vrn,
        credit_limit: credit_limit === "" ? 0 : credit_limit,
        is_active,
        created_by: user.id,
      };
      setLoading(true);
      setTimeout(() => {
        accountingCRUD
          .addCustomer(data)
          .then((resp) => {
            const customer = resp.data.id;
            const code = resp.data.customer_number;

            const accountData = {
              customer: customer,
              account_code: code,
              account_type: 1,
              created_by: user.id,
            };
            accountingCRUD
              .addCustomerAccount(accountData)
              .then(() => {
                api.success({
                  message: "Thank you",
                  description:
                    "🎉 Congratulations! Your data has been successfully saved!",
                  placement: "topRight",
                });
                setIndex(index + 1);
                resetForm();
                setLoading(false);
                setSubmitting(false);
                setOpen(false);
              })
              .catch((error) => {
                api.error({
                  message: error.message,
                  description:
                    "Oops! It looks like there is some errors. Please correct your error and try again",
                  placement: "topRight",
                });
                setSubmitting(false);
                setLoading(false);
              });
          })
          .catch((error) => {
            setLoading(false);
            setSubmitting(false);
            api.error({
              message: error.message,
              description:
                "Oops! It looks like there is some errors. Please correct your error and try again",
              placement: "topRight",
            });
          });
      }, 2000);
    },
  });

  return (
    <>
      {contextHolder}
      <Modal open={open} onClose={() => setOpen(false)} size="md">
        <Modal.Header>
          <Modal.Title>New Customer</Modal.Title>
        </Modal.Header>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Customer Name <span className="text-danger">*</span>
                    </label>
                    <Input
                      {...formik.getFieldProps("name")}
                      placeholder="Customer name"
                      size="large"
                      status={
                        formik.touched.name && formik.errors.name && "error"
                      }
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "14px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label className="form-label">Address</label>
                  <Input
                    {...formik.getFieldProps("address")}
                    placeholder="Customer address"
                    size="large"
                    status={
                      formik.touched.address && formik.errors.address && "error"
                    }
                  />
                  {formik.touched.address && formik.errors.address && (
                    <div className="fw-light mt-1" style={{ fontSize: "14px" }}>
                      <span role="alert" className="text-danger">
                        {formik.errors.address}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <Input
                      {...formik.getFieldProps("phone_number")}
                      placeholder="Phone number"
                      size="large"
                      status={
                        formik.touched.phone_number &&
                        formik.errors.phone_number &&
                        "error"
                      }
                    />
                    {formik.touched.phone_number &&
                      formik.errors.phone_number && (
                        <div
                          className="fw-light mt-1"
                          style={{ fontSize: "14px" }}
                        >
                          <span role="alert" className="text-danger">
                            {formik.errors.phone_number}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <Input
                      {...formik.getFieldProps("email")}
                      placeholder="Email address"
                      size="large"
                      status={
                        formik.touched.email && formik.errors.email && "error"
                      }
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "14px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.email}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      TIN Number <span className="text-danger">*</span>
                    </label>
                    <Input
                      {...formik.getFieldProps("tin")}
                      placeholder="TIN number"
                      size="large"
                      status={
                        formik.touched.tin && formik.errors.tin && "error"
                      }
                    />
                    {formik.touched.tin && formik.errors.tin && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "14px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.tin}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">VRN Number</label>
                    <Input
                      {...formik.getFieldProps("vrn")}
                      placeholder="VRN Number"
                      size="large"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Credi Limit</label>
                    <Input
                      type="number"
                      className="form-control"
                      {...formik.getFieldProps("credit_limit")}
                      placeholder="Credit limit"
                      size="large"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Divider style={{ marginTop: -10 }} />
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                  <span className="fs-18 text-danger"> * &nbsp;</span> Required
                  field
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                  >
                    <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Save
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
