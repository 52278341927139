import axios from "axios";
import { dictionary } from "../../api";

const apiUrl = dictionary;

export function loadDictionary(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/dictionary/filter?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadDictionaryById(id) {
  return axios.get(`${apiUrl}/dictionary/details/${id}`);
}

export function updateDictionary(id, data) {
  return axios.put(`${apiUrl}/dictionary/details/${id}`, data);
}

export function loadAllDictionary() {
  return axios.get(`${apiUrl}/dictionary/filter`);
}

export function loadDictionaryData(pageLimit, page, search, id) {
  return axios.get(
    `${apiUrl}/dictionary/item/parent?limit=${pageLimit}&offset=${page}&dictionary__id=${id}&search=${search}`
  );
}

export function loadDictionaryItem(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/dictionary/item/filter?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function addDictionary(data) {
  return axios.post(`${apiUrl}/dictionary`, data);
}

export function addDictionaryItem(data) {
  return axios.post(`${apiUrl}/dictionary/item`, data);
}

export function loadDictionaryItemById(id) {
  return axios.get(`${apiUrl}/dictionary/item/details/${id}`);
}

export function saveDictionaryItemChanges(id, data) {
  return axios.put(`${apiUrl}/dictionary/item/details/${id}`, data);
}

export function loadDictionaryItemValidation(name) {
  return axios.get(
    `${apiUrl}/dictionary/item/check?dictionary_item_name__in=${name}`
  );
}
