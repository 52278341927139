import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Divider, Modal, useToaster, Message, SelectPicker } from "rsuite";
import * as Yup from "yup";
import { selector } from "../../../../api";
import { invoiceCRUD } from "../../../../actions/invoice";
import { char } from "../../../../utils";
import { configurationCRUD } from "../../../../actions/configuration";
import { accountingCRUD } from "../../../../actions/accounting";
import FilterTable from "../../../../utils/filterTable";
import { Input, Select, Table, Transfer } from "antd";
import { dictionaryCRUD } from "../../../../actions/dictionary";
import { set } from "lodash";

const { TextArea } = Input;

const financialYearSchema = Yup.object().shape({
  name: Yup.string().required("This field is reuired"),
  description: Yup.string().required("This field is reuired"),
  gfs_code: Yup.string().required("This field is reuired"),
});

const initialValues = {
  name: "",
  description: "",
  gfs_code: "",
  is_active: true,
};

export const CollectionItemAdd = (props) => {
  const { open, setOpen, index, setIndex, centerId } = props;
  const user = selector.User();
  const [loading, setLoading] = useState(false);
  
  const [search, setSearch] = useState("");
  const [centerCode, setCenterCode] = useState("");
  const [gfscode, setGfsCode] = useState("");
  const [pay, setPay] = useState([]);
  const toaster = useToaster();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
        accountingCRUD.loadGFSCode(50, 0, search, "id").then((resp) => {
            setPay(resp.data.results);
        })
        .catch(() => {})
        .finally(() => {
            setLoading(false);
        })
    },500)
  }, [search]);

  useEffect(() => {
    if(centerId){
        setTimeout(() => {
            accountingCRUD.loadSingleCollectionCenter("","",centerId, "", "").then((resp) => {
              setCenterCode(resp.data[0].code);
            })
        },500)
    }
  },[centerId])

  const formik = useFormik({
    initialValues,
    validationSchema: financialYearSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      const { name, description, gfs_code, is_active } = values;
      const dataSave = {
        account_code: centerCode + gfs_code,
        account_type: 2,
        name: centerCode + " " + name,
        acronym: centerCode + gfs_code,
        description,
        gfs_code,
        is_active,
        collection_center: centerId,
        created_by: user.id,
      };

      accountingCRUD
        .addInstAccount(dataSave)
        .then((resp) => {
          const accID = resp.data.id;
          const dataItem = {
            name: centerCode + " " + name,
            code:
              accID.toString().length >= 3
                ? "szci" + accID.toString()
                : accID.toString().length === 2
                ? "szci0" + accID.toString()
                : "szci00" + accID.toString(),
            description,
            account: accID,
            is_active,
            created_by: user.id,
          };
          accountingCRUD
            .addCollectionItem(dataItem)
            .then(() => {
              setIndex(index + 1);
              toaster.push(
                <Message type="success" showIcon header={"Success"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Thank you...!</p>
                    <p className="text-dark fs-12">
                      Collection item has been added successfully.
                    </p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
              resetForm();
              setSubmitting(false);
              setLoading(false);
              setOpen(false);
            })
            .catch((error) => {
              if (error.message === "Network Error") {
                toaster.push(
                  <Message showIcon type={"error"} header={"Error"} closable>
                    <p className="h6">
                      <p className="fw-bold fs-14">Oooh Sorry...!</p>
                      <p className="text-dark fs-12">{error.message}</p>
                    </p>
                  </Message>,
                  { placement: "topEnd", duration: 7000 }
                );
              } else if (error.response.data.code) {
                toaster.push(
                  <Message showIcon type={"error"} header={"Error"} closable>
                    <p className="h6">
                      <p className="fw-bold fs-14">Oooh Sorry...!</p>
                      <p className="text-dark fs-12">
                        {error.response.data.code[0]}
                      </p>
                    </p>
                  </Message>,
                  { placement: "topEnd", duration: 7000 }
                );
              } else {
                toaster.push(
                  <Message showIcon type={"error"} header={"Error"} closable>
                    <p className="h6">
                      <p className="fw-bold fs-14">Oooh Sorry...!</p>
                      <p className="text-dark fs-12">{error.message}</p>
                    </p>
                  </Message>,
                  { placement: "topEnd", duration: 7000 }
                );
              }
              setSubmitting(false);
              setLoading(false);
            });
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            toaster.push(
              <Message showIcon type={"error"} header={"Error"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Oooh Sorry...!</p>
                  <p className="text-dark fs-12">{error.message}</p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
          } else if (error.response.data.code) {
            toaster.push(
              <Message showIcon type={"error"} header={"Error"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Oooh Sorry...!</p>
                  <p className="text-dark fs-12">
                    {error.response.data.code[0]}
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
          } else {
            toaster.push(
              <Message showIcon type={"error"} header={"Error"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Oooh Sorry...!</p>
                  <p className="text-dark fs-12">{error.message}</p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
          }
          setSubmitting(false);
          setLoading(false);
        });
    },
  });






const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
 

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="md">
        <Modal.Header>
          <Modal.Title>New Collection Item</Modal.Title>
        </Modal.Header>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div
              className="container-fluid p-4"
              style={{ backgroundColor: "whitesmoke", borderRadius: 5 }}
            >
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Collection Item Name{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      className="w-100"
                      showSearch
                      placeholder="Select Collection Item Name"
                      size="large"
                      status={
                        formik.touched.name && formik.errors.name && "error"
                      }
                      optionFilterProp="children"
                      onChange={(e, option) => {
                        formik.setFieldValue("name", e);
                        formik.setFieldValue("gfs_code", option.gfscode);
                        formik.setFieldValue("description", option.description);
                      }}
                      onSearch={(e) => setSearch(e)}
                      filterOption={filterOption}
                      options={pay?.map((dt) => ({
                        value: char.toLowerCase(dt.name),
                        label: char.toTitleCase(dt.name),
                        gfscode: dt.code,
                        description: dt.description,
                      }))}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "14px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Collection Item GFS Code{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Input
                      {...formik.getFieldProps("gfs_code")}
                      disabled
                      placeholder="Collection Item GFS Code"
                      size="large"
                      status={
                        formik.touched.gfs_code &&
                        formik.errors.gfs_code &&
                        "error"
                      }
                    />
                    {formik.touched.gfs_code && formik.errors.gfs_code && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "14px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.gfs_code}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label">
                      Collection Item Description
                      <span className="text-danger">*</span>
                    </label>
                    <TextArea
                      rows={4}
                      {...formik.getFieldProps("description")}
                      placeholder="Collection Item Description"
                      size="large"
                      status={
                        formik.touched.description &&
                        formik.errors.description &&
                        "error"
                      }
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div
                          className="fw-light mt-1"
                          style={{ fontSize: "14px" }}
                        >
                          <span role="alert" className="text-danger">
                            {formik.errors.description}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                  <span className="fs-18 text-danger"> * &nbsp;</span> Required
                  field
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                  >
                    <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                    disabled={formik.isSubmitting}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Save
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
