import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { selector } from "../../../api";
import { TableShared } from "../../../shared";
import { char } from "../../../utils";
import {
  Badge,
  Breadcrumb,
  Button,
  ConfigProvider,
  Dropdown,
  Space,
  message,
  notification,
} from "antd";
import {
  PlusCircleOutlined,
  MoreOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { configurationCRUD } from "../../../actions/configuration";

const items = [
  {
    label: "Edit",
    key: "edit",
    icon: <EditOutlined />,
  },
  {
    label: "View",
    key: "view",
    icon: <EyeOutlined />,
  },
  {
    label: "Delete",
    key: "delete",
    icon: <DeleteOutlined />,
  },
];

export const SchoolContent = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [postsPerPage, setePostPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(0);
  const [value, setValue] = useState("");
  const [index, setIndex] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortType, setSortType] = useState("");

  const [api, contextHolder] = notification.useNotification();

  const user = selector.User();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      configurationCRUD
        .loadSchool(
          postsPerPage,
          nextPage,
          value,
          sortType === "desc" ? "-" + sortColumn : sortColumn
        )
        .then((resp) => {
          setData(resp.data.results);
          setCount(resp.data.count);
        })
        .catch((error) => {
          api.error({
            message: error.message,
            description:
              "Oops! It looks like you're not connected to the network. Please check your network connectivity and try again",
            placement: "topRight",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  }, [page, postsPerPage, value, index, nextPage, sortColumn, sortType]);

  const defaultColumns = [
    {
      key: "sn",
      title: "#",
      dataIndex: "sn",
      fixed: true,
      width: 150,
    },
    {
      key: "code",
      title: "Code",
      dataIndex: "code",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sortable: true,
      width: 150,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => (
        <Dropdown
          menu={{
            items,
            onClick: (event) => {
              switch (event.key) {
                case "1":
                  setOpen(true);
                  break;
                default:
              }
            },
          }}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <Space>
            <Button type="dashed" icon={<MoreOutlined />} size={"middle"} />
          </Space>
        </Dropdown>
      ),
    },
  ];

  const dataItem = data?.map((dt, i) => ({
    id: dt.id,
    sn: i + 1,
    code: dt.code ? dt.code : "N/A",
    name: dt.name ? char.toTitleCase(dt.name) : "N/A",
    status: dt.is_active ? (
      <span class="badge badge-soft-success badge-border">
        {char.toTitleCase("active")}
      </span>
    ) : (
      <span class="badge badge-soft-danger badge-border">
        {char.toTitleCase("inactive")}
      </span>
    ),
  }));

  return (
    <>
      {contextHolder}
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-12">
            <p className="h6 fs-18 fw-bold text-dark">School</p>
          </div>
          <hr className="mt-3" />
        </div>
        <TableShared
          setOpen={setOpen}
          loading={loading}
          data={dataItem}
          setData={setData}
          page={page}
          setPage={setPage}
          count={count}
          postsPerPage={postsPerPage}
          setePostPerPage={setePostPerPage}
          setNextPage={setNextPage}
          value={value}
          setValue={setValue}
          defaultColumns={defaultColumns}
          button={null}
          link={"#"}
          showButton={true}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortType={sortType}
          setSortType={setSortType}
          setLoading={setLoading}
        />
      </div>
    </>
  );
};
