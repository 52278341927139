import moment from "moment";
import { useState } from "react";
import { DatePicker, Divider, Message, Modal, useToaster } from "rsuite";
import { gatewayCRUD } from "../../../actions/gateway";
import { code } from "../../../utils";

export const RequestReco = (props) => {
  const { open, setOpen } = props;

  const toaster = useToaster();

  const [date, setDate] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSend = () => {
    if (date) {
      const data = {
        date: date,
        code: code.randomCode(2, 8),
        option: 1,
      };
      setTimeout(() => {
        setLoading(true);
        gatewayCRUD
          .addReconciliationRequest(data)
          .then(() => {
            toaster.push(
              <Message showIcon type="success" closable>
                {
                  "Thank you....! Reconciliation request has been set to GePG, wait a minut then refresh your system to see a feedback"
                }
              </Message>,
              { placement: "topCenter", duration: 7000 }
            );
            setOpen(false);
          })
          .catch((error) => {
            toaster.push(
              <Message showIcon type="error" closable>
                {error.message}
              </Message>,
              { placement: "topCenter", duration: 7000 }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500);
    } else {
      toaster.push(
        <Message showIcon type="error" closable>
          {"Please select date"}
        </Message>,
        { placement: "topCenter", duration: 7000 }
      );
    }
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="lg">
        <Modal.Header>
          <Modal.Title>
            <div className="row">
              <div className="col-md-6 col-sm-12">Reconciliation</div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    Select Date <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    oneTap
                    block
                    onChange={(e) => setDate(moment(e).format("yyyy-MM-DD"))}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Divider />
        <Modal.Footer>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                <span className="text-danger">*</span> &nbsp; Required field
              </div>
              <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-soft-danger me-3"
                  onClick={() => setOpen(false)}
                >
                  <span className="bi bi-slash-circle"></span> Clear
                </button>
                <button
                  type="submit"
                  className="btn btn-soft-success"
                  onClick={handleSend}
                >
                  {!loading ? (
                    <span className="indicator-label">
                      <span className="bi bi-check-circle"></span> Save
                    </span>
                  ) : (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait.....
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
