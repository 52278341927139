import * as selector from "./selector";
import { Headers } from "./header";

const apiUrl = process.env.REACT_APP_API_URL;

export const auth = `${apiUrl}/auth/api`;
export const dictionary = `${apiUrl}/configuration/api`;
export const configuration = `${apiUrl}/configuration/api`;
export const accounting = `${apiUrl}/accounting/api`;
export const invoice = `${apiUrl}/invoice/api`;
export const adjustment = `${apiUrl}/adjustment/api`;
export const gateway = `${apiUrl}/suza/api`;
export const report = `${apiUrl}/report/api`;
export const image = `${apiUrl}/`;

export {selector,Headers,};
