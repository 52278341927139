import axios from "axios";
import { invoice, accounting } from "../../api";

const apiUrl = invoice;

//============================================ customer account =====================================================================
export function loadInvoice(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/invoice/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadStudentInvoice(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/invoice/student/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadAdmissionInvoice(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/invoice/admission/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadCustomerInvoice(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/invoice/customer/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadInvoiceLast() {
  return axios.get(`${apiUrl}/invoice/last`);
}

export function invoicePrint(id) {
  return axios.get(`${apiUrl}/invoice/print/${id}`);
}

export function addInvoiceItem(data) {
  return axios.post(`${apiUrl}/invoice-item`, data);
}

export function addInvoice(data) {
  return axios.post(`${apiUrl}/invoice`, data);
}

export function addInvoiceCancelation(data) {
  return axios.post(`${apiUrl}/invoice/cancellation`, data);
}

export function addInvoicePost(id, data) {
  return axios.put(`${apiUrl}/invoice/post/${id}`, data);
}

export function addInvoiceCancel(id, data) {
  return axios.put(`${apiUrl}/invoice/cancel/${id}`, data);
}

//==================================================================student==================================================================
export function addInvoiceTransaction(data) {
  return axios.post(`${apiUrl}/invoice-transaction`, data);
}

//============================================ customer account =====================================================================
export function loadStudentAccount(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/account/student/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadStudentAdmissionAccount(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/account/admission/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadCustomerAccount(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/account/customer/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadLoanBoardAccount(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/account/loan-board/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadAccountTransaction(
  pageLimit,
  page,
  account,
  transaction,
  financial,
  start,
  end,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/account/transaction?limit=${pageLimit}&offset=${page}&account__id=${account}&transaction_type__id=${transaction}&financial_year__id=${financial}&start_date=${start}&end_date=${end}&search=${search}&ordering=${order}`
  );
}

export function loadCustomerAccountId(id) {
  return axios.get(`${apiUrl}/account/customer/details/${id}`);
}

//============================================ suza account =====================================================================
export function loadInstitutionAccountId(id) {
  return axios.get(`${apiUrl}/account/institution/details/${id}`);
}

export function loadInstitutionAccount(
  pageLimit,
  page,
  center,
  code,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/account/institution/list?limit=${pageLimit}&offset=${page}&collection_center__id__in=${center}&gfs_code=${code}&search=${search}&ordering=${order}`
  );
}

export function loadCollectionItem(pageLimit, page, center, search, order) {
  return axios.get(
    `${accounting}/collection-item/list?limit=${pageLimit}&offset=${page}&account__collection_center__id__in=${center}&search=${search}&ordering=${order}`
  );
}
