import axios from "axios";
import { configuration } from "../../api";

const apiUrl = configuration;

//============================================currency=====================================================================
export function loadCurrency(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/currency/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function adCurrency(data) {
  return axios.post(`${apiUrl}/currency`, data);
}

export function loadCurrencyDetails(id) {
  return axios.get(`${apiUrl}/currency/details/${id}`);
}

//============================================exchange-rate=====================================================================
export function loadExchangeRate(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/exchange-rate/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function addExchangeRate(data) {
  return axios.post(`${apiUrl}/exchange-rate`, data);
}

export function loadCurrencyExchangeRate(id) {
  return axios.get(`${apiUrl}/exchange-rate/currency/${id}`);
}

//============================================academic-year=====================================================================
export function loadAccademicYear(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/academic-year/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadActiveAcademicYear() {
  return axios.get(`${apiUrl}/academic-year/active`);
}

export function addAccdemicYear(data) {
  return axios.post(`${apiUrl}/academic-year`, data);
}

//============================================financial-year=====================================================================
export function loadFinancialYear(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/financial-year/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadActiveFinancialYear() {
  return axios.get(`${apiUrl}/financial-year/active`);
}

export function addFinancialYear(data) {
  return axios.post(`${apiUrl}/financial-year`, data);
}

//============================================study-year=====================================================================
export function loadStudyYear(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/study-year/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

//============================================school=====================================================================
export function loadSchool(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/school/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

//============================================department=====================================================================
export function loadDepartment(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/department/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

//============================================programme=====================================================================
export function loadProgramme(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/programme/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

//============================================programme=====================================================================
export function loadSchoolProgramme(pageLimit, page, school, search, order) {
  return axios.get(
    `${apiUrl}/programme/list?limit=${pageLimit}&offset=${page}&department__school__id__in=${school}&search=${search}&ordering=${order}`
  );
}
