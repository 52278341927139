import { useLocation } from "react-router-dom";
import { AllocationContent, AllocationItem } from "../../components/adjustment";

export default function Allocation() {
  let location = useLocation();

  return (
    <>
      {location.state ? (
        location.state.page === "item" ? (
          <AllocationItem allocationId={location.state.dataId} />
        ) : (
          <AllocationContent />
        )
      ) : (
        <AllocationContent />
      )}
    </>
  );
}
