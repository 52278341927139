import { useEffect, useState } from "react";
import { TableShared } from "../../../shared";
import { adjustmentCRUD } from "../../../actions/adjustment";
import { Message, useToaster } from "rsuite";
import { char, number } from "../../../utils";
import moment from "moment";
import { Button, Dropdown, Space } from "antd";
import { MoreOutlined, EyeOutlined } from "@ant-design/icons";
import { AllocatedStudent } from "./student";

export const AllocationItem = (props) => {
  const { allocationId } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const [search, setSearch] = useState("");
  const [postPerPage, setPostPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [dataId, setDataId] = useState(null);
  const toaster = useToaster();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      adjustmentCRUD
        .loadAllocationItem(postPerPage, nextPage, allocationId, search, "id")
        .then((resp) => {
          setData(resp.data.results);
          setCount(resp.data.count);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">
                  Something went wrong, Check your network connection.
                </p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  }, [allocationId, postPerPage, nextPage, page, search]);

  const defaultColumns = [
    {
      key: "sn",
      title: "#",
      dataIndex: "sn",
      fixed: true,
      width: 150,
    },
    {
      key: "batch_no",
      title: "Batch No.",
      dataIndex: "batch_no",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "collection_code",
      title: "Collection Codde",
      dataIndex: "collection_code",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "collection_item",
      title: "Collection Item",
      dataIndex: "collection_item",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      sortable: true,
      flexGrow: 1,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => (
        <Dropdown
          menu={{
            items: [
              {
                label: "View",
                key: "view",
                icon: <EyeOutlined />,
              },
            ],
            onClick: (event) => {
              switch (event.key) {
                case "view":
                  setDataId(record.id);
                  setOpen(true);
                  break;
                default:
              }
            },
          }}
          trigger={["click"]}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <Space>
            <Button type="dashed" icon={<MoreOutlined />} size={"middle"} />
          </Space>
        </Dropdown>
      ),
    },
  ];

  const dataItem = data?.map((dt, i) => ({
    id: dt.id,
    sn: i + 1,
    batch_no: dt.allocation ? dt.allocation.batch_no : "N/A",
    collection_code: dt.collection_item
      ? char.toUpperCase(dt.collection_item.code)
      : "N/A",
    collection_item: dt.collection_item
      ? char.toUpperCase(dt.collection_item.name)
      : "N/A",
    amount: dt.equivalent_amount
      ? number.fNumberDoublePoint(dt.equivalent_amount)
      : number.fNumberDoublePoint(0.0),
  }));

  return (
    <>
      <AllocatedStudent allocationId={dataId} open={open} setOpen={setOpen} />
      <div className="container-fluid">
        <div className="row mb-2" style={{ marginTop: -30 }}>
          <div className="col-12">
            <p className="h6 fs-18 fw-bold text-dark">Allocation Items</p>
          </div>
          <hr />
        </div>
        <TableShared
          setOpen={setOpen}
          loading={loading}
          data={dataItem}
          setData={setData}
          page={page}
          setPage={setPage}
          count={count}
          postsPerPage={postPerPage}
          setePostPerPage={setPostPerPage}
          setNextPage={setNextPage}
          value={search}
          setValue={setSearch}
          defaultColumns={defaultColumns}
          button={null}
          link={"#"}
          showButton={true}
          setLoading={setLoading}
        />
      </div>
    </>
  );
};
