import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import SuzaLayout from "../layout";
import { Login, Lock } from "../pages/auth";
import Home from "../pages/home";
import {
  Dictionary,
  DictionaryItem,
  Currency,
  ExchangeRate,
  AccademicYear,
  FinancialYear,
  StudyYear,
  School,
  Department,
  Programme,
} from "../pages/configuration";
import {
  Addmission,
  CollectionCenter,
  Customer,
  GFSCode,
  LoanBoard,
  Student,
} from "../pages/accounting";
import { ReceivableAccount } from "../pages/accounting/account";
import { Invoice, Reconciliation } from "../pages/invoice";
import { Allocation, Journal } from "../pages/adjustment";
import { useInactivityLogout } from "../utils/inactivity";
import { InvoiceReport } from "../pages/report";
import { CollectionReportContent } from "../components/report/collection/content";

export default function Routers() {
  const logoutTime = 3 * 60 * 1000;

  useInactivityLogout(logoutTime);
  
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/lockscreen" element={<Lock />} />
        <Route element={<SuzaLayout />}>
          <Route
            exact
            path="/home"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dictionary"
            element={
              <PrivateRoute>
                <Dictionary />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/dictionary-item"
            element={
              <PrivateRoute>
                <DictionaryItem />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/currency"
            element={
              <PrivateRoute>
                <Currency />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/exchange-rate"
            element={
              <PrivateRoute>
                <ExchangeRate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/academic-year"
            element={
              <PrivateRoute>
                <AccademicYear />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/financial-year"
            element={
              <PrivateRoute>
                <FinancialYear />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/study-year"
            element={
              <PrivateRoute>
                <StudyYear />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/school"
            element={
              <PrivateRoute>
                <School />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/department"
            element={
              <PrivateRoute>
                <Department />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/programme"
            element={
              <PrivateRoute>
                <Programme />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/student"
            element={
              <PrivateRoute>
                <Student />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/addmission"
            element={
              <PrivateRoute>
                <Addmission />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/customer"
            element={
              <PrivateRoute>
                <Customer />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/loan-board"
            element={
              <PrivateRoute>
                <LoanBoard />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/collection-center"
            element={
              <PrivateRoute>
                <CollectionCenter />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/gfscode"
            element={
              <PrivateRoute>
                <GFSCode />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/receivable"
            element={
              <PrivateRoute>
                <ReceivableAccount />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/invoice"
            element={
              <PrivateRoute>
                <Invoice />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/reconciliation"
            element={
              <PrivateRoute>
                <Reconciliation />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/allocation"
            element={
              <PrivateRoute>
                <Allocation />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/journal"
            element={
              <PrivateRoute>
                <Journal />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/invoice-report"
            element={
              <PrivateRoute>
                <InvoiceReport />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/collection-report"
            element={
              <PrivateRoute>
                <CollectionReportContent />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
}
