import * as actions from "./actionTypes";
//import { refreshToken } from "./crud";

export const getToken = (token) => ({
  type: actions.getToken,
  payload: token,
});

export const getUser = (user) => ({
  type: actions.getUser,
  payload: user,
});

export const setAuth = (auth) => ({
  type: actions.setAuth,
  payload: auth,
});

export const setLock = (lock) => ({
  type: actions.setLock,
  payload: lock,
});
