import { useEffect, useState } from "react";
import { DatePicker, Spin } from "antd";
import { selector } from "../../api";
import { char, code, number } from "../../utils";
import { LoadingOutlined } from "@ant-design/icons";
import { reportCRUD } from "../../actions/report";
import moment from "moment";
import { CollectionCategory, QuarterCollection } from "./data";

const currentYear = new Date().getFullYear();

const disabledDate = (current) => {
  return current && current.year() > currentYear;
};

export const HomeContent = () => {
  const user = selector.User();
  const [yearData, setYearData] = useState(parseInt(moment().format("YYYY")));
  const [count, setCount] = useState(null);
  const [countLoading, setCountLoading] = useState(false);

  
  useEffect(() => {
    setCountLoading(true);
    setTimeout(() => {
      reportCRUD.loadTotalData(yearData).then((resp) => {
        setCount(resp.data);
      })
      .catch(() => {})
      .finally(() => {
        setCountLoading(false);
      });
    },500)
  },[yearData])

  
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="mb-3 pb-1">
            <div className="col-12">
              <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                <div className="flex-grow-1">
                  <h4 className="fs-16 mb-1">
                    Welcome, {char.toTitleCase(user.username)}...!
                  </h4>
                  <p className="text-muted mb-0">
                    Here's what's happening in our university.
                  </p>
                </div>
                <div className="mt-3 mt-lg-0">
                  <div className="row g-3 mb-0 align-items-center">
                    <div className="col-sm-auto">
                      <DatePicker
                        picker="year"
                        variant="borderless"
                        onChange={(_, dateString) =>
                          setYearData(
                            parseInt(moment(dateString).format("YYYY"))
                          )
                        }
                        allowClear={false}
                        disabledDate={disabledDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* end card header */}
            </div>
            {/*end col*/}
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-xl-4">
            <div className="card card-animate">
              {countLoading ? (
                <div className="text-center mt-4 mb-4">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                </div>
              ) : (
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        {" "}
                        total invoice
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div className="text-dark">
                      <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                        <span className="counter-value">
                          {count
                            ? char.toUpperCase(
                                number.fShortenNumber(count.invoice)
                              )
                            : "0.0"}
                        </span>
                      </h4>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-soft-success rounded fs-3">
                        <i className="bx bx-user text-success" />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card card-animate">
              {countLoading ? (
                <div className="text-center mt-4 mb-4">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                </div>
              ) : (
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        {" "}
                        paid invoice
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div className="text-dark">
                      <h4 className="fs-22 fw-semibold ff-primary mb-4">
                        <span className="counter-value">
                          {count
                            ? char.toUpperCase(
                                number.fShortenNumber(count.paid)
                              )
                            : "0.0"}
                        </span>
                      </h4>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-soft-primary rounded fs-3">
                        <i className="ri-ship-line text-primary" />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card card-animate">
              {countLoading ? (
                <div className="text-center mt-4 mb-4">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                        }}
                        spin
                      />
                    }
                  />
                </div>
              ) : (
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1 overflow-hidden">
                      <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                        {" "}
                        not paid invoice
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-end justify-content-between mt-4">
                    <div className="text-dark">
                      <h4 className="fs-22 fw-semibold ff-info mb-4">
                        <span className="counter-value">
                          {count
                            ? char.toUpperCase(
                                number.fShortenNumber(count.notPaid)
                              )
                            : "0.0"}
                        </span>
                      </h4>
                    </div>
                    <div className="avatar-sm flex-shrink-0">
                      <span className="avatar-title bg-soft-info rounded fs-3">
                        <i className="ri-luggage-cart-line text-info" />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-8 col-sm-12">
            <div className="card card-animate">
              <div className="card-body text-start">
                <QuarterCollection year={yearData} />
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="card card-animate">
              <div className="card-body text-start">
                <CollectionCategory year={yearData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
