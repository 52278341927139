import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { number } from "../../../utils";
import { reportCRUD } from "../../../actions/report";


export const CollectionCategory = (props) => {

    const { year } = props;

    const [labels, setLabels] = useState([]);
    const [series, setSeries] = useState([]);
    const [loading, setLoading] = useState(false);

     useEffect(() => {
       setLoading(true);
       reportCRUD
         .loadCategoryCollectionData(year)
         .then((resp) => {
           setLabels(Object.keys(resp.data));
           setSeries(Object.values(resp.data));
         })
         .catch(() => {})
         .finally(() => {
           setLoading(false);
         });
     }, [year]);

     const state = {
       series: series, // Ensure series contains the actual data points
       options: {
         chart: {
           width: 350,
           type: "pie",
         },
         labels: labels, // Labels corresponding to the series data
         colors: ["#2AAA8A", "#FF5733", "#FFC300", "#C70039", "#900C3F"], // Colors for each slice
         legend: {
           show: true, // Show legend
           position: "bottom", // Position the legend at the bottom
           horizontalAlign: "center",
         },
         responsive: [
           {
             breakpoint: 480,
             options: {
               chart: {
                 width: 250,
               },
             },
           },
         ],
         title: {
           text: "Collection Categories of " + year,
           align: "center",
         },
       },
     };

    
     

       console.log(labels);
       console.log(series);

    return (
      <>
        {loading ? (
          <div className="text-center mt-4 mb-4">
            <Spin
              indicator={
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                  }}
                  spin
                />
              }
            />
          </div>
        ) : (
          <Chart
            options={state.options}
            series={state.series}
            type="pie"
            height={400}
          />
        )}
      </>
    );

}