import { useEffect, useRef, useState } from "react";
import { QRCode } from "antd";
import { amountWord, char, number } from "../../../../utils";
import moment from "moment";
import { selector } from "../../../../api";
import { invoiceCRUD } from "../../../../actions/invoice";
import { gatewayCRUD } from "../../../../actions/gateway";
import { useReactToPrint } from "react-to-print";
import { Divider, Loader, Message, Modal, useToaster } from "rsuite";

export const InvoicePrint = (props) => {
  const { open, setOpen, dataId } = props;
  const [data, setData] = useState(null);
  const [invoLogs, setInvoLogs] = useState(null);
  const [loading, setLoading] = useState(false);
  const dataRef = useRef(null);
  const user = selector.User();

  const toaster = useToaster();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (dataId !== null) {
        invoiceCRUD
          .invoicePrint(dataId)
          .then((resp) => {
            setData(resp.data);
          })
          .catch((error) => {
            toaster.push(
              <Message showIcon type={"error"} header={"Error"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Oooh Sorry...!</p>
                  <p className="text-dark fs-12">
                    Something went wrong, Check your network connection.
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
          })
          .finally(() => {
            setLoading(false);
          });
        gatewayCRUD
          .loadInvoiceLogsDetails(dataId)
          .then((resp) => {
            setInvoLogs(resp.data);
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          });
      }
    }, 500);
  }, [dataId]);

  const handleGeneratePdf = useReactToPrint({
    content: () => dataRef.current,
  });

  const QRValue = {
    opType: "2",
    shortCode: invoLogs ? invoLogs.bill_code : "BiMIS",
    billReference: data ? data.control_number : "0",
    amount: data ? data.amount : number.fNumberDoublePoint(0),
    billCcy:
      data && data.currency ? char.toUpperCase(data.currency.code) : "TZS",
    billExprDt:
      data && moment(data.control_number_expiry_date).format("YYYY-MM-DD"),
    billPayOpt: invoLogs ? invoLogs.payment_option : "3",
    billRsv01: "",
  };

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="lg">
        <Modal.Header>
          <Modal.Title>
            <div className="row">
              <div className="col-md-6 col-sm-12">Invoice</div>
              <div className="col-md-6 col-sm-12 d-flex flex-row-reverse">
                <button
                  className="btn btn-soft-success btn-sm waves-effect waves-light"
                  onClick={handleGeneratePdf}
                >
                  <i className="bi bi-printer-fill m-r-5" /> Print
                </button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          <div
            className="container-fluid pt-3"
            style={{ backgroundColor: "whitesmoke" }}
          >
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div ref={dataRef}>
                      <div className="clearfix">
                        <div className="text-center">
                          <div
                            className="mb-2 text-center"
                            style={{ marginTop: -5 }}
                          >
                            <span>
                              <img
                                src="assets/images/suza.jpeg"
                                height={100}
                                alt=""
                              />
                            </span>
                          </div>
                          <p className="h6 fs-16 text-primary">
                            Revolution Government of Zanzibar
                          </p>
                          <p className="h6 fs-15 text-primary fw-bold">
                            State University of Zanzibar
                          </p>
                          <p className="h6 fs-15 text-primary">
                            Government Bill
                          </p>
                        </div>
                      </div>
                      <div
                        className="mt-2"
                        style={{ borderBottom: "1px dashed #000000" }}
                      ></div>
                      <div className="row mt-2">
                        <div className="col-3">
                          <p className="fs-6">
                            <span>Control Number</span>
                          </p>
                          <p className="fs-6">
                            <span>Payment Ref</span>
                          </p>
                          <p className="fs-6">
                            <span>Service Provider Code</span>
                          </p>
                          <p className="fs-6">
                            <span>Payer Name</span>
                          </p>
                          <p className="fs-6">
                            <span>Payer Phone</span>
                          </p>
                          <p className="fs-6">
                            <span>Bill Description</span>
                          </p>
                        </div>
                        <div className="col-4">
                          <p className="fs-6">
                            <span className="fw-bold">
                              : &nbsp; {data ? data.control_number : "N/A"}
                            </span>
                          </p>
                          <p className="fs-6">
                            <span className="fw-bold">
                              : &nbsp; {"Student Payment"}
                            </span>
                          </p>
                          <p className="fs-6">
                            <span className="fw-bold">: &nbsp; {"SP515"}</span>
                          </p>
                          <p className="fs-6">
                            <span className="fw-bold">
                              : &nbsp;{" "}
                              {data
                                ? char.toTitleCase(data.customer.name)
                                : "N/A"}
                            </span>
                          </p>
                          <p className="fs-6">
                            <span className="fw-bold">
                              : &nbsp;{" "}
                              {data ? data.customer.phone_number : "N/A"}
                            </span>
                          </p>
                          <p className="fs-6">
                            <span>
                              : &nbsp;{" "}
                              {data
                                ? char.toUpperCaseFirst(data.description)
                                : "N/A"}
                            </span>
                          </p>
                        </div>
                        {/* end col */}
                        <div className="col-5">
                          <div className=" float-end">
                            <div
                              style={{
                                height: "auto",
                                margin: "0 auto",
                                maxWidth: 150,
                                width: "100%",
                              }}
                              className="me-5"
                            >
                              <QRCode
                                errorLevel="H"
                                size={200}
                                iconSize={200 / 4}
                                value={JSON.stringify(QRValue)}
                                icon="assets/images/suza.jpeg"
                              />
                            </div>
                            <p
                              className="mt-1 fw-bold text-center"
                              style={{ fontSize: 12 }}
                            >
                              SCAN & PAY by MNO's APPs
                            </p>
                          </div>
                        </div>
                        {/* end col */}
                      </div>
                      <hr style={{ marginTop: 10 }} />
                      <div className="row" style={{ marginTop: -15 }}>
                        <div className="col-12">
                          {data &&
                            data.invoice?.map((dt, i) => (
                              <div className="row mt-2">
                                <div className="col-3">Billed Item {i + 1}</div>
                                <div className="col-6">
                                  {":"} &nbsp;
                                  {char.toUpperCaseFirst(dt.description)}
                                </div>
                                <div className="col-3 text-end">
                                  {number.fNumberDoublePoint(
                                    dt.equivalent_amount
                                  )}{" "}
                                  &nbsp; ({"TZS"})
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <hr style={{ marginTop: 10 }} />
                      <div className="row" style={{ marginTop: -10 }}>
                        <div className="col-3 "></div>
                        <div className="col-6  fw-bold">
                          {"Total Billed Amount"}
                        </div>
                        <div className="text-end col-3  fw-bold">
                          {data &&
                            number.fNumberDoublePoint(
                              data.equivalent_amount
                            )}{" "}
                          &nbsp;(
                          {"TZS"})
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-3">{"Amount in Words"}</div>
                        <div className="col-6 fw-bold">
                          {":"} &nbsp;{" "}
                          {data
                            ? char.toLowerCase(
                                amountWord.toWords(
                                  number.fNumber(data.equivalent_amount)
                                )
                              )
                            : "N/A"}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3">{"Expires on"}</div>
                        <div className="col-6">
                          {":"} &nbsp;{" "}
                          {moment(
                            data ? data.control_number_expiry_date : new Date()
                          ).format("D MMM YYYY")}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3">{"Prepared By"}</div>
                        <div className="col-6 fw-bold">
                          {":"} &nbsp;{" "}
                          {data
                            ? data.created_by.first_name
                              ? char.toUpperCase(data.created_by.first_name)
                              : char.toUpperCase(data.created_by.username)
                            : "N/A"}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3">{"Collection Center"}</div>
                        <div className="col-6">
                          {":"} &nbsp; {"State University of Zanzibar"}
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-3">{"Printed By"}</div>
                        <div className="col-6">
                          {":"} &nbsp;{" "}
                          {user
                            ? user.first_name
                              ? char.toUpperCase(user.first_name)
                              : char.toUpperCase(user.username)
                            : "N/A"}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-3">{"Printed On"}</div>
                        <div className="col-6">
                          {":"} &nbsp;{" "}
                          {moment(data && data.created_at).format("D MMM YYYY")}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-3">{"Signature"}</div>
                        <div className="col-6">
                          {":"} &nbsp;{" "}
                          {
                            "........................................................................"
                          }
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <p className="fw-bold">Jinsi ya Kulipa</p>
                            </div>
                            <div className="col-12">
                              <p style={{ lineHeight: 2 }}>
                                1. Kupitia Benki: Fika tawi lolote au wakala wa
                                Benki ya CRDB, NMB, BOT. Namba ya kumbukumbu:{" "}
                                {data ? data.control_number : "N/A"}
                              </p>
                              <p>2. Kupitia mitandao ya Simu:</p>
                              <ul>
                                <li>Ingia kwenye menyu ya mtandao husika</li>
                                <li>Chagua 4 (Lipa Bili)</li>
                                <li>Chagua 5 (Malipo ya Serikali)</li>
                                <li>
                                  Ingiza {data ? data.control_number : "N/A"}{" "}
                                  kama namba ya kumbukumbu
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-12">
                              <p className="fw-bold">How to Pay</p>
                            </div>
                            <div className="col-12">
                              <p style={{ lineHeight: 2 }}>
                                1. Via Bank: Visit any bank branch or bank agent
                                of CRDB, NMB, BOT. Reference Number:{" "}
                                {data ? data.control_number : "N/A"}
                              </p>
                              <p>2. Via Mobile Network Operators (MNO):</p>
                              <ul>
                                <li>
                                  Enter to the respective USSD Menu of MNO
                                </li>
                                <li>Select 4 (Make Payment)</li>
                                <li>Select 5 (Government Payment)</li>
                                <li>
                                  Enter {data ? data.control_number : "N/A"} as
                                  reference number
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <div className="text-center text-primary">
                            <p>
                              BiMS © {new Date().getFullYear()} All Rights
                              Reserved (SUZA)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-print-none">
                      <div className="text-end"></div>
                    </div>
                  </div>
                </div>{" "}
                {/* end card*/}
              </div>{" "}
              {/* end col */}
            </div>
            {loading && (
              <Loader
                size="lg"
                speed="slow"
                center
                backdrop
                style={{ color: "orange" }}
                content="loading..."
                vertical
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
