import React, { useState } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  LockOutlined,
  LogoutOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme, Dropdown, Space } from "antd";
import { Footer } from "antd/es/layout/layout";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { selector } from "../api";
import { authActions } from "../actions/auth";
import { useDispatch } from "react-redux";
const { Header, Sider, Content } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const item = [
  getItem(
    <Link to={"/home"} className="text-decoration-none fs-16">
      Dashboard
    </Link>,
    "home",
    <i className="ri-apps-2-line fs-4" />
  ),
  getItem(
    <span className="fs-16">Configuration</span>,
    "sub1",
    <i className="ri-settings-line fs-4" />,
    [
      getItem(
        <Link to={"/dictionary"} className="text-decoration-none fs-15">
          Dictionary
        </Link>,
        "dictionary"
      ),
      getItem(
        <Link to={"/dictionary-item"} className="text-decoration-none fs-15">
          Dictionary Items
        </Link>,
        "item"
      ),
      getItem(
        <Link to={"/currency"} className="text-decoration-none fs-15">
          Currency
        </Link>,
        "currency"
      ),
      getItem(
        <Link to={"/exchange-rate"} className="text-decoration-none fs-15">
          Exchange Rate
        </Link>,
        "rate"
      ),
      getItem(
        <Link to={"/academic-year"} className="text-decoration-none fs-15">
          Academic Year
        </Link>,
        "academic"
      ),
      getItem(
        <Link to={"/financial-year"} className="text-decoration-none fs-15">
          Financial Year
        </Link>,
        "financial"
      ),
      getItem(
        <Link to={"/study-year"} className="text-decoration-none fs-15">
          Study Year
        </Link>,
        "study"
      ),
      getItem(
        <Link to={"/school"} className="text-decoration-none fs-15">
          School
        </Link>,
        "school"
      ),
      getItem(
        <Link to={"/department"} className="text-decoration-none fs-15">
          Department
        </Link>,
        "department"
      ),
      getItem(
        <Link to={"/programme"} className="text-decoration-none fs-15">
          Programme
        </Link>,
        "programme"
      ),
    ]
  ),
  getItem(
    <span className="fs-16">Customers</span>,
    "sub2",
    <i className="ri-group-line fs-4" />,
    [
      getItem(
        <Link to={"/student"} className="text-decoration-none fs-15">
          Student
        </Link>,
        "student"
      ),
      getItem(
        <Link to={"/addmission"} className="text-decoration-none fs-15">
          Addmission
        </Link>,
        "addmission"
      ),
      getItem(
        <Link to={"/customer"} className="text-decoration-none fs-15">
          Customer
        </Link>,
        "customer"
      ),
      getItem(
        <Link to={"/loan-board"} className="text-decoration-none fs-15">
          Loan Board
        </Link>,
        "loan"
      ),
    ]
  ),
  getItem(
    <span className="fs-16">Adjustment</span>,
    "sub3",
    <i className="ri-install-line fs-4" />,
    [
      getItem(
        <Link to={"/allocation"} className="text-decoration-none fs-15">
          Allocation
        </Link>,
        "allocation"
      ),
      getItem(
        <Link to={"/journal"} className="text-decoration-none fs-15">
          General Journal
        </Link>,
        "journal"
      ),
    ]
  ),
  getItem(
    <span className="fs-16">Accounting</span>,
    "sub4",
    <i className="ri-hand-coin-fill fs-4" />,
    [
      // getItem(
      //   <Link to={"/gfscode"} className="text-decoration-none fs-15">
      //     GFS Code
      //   </Link>,
      //   "gfscode"
      // ),
      getItem(
        <span className="fs-15">Receivable Account</span>,
        "receivable",
        "",
        [
          getItem(
            <Link
              to={"/receivable"}
              state={{ account: "student" }}
              className="text-decoration-none fs-15"
            >
              Student Account
            </Link>,
            "studentaccount"
          ),
          getItem(
            <Link
              to={"/receivable"}
              state={{ account: "addmission" }}
              className="text-decoration-none fs-15"
            >
              Admission Account
            </Link>,
            "admissionaccount"
          ),
          getItem(
            <Link
              to={"/receivable"}
              state={{ account: "customer" }}
              className="text-decoration-none fs-15"
            >
              Customer Account
            </Link>,
            "customeraccount"
          ),
          getItem(
            <Link
              to={"/receivable"}
              state={{ account: "loan" }}
              className="text-decoration-none fs-15"
            >
              Loan Board Account
            </Link>,
            "loanaccount"
          ),
        ]
      ),
      getItem(
        <Link to={"/collection-center"} className="text-decoration-none fs-15">
          Collection Center
        </Link>,
        "center"
      ),
    ]
  ),
  getItem(
    <span className="fs-16">Invoice</span>,
    "sub5",
    <i className="ri-stack-line fs-4" />,
    [
      getItem(<span className="fs-15">Invoice</span>, "invoice", "", [
        getItem(
          <Link
            to={"/invoice"}
            state={{ page: "invoice" }}
            className="text-decoration-none fs-15"
          >
            All Invoice
          </Link>,
          "allinvoice"
        ),
        getItem(
          <Link
            to={"/invoice"}
            state={{ page: "student" }}
            className="text-decoration-none fs-15"
          >
            Student Invoice
          </Link>,
          "studentinvoice"
        ),
        getItem(
          <Link
            to={"/invoice"}
            state={{ page: "admission" }}
            className="text-decoration-none fs-15"
          >
            Admission Invoice
          </Link>,
          "admissioninvoice"
        ),
        getItem(
          <Link
            to={"/invoice"}
            state={{ page: "customer" }}
            className="text-decoration-none fs-15"
          >
            Customer Invoice
          </Link>,
          "customerinvoice"
        ),
      ]),
      getItem(
        <Link to={"/reconciliation"} className="text-decoration-none fs-15">
          Reconciliation
        </Link>,
        "reconciliation"
      ),
    ]
  ),
  getItem(
    <span className="fs-16">Reports</span>,
    "sub6",
    <i className="ri-stack-line fs-4" />,
    [
      getItem(<span className="fs-15">Invoice</span>, "invoice_repo", "", [
        getItem(
          <Link
            to={"/invoice-report"}
            state={{ page: "invoice" }}
            className="text-decoration-none fs-15"
          >
            All Invoice
          </Link>,
          "allinvoice"
        ),
        getItem(
          <Link
            to={"/invoice-report"}
            state={{ page: "full" }}
            className="text-decoration-none fs-15"
          >
            Full Paid Invoice
          </Link>,
          "fullinvoice"
        ),
        getItem(
          <Link
            to={"/invoice-report"}
            state={{ page: "partial" }}
            className="text-decoration-none fs-15"
          >
            Partial Paid Invoice
          </Link>,
          "partialinvoice"
        ),
        getItem(
          <Link
            to={"/invoice-report"}
            state={{ page: "unpaid" }}
            className="text-decoration-none fs-15"
          >
            None Paid Invoice
          </Link>,
          "noneinvoice"
        ),
      ]),
      getItem(<span className="fs-15">Collection</span>, "collection_repo", "", [
        getItem(
          <Link
            to={"/collection-report"}
            state={{ page: "general" }}
            className="text-decoration-none fs-15"
          >
            General Collection
          </Link>,
          "general"
        ),
      ]),
    ]
  ),
];

function getItems(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export default function SuzaLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("persist:root");
    navigate("/");
    document.location.reload();
  };

  const handleLock = () => {
    dispatch(authActions.setLock(true));
    dispatch(authActions.setAuth(null));
    navigate("/lockscreen", { replace: true });
    document.location.reload();
  };

  const user = selector.User();

  const items = [
    getItems("Profile", "1profile", <UserOutlined />, ""),
    getItems(
      <Link className="text-decoration-none" to={"#"} onClick={handleLock}>
        Lock Screen
      </Link>,
      "lock",
      <LockOutlined />,
      ""
    ),
    getItems(
      <Link className="text-decoration-none" to={"#"} onClick={handleLogout}>
        Logout
      </Link>,
      "out",
      <LogoutOutlined />,
      ""
    ),
  ];

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        width={250}
      >
        {collapsed ? (
          <div className="demo-logo-vertical mb-5 text-center mt-4">
            <span className="h3 text-white fw-bold">BiMS</span>
          </div>
        ) : (
          <div className="demo-logo-vertical mb-5 text-center mt-4">
            <span className="h3 text-white fw-bold">SUZA | BiMS</span>
          </div>
        )}
        <Menu
          theme="dark"
          className="fs-6"
          defaultSelectedKeys={["home"]}
          mode="inline"
          items={item}
        />
      </Sider>
      <Layout
        style={{
          marginLeft: 250,
        }}
      >
        <Header
          style={{
            padding: 0,
            backgroundColor: "#23adf6",
            color: "#ffffff",
            borderBottom: "2px solid #dee300",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: 64,
              height: 64,
            }}
          />
          <div className="position-absolute top-0 end-0">
            <Dropdown menu={{ items }} className="me-4 cursor-pointer">
              <Space>
                <i className="ri-user-line fs-5" />
                {user.username}
              </Space>
            </Dropdown>
          </div>
        </Header>
        <Content
          style={{
            margin: "24px 16px",
            padding: 24,
            minHeight: 280,
            background: "whitesmoke",
          }}
        >
          <Outlet />
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          <p>
            <span className="fw-bold">BiMS ©{new Date().getFullYear()}</span>{" "}
            &nbsp; | Created by <Link className="text-success">SUZA</Link>
          </p>
          <p className="fw-light h6 text-success fs-10 fst-italic mt-2">
            {" "}
            ---- catalist for social change ----
          </p>
        </Footer>
      </Layout>
    </Layout>
  );
}
