import axios from "axios";
import { auth } from "../../api";

const apiUrl = auth;

export function loadToken(user) {
  return axios.post(`${apiUrl}/token/obtain`, user);
}

export function refreshToken(token) {
  return axios.post(`${apiUrl}/token/refresh/`, token);
}

export function addUser(data) {
  return axios.post(`${apiUrl}/user`, data);
}

export function updateUser(id, data) {
  return axios.put(`${apiUrl}/user/details/${id}`, data);
}

export function updateUserActive(id) {
  return axios.put(`${apiUrl}/user/active/${id}`);
}

export function updateUserNotActive(id) {
  return axios.put(`${apiUrl}/user/not/active/${id}`);
}

export function updatePassword(id, data, headers) {
  return axios.put(`${apiUrl}/user/password/${id}`, data, headers);
}

export function loadUser(user) {
  return axios.get(`${apiUrl}/user/${user}`);
}

export function loadUserGroups() {
  return axios.get(`${apiUrl}/group`);
}

export function loadUserList(pageLimit, page, search) {
  return axios.get(
    `${apiUrl}/user/filter?limit=${pageLimit}&offset=${page}&search=${search}`
  );
}

export function loadUserFilter(pageLimit, page, search) {
  return axios.get(
    `${apiUrl}/user/list?limit=${pageLimit}&offset=${page}&search=${search}`
  );
}
