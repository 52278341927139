import { InvoiceReportContent } from "../../components/report/invoice/content";


export default function InvoiceReport(props) {

    return(
        <>
        <InvoiceReportContent/>
        </>
    )

}