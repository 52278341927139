import axios from "axios";
import { invoice, report } from "../../api";

const apiUrl = report;

//============================================total=====================================================================
export function loadTotalData(year) {
  return axios.get(`${apiUrl}/data/total/${year}`);
}
export function loadQuarterCollectionData(year) {
  return axios.get(`${apiUrl}/data/quarter/${year}`);
}
export function loadCategoryCollectionData(year) {
  return axios.get(`${apiUrl}/data/collection/category/${year}`);
}

//=================================================report invoice=======================================================
export function loadReportInvoice(pageLimit, page, year, date, status, search, order) {
  return axios.get(
    `${apiUrl}/all/invoice?limit=${pageLimit}&offset=${page}&financial_year__id=${year}&created_at__range=${date}&status_code__in=${status}&search=${search}&ordering=${order}`
  );
}

export function loadAdmissionInvoiceReport(
  pageLimit,
  page,
  year,
  date,
  status,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/admission/invoice?limit=${pageLimit}&offset=${page}&financial_year__id=${year}&created_at__range=${date}&status_code__in=${status}&search=${search}&ordering=${order}`
  );
}

export function loadStudentInvoiceReport(
  pageLimit,
  page,
  year,
  date,
  status,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/student/invoice?limit=${pageLimit}&offset=${page}&financial_year__id=${year}&created_at__range=${date}&status_code__in=${status}&search=${search}&ordering=${order}`
  );
}

export function loadStaffInvoiceReport(
  pageLimit,
  page,
  year,
  date,
  status,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/staff/invoice?limit=${pageLimit}&offset=${page}&financial_year__id=${year}&created_at__range=${date}&status_code__in=${status}&search=${search}&ordering=${order}`
  );
}

export function loadCustomerInvoiceReport(
  pageLimit,
  page,
  year,
  date,
  status,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/customer/invoice?limit=${pageLimit}&offset=${page}&financial_year__id=${year}&created_at__range=${date}&status_code__in=${status}&search=${search}&ordering=${order}`
  );
}

export function loadBoardInvoiceReport(
  pageLimit,
  page,
  year,
  date,
  status,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/board/invoice?limit=${pageLimit}&offset=${page}&financial_year__id=${year}&created_at__range=${date}&status_code__in=${status}&search=${search}&ordering=${order}`
  );
}


//=================================================report invoice payment=======================================================
export function loadPaymentLogsReportInvoice(pageLimit, page, date, search, order) {
  return axios.get(
    `${apiUrl}/payment/collection?limit=${pageLimit}&offset=${page}&created_at__range=${date}&search=${search}&ordering=${order}`
  );
}