import axios from "axios";
import { accounting } from "../../api";

const apiUrl = accounting;

//============================================student=====================================================================
export function loadStudent(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/student/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function addStudent(data) {
  return axios.post(`${apiUrl}/student`, data);
}

//============================================addmission=====================================================================
export function loadStudentAdmission(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/student-admission/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

//============================================customer=====================================================================
export function loadCustomer(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/customer/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function addCustomer(data) {
  return axios.post(`${apiUrl}/customer`, data);
}

export function loadCustomerInfoLast() {
  return axios.get(`${apiUrl}/customer-info/last`);
}

export function addCustomerAccount(data) {
  return axios.post(`${apiUrl}/customer-account`, data);
}

//============================================loan-board=====================================================================
export function loadLoanBoard(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/loan-board/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function addLoanBoard(data) {
  return axios.post(`${apiUrl}/loan-board`, data);
}

export function loadCheckLoanBoard(code, active) {
  return axios.get(
    `${apiUrl}/loan-board/list?acronym__in=${code}&is_active=${active}`
  );
}

//============================================loan-board=====================================================================
export function loadCollectionCenter(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/collection-center/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadSingleCollectionCenter(pageLimit, page, id, search, order) {
  return axios.get(
    `${apiUrl}/collection-center/list?limit=${pageLimit}&offset=${page}&id__in=${id}&search=${search}&ordering=${order}`
  );
}

export function addCollectionCenter(data) {
  return axios.post(`${apiUrl}/collection-center`, data);
}

export function loadCollectionItem(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/collection-item/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function loadCollectionCenterItem(
  pageLimit,
  page,
  center,
  search,
  order
) {
  return axios.get(
    `${apiUrl}/collection-item/list?limit=${pageLimit}&offset=${page}&account__collection_center__id=${center}&search=${search}&ordering=${order}`
  );
}

export function addCollectionItem(data) {
  return axios.post(`${apiUrl}/collection-item`, data);
}

export function addInstAccount(data) {
  return axios.post(`${apiUrl}/institution-account`, data);
}

//============================================================ validation crud =========================================================================
export function loadCheckPaymentItem(code) {
  return axios.get(`${apiUrl}/collection-item/list?code__in=${code}`);
}
export function loadAccountCode(code) {
  return axios.get(`${apiUrl}/account/code?account_code__in=${code}`);
}

export function loadCheckStudent(reg) {
  return axios.get(`${apiUrl}/student/list?registration_number__in=${reg}`);
}

//============================================================ validation crud =========================================================================

export function loadAccount(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/account/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

//============================================================ validation crud =========================================================================

export function loadCenterFee(pageLimit, page, center, search, order) {
  return axios.get(
    `${apiUrl}/center-fee/list?limit=${pageLimit}&offset=${page}&center__id__in=${center}&search=${search}&ordering=${order}`
  );
}

export function addCenterFee(data) {
  return axios.post(`${apiUrl}/center-fee`, data);
}

//============================================loan-board=====================================================================
export function loadGFSCode(pageLimit, page, search, order) {
  return axios.get(
    `${apiUrl}/gfs-code/list?limit=${pageLimit}&offset=${page}&search=${search}&ordering=${order}`
  );
}

export function addGFSCode(data) {
  return axios.post(`${apiUrl}/gfs-code`, data);
}
