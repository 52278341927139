import { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";

import { selector } from "../../../../api";
import { Button, DatePicker, Divider, Select, notification } from "antd";
import { char } from "../../../../utils";
import { configurationCRUD } from "../../../../actions/configuration";
import { accountingCRUD } from "../../../../actions/accounting";
import { invoiceCRUD } from "../../../../actions/invoice";
import moment from "moment";
import { PlusCircleOutlined } from "@ant-design/icons";
import { gatewayCRUD } from "../../../../actions/gateway";
import { Modal } from "rsuite";

const customerSchema = Yup.object().shape({
  description: Yup.string().required("This field is reuired"),
  customer: Yup.string().required("This field is reuired"),
  currency: Yup.string().required("This field is reuired"),
  control_number_expiry_date: Yup.date()
    .min(new Date(), "Exipry date can't be less than today")
    .required("This field is required"),
  payment_option: Yup.string().required("This field is reuired"),
  items: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required("This field is required"),
      collection_item: Yup.string().required("This field is required"),
      amount: Yup.string()
        .matches(/^[0-9]+$/, "Amount must be a digits")
        .required("This field is required"),
    })
  ),
});

const initialValues = {
  description: "",
  customer: "",
  currency: "",
  payment_option: "",
  control_number_expiry_date: "",
  items: [{ description: "", collection_item: "", amount: 1 }],
  isPosted: true,
};

export const CustomerInvoiceAdd = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [api, contextHolder] = notification.useNotification();
  const [financial, setFinancial] = useState({});
  const [currency, setCurrency] = useState([]);
  const [currencySearch, setCurrencySearch] = useState("");
  const [currencyId, setCurrencyId] = useState(null);
  const [rate, setRate] = useState({});
  const [customerData, setCustomerData] = useState([]);
  const [customerSearch, setCustomerSearch] = useState("");
  const [currencyCode, setCurrencyCode] = useState(null);
  const [payment, setPayment] = useState([]);
  const [paymentSearch, setPaymentSearch] = useState("");
  const [invoiceItem] = useState([]);
  const [invoice, setInvoice] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      configurationCRUD
        .loadActiveFinancialYear()
        .then((resp) => {
          setFinancial(resp.data);
        })
        .catch((error) => {});
      configurationCRUD
        .loadCurrency(50, 0, currencySearch, "code")
        .then((resp) => {
          setCurrency(resp.data.results);
        })
        .catch((error) => {});

      accountingCRUD
        .loadCollectionItem(50, 0, paymentSearch, "name")
        .then((resp) => {
          setPayment(resp.data.results);
        })
        .catch((error) => {});

      invoiceCRUD
        .loadCustomerAccount(50, 0, customerSearch, "name")
        .then((resp) => {
          setCustomerData(resp.data.results);
        })
        .catch((error) => {});
      invoiceCRUD.loadInvoiceLast().then((resp) => {
        setInvoice(resp.data);
      });
    }, 500);
  }, [currencySearch, customerSearch, paymentSearch]);

  useEffect(() => {
    if (currencyId) {
      setTimeout(() => {
        configurationCRUD
          .loadCurrencyExchangeRate(currencyId)
          .then((resp) => {
            setRate(resp.data);
          })
          .catch(() => {});
        configurationCRUD
          .loadCurrencyDetails(currencyId)
          .then((resp) => {
            setCurrencyCode(resp.data.code);
          })
          .catch(() => {});
      }, 50);
    }
  }, [currencyId]);

  const handleSubmit = (values) => {
    setLoading(true);
    const {
      description,
      currency,
      customer,
      items,
      payment_option,
      isPosted,
      control_number_expiry_date,
    } = values;

    const dateD = moment(control_number_expiry_date).format("DD");
    const monthM = moment(control_number_expiry_date).format("MM");
    const yearY = moment(control_number_expiry_date).format("YYYY");

    const expiry_date = yearY + "-" + monthM + "-" + dateD + "T06:15:00";

    const sumValue = items
      .map((datum) => parseFloat(datum.amount))
      .reduce((a, b) => a + b);

    const number = invoice.id.toString();

    const data = {
      amount: sumValue,
      equivalent_amount: sumValue * rate.rate,
      name:
        number.length <= 2
          ? "INV100" + number
          : number.length >= 4
          ? "INV1" + number
          : "INV10" + number,
      description,
      currency,
      exchange_rate: rate.id,
      customer,
      control_number: null,
      is_posted: isPosted,
      posted_by: isPosted ? user.id : "",
      status: isPosted ? "waiting controll number" : "pending not posted",
      status_code: isPosted ? 200 : 201,
      control_number_expiry_date: expiry_date,
      financial_year: financial.id,
      created_by: user.id,
    };

    invoiceCRUD
      .addInvoice(data)
      .then((resp) => {
        const invo = resp.data.id;

        for (let i = 0; i < items.length; i++) {
          const data = {
            invoice: invo,
            description: items[i].description,
            collection_item: items[i].collection_item,
            amount: parseFloat(items[i].amount),
            equivalent_amount: parseFloat(items[i].amount) * rate.rate,
            created_by: user.id,
          };

          invoiceItem.push(data);
        }

        if (isPosted) {
          const dataPost = {
            request_id: parseInt(0),
            system_code: "BiMS",
            service_code: "BiMS0" + invo,
            amount: parseFloat(data.amount),
            equivalent_amount: parseFloat(data.equivalent_amount),
            control_number: null,
            control_number_date: null,
            control_number_expiry_date: data.control_number_expiry_date,
            payment_option: parseInt(payment_option),
            bill_code: "BiMS00" + invo,
            bill_currency: char.toUpperCase(currencyCode),
            bill_description: data.description,
            payer_id: data.customer,
            invoice_id: invo,
            status: "waiting controll number",
            status_code: 200,
            is_requested: false,
            is_responded: false,
            is_canceled: false,
            reuested_at: null,
            responded_at: null,
          };

          invoiceCRUD.addInvoiceItem(invoiceItem).then(() => {});
          gatewayCRUD.addInterInvoice(dataPost).then(() => {});
          api.success({
            message: "Thank you",
            description:
              "🎉 Congratulations! Your data has been successfully saved!",
            placement: "topRight",
          });
          setIndex(index + 1);
          setLoading(false);
          setOpen(false);
        } else {
          invoiceCRUD.addInvoiceItem(invoiceItem).then(() => {});
          api.success({
            message: "Thank you",
            description:
              "🎉 Congratulations! Your data has been successfully saved!",
            placement: "topRight",
          });
          setIndex(index + 1);
          setLoading(false);
          setOpen(false);
        }
      })
      .catch((error) => {
        api.error({
          message: error.message,
          description:
            "Oops! It looks like there is some errors. Please correct your error and try again",
          placement: "topRight",
        });
        setLoading(false);
      });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      {contextHolder}

      <Modal open={open} onClose={() => setOpen(false)} size="lg">
        <Modal.Header>
          <Modal.Title>New Customer Invoice</Modal.Title>
        </Modal.Header>
        <Divider />
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
          validationSchema={customerSchema}
        >
          {({ setFieldValue, values, isSubmitting, isValid, resetForm }) => (
            <Form>
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label>
                          Description <span className="text-danger">*</span>
                        </label>
                        <Field
                          type="text"
                          className="form-control"
                          placeholder="Invoice Description"
                          name={"description"}
                        />
                        <div className="fw-light mt-1 fs-15">
                          <span role="alert" className="text-danger">
                            <ErrorMessage name={"description"} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label>
                          Payment Options <span className="text-danger">*</span>
                        </label>
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Select payment options"
                          optionFilterProp="children"
                          onChange={(e) => {
                            setFieldValue("payment_option", e);
                          }}
                          onSearch={(e) => setCurrencySearch(e)}
                          filterOption={filterOption}
                          options={[
                            {
                              value: 1,
                              label: "Full",
                            },
                            {
                              value: 2,
                              label: "Partial",
                            },
                            {
                              value: 3,
                              label: "Exact",
                            },
                          ]}
                        />
                        <div className="fw-light mt-1 fs-15">
                          <span role="alert" className="text-danger">
                            <ErrorMessage name={"payment_option"} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group">
                        <label>
                          Expiry Date <span className="text-danger">*</span>
                        </label>
                        <DatePicker
                          style={{ width: "100%" }}
                          size="large"
                          onChange={(_, dateString) => {
                            setFieldValue(
                              "control_number_expiry_date",
                              dateString
                            );
                          }}
                        />
                        <div className="fw-light mt-1 fs-15">
                          <span role="alert" className="text-danger">
                            <ErrorMessage name={"control_number_expiry_date"} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>
                          Currency <span className="text-danger">*</span>
                        </label>
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Select currency"
                          optionFilterProp="children"
                          onChange={(e) => {
                            setFieldValue("currency", e);
                            setCurrencyId(e);
                          }}
                          onSearch={(e) => setCurrencySearch(e)}
                          filterOption={filterOption}
                          options={currency?.map((dt) => ({
                            value: dt.id,
                            label:
                              char.toUpperCase(dt.code) +
                              " --- " +
                              char.toTitleCase(dt.name),
                          }))}
                        />
                        <div className="fw-light mt-1 fs-15">
                          <span role="alert" className="text-danger">
                            <ErrorMessage name={"currency"} />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <div className="form-group">
                        <label>
                          Customer <span className="text-danger">*</span>
                        </label>
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Select customer"
                          optionFilterProp="children"
                          onChange={(e) => {
                            setFieldValue("customer", e);
                          }}
                          onSearch={(e) => setCustomerSearch(e)}
                          filterOption={filterOption}
                          options={customerData?.map((dt) => ({
                            value: dt.id,
                            label:
                              char.toUpperCase(dt.tin) +
                              " --- " +
                              char.toUpperCase(dt.name),
                          }))}
                        />
                        <div className="fw-light mt-1 fs-15">
                          <span role="alert" className="text-danger">
                            <ErrorMessage name={"customer"} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <FieldArray name="items">
                        {({ remove, push }) => (
                          <div>
                            <div className="row mt-2">
                              <div className="col-12 mb-2">
                                <div style={{ marginTop: -20 }}>
                                  <span className="mt-4">
                                    <p className="fw-bold">Invoice Items</p>
                                  </span>
                                  <span
                                    className="d-flex justify-content-end"
                                    style={{ marginTop: -20 }}
                                  >
                                    <Button
                                      type="primary"
                                      icon={<PlusCircleOutlined />}
                                      onClick={() =>
                                        push({
                                          description: "",
                                          collection_item: "",
                                          amount: "",
                                        })
                                      }
                                    >
                                      <span>add row</span>
                                    </Button>
                                  </span>
                                </div>
                              </div>
                              <hr />
                            </div>
                            {values.items.length > 0 &&
                              values.items.map((_, i) => (
                                <>
                                  <div key={i} className="mb-3">
                                    <div className="row">
                                      <div className={"col-md-4 col-sm-12"}>
                                        <label className="text-body mb-2">
                                          Item Description
                                          <span className="text-danger">
                                            &nbsp;*
                                          </span>
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          placeholder="Invoice Item Description"
                                          name={`items.${i}.description`}
                                        />
                                        <div className="fw-light mt-1 fs-15">
                                          <span
                                            role="alert"
                                            className="text-danger"
                                          >
                                            <ErrorMessage
                                              name={`items.${i}.description`}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-4 col-sm-12">
                                        <label className="text-body mb-2">
                                          Payment Item
                                          <span className="text-danger">
                                            &nbsp;*
                                          </span>
                                        </label>
                                        <Select
                                          size="large"
                                          style={{ width: "100%" }}
                                          showSearch
                                          placeholder="Select a payment item"
                                          optionFilterProp="children"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `items.${i}.collection_item`,
                                              e
                                            )
                                          }
                                          onSearch={(e) => setPaymentSearch(e)}
                                          filterOption={filterOption}
                                          options={payment?.map((dt) => ({
                                            value: dt.id,
                                            label:
                                              char.toUpperCase(dt.code) +
                                              " --- " +
                                              char.toUpperCase(dt.name),
                                          }))}
                                        />
                                        <div
                                          className="fw-light mt-1"
                                          style={{ fontSize: "12px" }}
                                        >
                                          <span
                                            role="alert"
                                            className="text-danger"
                                          >
                                            <ErrorMessage
                                              name={`items.${i}.collection_item`}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          values.items.length > 1
                                            ? "col-md-3 col-sm-12"
                                            : "col-md-4 col-sm-12"
                                        }
                                      >
                                        <label className="text-body mb-2">
                                          Amount{" "}
                                          <span className="text-danger">
                                            &nbsp;*
                                          </span>
                                        </label>
                                        <Field
                                          type={"text"}
                                          className="form-control"
                                          name={`items.${i}.amount`}
                                          placeholder={"Invoice item amount"}
                                        />
                                        <div
                                          className="fw-light mt-1"
                                          style={{ fontSize: "12px" }}
                                        >
                                          <span
                                            role="alert"
                                            className="text-danger"
                                          >
                                            <ErrorMessage
                                              name={`items.${i}.amount`}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      {values.items.length > 1 && (
                                        <div
                                          className="col-md-1 text-right"
                                          style={{ marginTop: 28 }}
                                        >
                                          <>
                                            <button
                                              type="button"
                                              className="btn btn-danger btn-sm"
                                              onClick={() => remove(i)}
                                            >
                                              <i className="bi bi-patch-minus la-lg"></i>
                                            </button>
                                          </>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ))}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                </div>
              </>

              <Divider style={{ marginTop: -10 }} />
              <Modal.Footer>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                      <span className="fs-18 text-danger"> * &nbsp;</span>{" "}
                      Required field
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                      <button
                        onClick={() => setOpen(false)}
                        type="button"
                        className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                      >
                        <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                        disabled={isSubmitting}
                      >
                        {!loading && (
                          <span className="indicator-label">
                            <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                            Save
                          </span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
