import { useLocation } from "react-router-dom";
import { FullPaidData, InvoiceData, NonePaidData, PartialPaidData } from "./data";


export const InvoiceReportContent = (props) => {

    let location = useLocation();

    return (
      <>
        <div className="container-fluid">
          <div className="row mb-2" style={{ marginTop: -30 }}>
            <div className="col-12">
              <p className="h6 fs-18 fw-bold text-dark">Invoice Report</p>
            </div>
            <hr className="mt-3" />
          </div>
          {location?.state?.page === "invoice" ? (
            <InvoiceData />
          ) : location?.state?.page === "full" ? (
            <FullPaidData />
          ) : location?.state?.page === "partial" ? (
            <PartialPaidData />
          ) : location?.state?.page === "unpaid" ? (
            <NonePaidData />
          ) : null}
        </div>
      </>
    );
}