import { useEffect, useState } from "react";
import { TableShared } from "../../../shared";
import { adjustmentCRUD } from "../../../actions/adjustment";
import { Divider, Message, Modal, useToaster } from "rsuite";
import { char, number } from "../../../utils";
import moment from "moment";
import { Button, Dropdown, Space } from "antd";
import { MoreOutlined, EyeOutlined } from "@ant-design/icons";

export const AllocatedStudent = (props) => {
  const { allocationId, open, setOpen } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(false);
  const [search, setSearch] = useState("");
  const [postPerPage, setPostPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [dataId, setDataId] = useState(null);
  const toaster = useToaster();

  useEffect(() => {
    if (allocationId) {
      setLoading(true);
      setTimeout(() => {
        adjustmentCRUD
          .loadAllocatedStudent(
            postPerPage,
            nextPage,
            allocationId,
            search,
            "id"
          )
          .then((resp) => {
            setData(resp.data.results);
            setCount(resp.data.count);
          })
          .catch((error) => {
            toaster.push(
              <Message showIcon type={"error"} header={"Error"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Oooh Sorry...!</p>
                  <p className="text-dark fs-12">
                    Something went wrong, Check your network connection.
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500);
    }
  }, [allocationId, postPerPage, nextPage, page, search]);

  const defaultColumns = [
    {
      key: "sn",
      title: "#",
      dataIndex: "sn",
      fixed: true,
      width: 150,
    },
    {
      key: "reg",
      title: "Registration",
      dataIndex: "reg",
      sortable: true,
      width: 200,
    },
    {
      key: "name",
      title: "Full Name",
      dataIndex: "name",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "batch_no",
      title: "Batch No.",
      dataIndex: "batch_no",
      sortable: true,
      width: 150,
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      sortable: true,
      width: 200,
    },
  ];

  const dataItem = data?.map((dt, i) => ({
    id: dt.id,
    sn: i + 1,
    reg: dt.student ? char.toUpperCase(dt.student.registration_number) : "N/A",
    name: dt.student ? char.toUpperCase(dt.student.name) : "N/A",
    batch_no: dt.allocation ? dt.allocation.allocation.batch_no : "N/A",
    amount: dt.equivalent_amount
      ? number.fNumberDoublePoint(dt.equivalent_amount)
      : number.fNumberDoublePoint(0.0),
  }));

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="full">
        <Modal.Header>
          <Modal.Title>Allocated Students </Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          <div className="container-fluid">
            <TableShared
              setOpen={setOpen}
              loading={loading}
              data={dataItem}
              setData={setData}
              page={page}
              setPage={setPage}
              count={count}
              postsPerPage={postPerPage}
              setePostPerPage={setPostPerPage}
              setNextPage={setNextPage}
              value={search}
              setValue={setSearch}
              defaultColumns={defaultColumns}
              button={null}
              link={"#"}
              showButton={true}
              setLoading={setLoading}
            />
          </div>
        </Modal.Body>
        <Divider style={{ marginTop: -10 }} />
        <Modal.Footer>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-11 col-sm-12 d-flex justify-content-start"></div>
              <div className="col-md-1 col-sm-12 d-flex justify-content-end">
                <button
                  onClick={() => setOpen(false)}
                  type="button"
                  className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                >
                  <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
