import { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";

import { selector } from "../../../../api";
import { Button, DatePicker, Divider, Select, notification } from "antd";
import { char } from "../../../../utils";
import { configurationCRUD } from "../../../../actions/configuration";
import { accountingCRUD } from "../../../../actions/accounting";
import { invoiceCRUD } from "../../../../actions/invoice";
import moment from "moment";
import { PlusCircleOutlined } from "@ant-design/icons";
import { gatewayCRUD } from "../../../../actions/gateway";
import { Message, Modal, useToaster } from "rsuite";

const initialValues = {
  programme: "",
  items: [
    {
      cost_type: "",
      cost_status: "",
      collection: "",
      amount_usd: "",
      amount_tzs: "",
    },
  ],
  is_active: true,
};

export const CenterFeeAdd = (props) => {
  const { open, setOpen, centerId, schoolId, index, setIndex } = props;
  const user = selector.User();
  const [programme, setProgramme] = useState([]);
  const [programmeS, setProgrammeS] = useState("");
  const [payment, setPayment] = useState([]);
  const [paymentSearch, setPaymentSearch] = useState("");
  const [rate, setRate] = useState({});
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();

  useEffect(() => {
    setTimeout(() => {
      configurationCRUD
        .loadSchoolProgramme(100, 0, schoolId, programmeS, "code")
        .then((resp) => {
          setProgramme(resp.data.results);
        })
        .catch((error) => {});

      accountingCRUD
        .loadCollectionCenterItem(100, 0, centerId, paymentSearch, "name")
        .then((resp) => {
          setPayment(resp.data.results);
        })
        .catch((error) => {});
      configurationCRUD
        .loadCurrencyExchangeRate(1)
        .then((resp) => {
          setRate(resp.data);
        })
        .catch(() => {});
    }, 500);
  }, [programmeS, paymentSearch]);

  const handleSubmit = (values, resetForm, setSubmitting) => {
    setLoading(true);
    const { programme, is_active, items } = values;

    const dataItem = [];

    for (let i = 0; i < items.length; i++) {
      const dataSaave = {
        cost_type: items[i].cost_type,
        cost_status: items[i].cost_status,
        programme,
        collection: items[i].collection,
        center: centerId,
        amount_usd: parseFloat(items[i].amount_usd),
        amount_tzs: parseFloat(items[i].amount_tzs),
        is_active,
        created_by: user.id,
      };

      const existingObject = dataItem.find(
        (obj) =>
          obj.programme === dataSaave.programme &&
          obj.collection === dataSaave.collection
      );

      if (!existingObject) {
        dataItem.push(dataSaave);
      }
    }

    accountingCRUD
      .addCenterFee(dataItem)
      .then(() => {
        setIndex(index + 1);
        toaster.push(
          <Message type="success" showIcon header={"Success"} closable>
            <p className="h6">
              <p className="fw-bold fs-14">Thank you...!</p>
              <p className="text-dark fs-12">
                Center Fee structure has been added successfully.
              </p>
            </p>
          </Message>,
          { placement: "topEnd", duration: 7000 }
        );
        resetForm();
        setSubmitting(false);
        setLoading(false);
        setOpen(false);
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">{error.message}</p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
        } else if (error.response.data.code) {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">{error.response.data.code[0]}</p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
        } else {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">{error.message}</p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
        }
        setSubmitting(false);
        setLoading(false);
      });
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const feeSchema = Yup.object().shape({
    programme: Yup.string().required("This field is reuired"),
    items: Yup.array()
      .of(
        Yup.object().shape({
          cost_type: Yup.string().required("This field is required"),
          cost_status: Yup.string().required("This field is required"),
          collection: Yup.string().required("This field is required"),
          amount_tzs: Yup.string()
            .matches(/^[0-9]+$/, "Amount must be a digits")
            .required("This field is required"),
          amount_usd: Yup.string()
            .matches(/^[0-9]+$/, "Amount must be a digits")
            .required("This field is required")
            .test(
              "amount-comparison",
              "Amount in USD must be greate or equal to amount in TZS * active rate",
              async function (value) {
                const amountTzs = parseFloat(this.parent.amount_tzs) || 0;
                return amountTzs <= parseFloat(value * rate.rate);
              }
            ),
        })
      )
      .test("unique", "Collection Item must be unique", function (value) {
        const names = value.map((item) => item.collection);
        return new Set(names).size === names.length;
      }),
  });

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="lg">
        <Modal.Header>
          <Modal.Title>New Fee Structure</Modal.Title>
        </Modal.Header>
        <Divider />
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm, setSubmitting }) => {
            handleSubmit(values, resetForm, setSubmitting);
          }}
          validationSchema={feeSchema}
        >
          {({
            setFieldValue,
            values,
            isSubmitting,
            isValid,
            resetForm,
            errors,
          }) => (
            <Form>
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label>
                          Programme <span className="text-danger">*</span>
                        </label>
                        <Select
                          size="large"
                          style={{ width: "100%" }}
                          showSearch
                          placeholder="Select programme"
                          optionFilterProp="children"
                          onChange={(e) => {
                            setFieldValue("programme", e);
                          }}
                          onSearch={(e) => setProgrammeS(e)}
                          filterOption={filterOption}
                          options={programme?.map((dt) => ({
                            value: dt.id,
                            label:
                              char.toUpperCase(dt.code) +
                              " -- " +
                              char.toTitleCase(dt.name),
                          }))}
                        />
                        <div className="fw-light mt-1 fs-15">
                          <span role="alert" className="text-danger">
                            <ErrorMessage name={"programme"} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider />
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <FieldArray name="items">
                        {({ remove, push }) => (
                          <div>
                            <div className="row mt-2">
                              <div className="col-12 mb-2">
                                <div style={{ marginTop: -20 }}>
                                  <span className="mt-4">
                                    <p className="fw-bold">Fee Items</p>
                                  </span>
                                  <span
                                    className="d-flex justify-content-end"
                                    style={{ marginTop: -20 }}
                                  >
                                    <Button
                                      type="primary"
                                      icon={<PlusCircleOutlined />}
                                      onClick={() =>
                                        push({
                                          cost_type: "",
                                          cost_status: "",
                                          collection: "",
                                          amount_usd: "",
                                          amount_tzs: "",
                                        })
                                      }
                                    >
                                      <span>add row</span>
                                    </Button>
                                  </span>
                                </div>
                              </div>
                              <hr />
                            </div>
                            {values.items.length > 0 &&
                              values.items.map((_, i) => (
                                <>
                                  <div key={i} className="mb-3">
                                    <div className="row">
                                      <div className="col-md-3 col-sm-12">
                                        <label className="text-body mb-2">
                                          Collection Item
                                          <span className="text-danger">
                                            &nbsp;*
                                          </span>
                                        </label>
                                        <Select
                                          size="large"
                                          style={{ width: "100%" }}
                                          showSearch
                                          placeholder="Select a collection item"
                                          optionFilterProp="children"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `items.${i}.collection`,
                                              e
                                            )
                                          }
                                          onSearch={(e) => setPaymentSearch(e)}
                                          filterOption={filterOption}
                                          options={payment?.map((dt) => ({
                                            value: dt.id,
                                            label:
                                              char.toUpperCase(dt.code) +
                                              " --- " +
                                              char.toUpperCase(dt.name),
                                          }))}
                                        />
                                        <div
                                          className="fw-light mt-1"
                                          style={{ fontSize: "12px" }}
                                        >
                                          <span
                                            role="alert"
                                            className="text-danger"
                                          >
                                            <ErrorMessage
                                              name={`items.${i}.collection`}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          values.items.length > 1
                                            ? "col-md-2 col-sm-12"
                                            : "col-md-3 col-sm-12"
                                        }
                                      >
                                        <label className="text-body mb-2">
                                          Fee Type
                                          <span className="text-danger">
                                            &nbsp;*
                                          </span>
                                        </label>
                                        <Select
                                          size="large"
                                          style={{ width: "100%" }}
                                          showSearch
                                          placeholder="Select fee type"
                                          optionFilterProp="children"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `items.${i}.cost_type`,
                                              e
                                            )
                                          }
                                          filterOption={filterOption}
                                          options={[
                                            {
                                              value: "tuition fee",
                                              label: "Tuition Fee",
                                            },
                                            {
                                              value: "direct cost",
                                              label: "Direct Cost",
                                            },
                                          ]}
                                        />
                                        <div
                                          className="fw-light mt-1"
                                          style={{ fontSize: "12px" }}
                                        >
                                          <span
                                            role="alert"
                                            className="text-danger"
                                          >
                                            <ErrorMessage
                                              name={`items.${i}.cost_type`}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col-md-2 col-sm-12">
                                        <label className="text-body mb-2">
                                          Payment Option
                                          <span className="text-danger">
                                            &nbsp;*
                                          </span>
                                        </label>
                                        <Select
                                          size="large"
                                          style={{ width: "100%" }}
                                          showSearch
                                          placeholder="Select payment status"
                                          optionFilterProp="children"
                                          onChange={(e) =>
                                            setFieldValue(
                                              `items.${i}.cost_status`,
                                              e
                                            )
                                          }
                                          filterOption={filterOption}
                                          options={[
                                            {
                                              value: "annualy",
                                              label: "Annualy",
                                            },
                                            {
                                              value: "once",
                                              label: "Only Once",
                                            },
                                          ]}
                                        />
                                        <div
                                          className="fw-light mt-1"
                                          style={{ fontSize: "12px" }}
                                        >
                                          <span
                                            role="alert"
                                            className="text-danger"
                                          >
                                            <ErrorMessage
                                              name={`items.${i}.cost_status`}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          values.items.length > 1
                                            ? "col-md-2 col-sm-12"
                                            : "col-md-2 col-sm-12"
                                        }
                                      >
                                        <label className="text-body mb-2">
                                          Amount TZS
                                          <span className="text-danger">
                                            &nbsp;*
                                          </span>
                                        </label>
                                        <Field
                                          type={"text"}
                                          className="form-control"
                                          name={`items.${i}.amount_tzs`}
                                          placeholder={"Fee amount in TZS"}
                                        />
                                        <div
                                          className="fw-light mt-1"
                                          style={{ fontSize: "12px" }}
                                        >
                                          <span
                                            role="alert"
                                            className="text-danger"
                                          >
                                            <ErrorMessage
                                              name={`items.${i}.amount_tzs`}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          values.items.length > 1
                                            ? "col-md-2 col-sm-12"
                                            : "col-md-2 col-sm-12"
                                        }
                                      >
                                        <label className="text-body mb-2">
                                          Amount USD
                                          <span className="text-danger">
                                            &nbsp;*
                                          </span>
                                        </label>
                                        <Field
                                          type={"text"}
                                          className="form-control"
                                          name={`items.${i}.amount_usd`}
                                          placeholder={"Fee amount in USD"}
                                        />
                                        <div
                                          className="fw-light mt-1"
                                          style={{ fontSize: "12px" }}
                                        >
                                          <span
                                            role="alert"
                                            className="text-danger"
                                          >
                                            <ErrorMessage
                                              name={`items.${i}.amount_usd`}
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      {values.items.length > 1 && (
                                        <div
                                          className="col-md-1 text-right"
                                          style={{ marginTop: 28 }}
                                        >
                                          <>
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => remove(i)}
                                            >
                                              <i className="bi bi-patch-minus la-lg"></i>
                                            </button>
                                          </>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ))}

                            {typeof errors.items === "string" && (
                              <div className="mb-4 text-center text-danger fs-14 h6">
                                {errors.items}
                              </div>
                            )}
                          </div>
                        )}
                      </FieldArray>
                    </div>
                  </div>
                </div>
              </>

              <Divider style={{ marginTop: -10 }} />
              <Modal.Footer>
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                      <span className="fs-18 text-danger"> * &nbsp;</span>{" "}
                      Required field
                    </div>
                    <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                      <button
                        onClick={() => setOpen(false)}
                        type="button"
                        className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                      >
                        <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                        disabled={isSubmitting}
                      >
                        {!loading && (
                          <span className="indicator-label">
                            <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                            Save
                          </span>
                        )}
                        {loading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
