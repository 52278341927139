import { useLocation } from "react-router-dom";
import {
  AddmissionAccount,
  CustomerAccount,
  LoanBoardAccount,
  StudentAccount,
} from "../../../components/accounting/receivable";

export default function ReceivableAccount() {
  let location = useLocation();

  return (
    <>
      {location.state && location.state.account === "student" ? (
        <StudentAccount />
      ) : location.state.account === "addmission" ? (
        <AddmissionAccount />
      ) : location.state.account === "loan" ? (
        <LoanBoardAccount />
      ) : location.state.account === "customer" ? (
        <CustomerAccount />
      ) : (
        <StudentAccount />
      )}
    </>
  );
}
