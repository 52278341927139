import moment from "moment";
import { Message } from "rsuite";
import { adjustmentCRUD } from "../actions/adjustment";
import { accountingCRUD } from "../actions/accounting";
import { char } from "../utils";

export function empty(data, toaster, setLoading) {
  for (let i = 1; i < data.length; i++) {
    if (data[i][0] === undefined) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Loan Board is empty</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (data[i][1] === undefined) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Date is empty</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (data[i][2] === undefined) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Batch No. is empty</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (data[i][3] === undefined) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Student is empty</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (data[i][4] === undefined) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Description is empty</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (data[i][5] === undefined) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Amount is empty</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (data[i][6] === undefined) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Collection item is empty</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (i === data.length - 1) {
      return "valid";
    }
  }
}

export function date(data, toaster, setLoading) {
  const regex = /\d{4}.\d{2}.\d{2}/;
  for (let i = 1; i < data.length; i++) {
    const string = data[i][1];
    const match = string.toString().match(regex);
    const date = moment(string).format("D MMM YYYY");
    if (!match) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Provided date format is wrong.</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (date === "Invalid date") {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Provided date format is wrong.</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (i === data.length - 1) {
      return "valid";
    }
  }
}

export function amount(data, toaster, setLoading) {
  for (let i = 1; i < data.length; i++) {
    const number = data[i][5];
    if (isNaN(+number)) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">
              Error in row {i}. <b>Provided amount is invalid.</b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (i === data.length - 1) {
      return "valid";
    }
  }
}

export async function batch(
  data,
  toaster,
  setLoading,
  academic,
  financial,
  loan
) {
  for (let i = 1; i < data.length; i++) {
    const batch = "00" + data[1][2];
    const response = await adjustmentCRUD
      .loadPostedAllocation(batch, academic, financial, loan)
      .then((resp) => {
        console.log(resp);
        return resp;
      });

    if (response === true) {
      toaster.push(
        <Message showIcon type={"error"} header={"Error"} closable>
          <p className="h6">
            <p className="fw-bold fs-14">Oooh Sorry...!</p>
            <p className="text-dark fs-12">Error in row {i}.</p>
            <p>
              <b>
                Provided batch is available for active academic & financial year
              </b>
            </p>
          </p>
        </Message>,
        { placement: "topEnd", duration: 7000 }
      );
      setLoading(false);
      break;
    } else if (response === false) {
      setLoading(false);
      return "valid";
    }
  }
}

export function board(data, code, toaster, setBoardValid, setLoading) {
  setLoading(true);
  accountingCRUD.loadCheckLoanBoard(code, true).then((resp) => {
    const respData = resp.data.map((dt) => {
      return char.toUpperCase(dt.acronym);
    });
    for (let i = 1; i < data.length; i++) {
      if (!respData.includes(char.toUpperCase(data[i][0]))) {
        toaster.push(
          <Message showIcon type={"error"} header={"Error"} closable>
            <p className="h6">
              <p className="fw-bold fs-14">Oooh Sorry...!</p>
              <p className="text-dark fs-12">
                Error in row {i}. <b>Provided Loan`s board is unknown.</b>
              </p>
            </p>
          </Message>,
          { placement: "topEnd", duration: 7000 }
        );
        setLoading(false);
        break;
      } else if (i === data.length - 1) {
        setBoardValid(true);
        setLoading(false);
        break;
      }
    }
  });
}

export function paymentItem(
  data,
  code,
  toaster,
  setPaymentItemValid,
  setLoading
) {
  setLoading(true);
  accountingCRUD.loadCheckPaymentItem(code).then((resp) => {
    const respData = resp.data.map((dt) => {
      return dt.code;
    });
    for (let i = 1; i < data.length; i++) {
      if (!respData.includes(data[i][6])) {
        toaster.push(
          <Message showIcon type={"error"} header={"Error"} closable>
            <p className="h6">
              <p className="fw-bold fs-14">Oooh Sorry...!</p>
              <p className="text-dark fs-12">
                Error in row {i}. <b>Provided collection item is unknown.</b>
              </p>
            </p>
          </Message>,
          { placement: "topEnd", duration: 7000 }
        );
        setLoading(false);
        break;
      } else if (i === data.length - 1) {
        setPaymentItemValid(true);
        setLoading(false);
        break;
      }
    }
  });
}

export function student(
  data,
  reg,
  studentReg,
  toaster,
  setLoading,
  setStudentValid
) {
  accountingCRUD.loadCheckStudent(reg).then((resp) => {
    const respData = resp.data.map((dt) => {
      return dt.registration_number;
    });

    for (let i = 1; i < data.length; i++) {
      const count = studentReg.reduce(
        (acc, curr) => (curr === data[i][3] ? acc + 1 : acc),
        0
      );
      if (count > 1) {
        toaster.push(
          <Message showIcon type={"error"} header={"Error"} closable>
            <p className="h6">
              <p className="fw-bold fs-14">Oooh Sorry...!</p>
              <p className="text-dark fs-12">Error in row {i}.</p>
              <p>
                <b>
                  Student with reg{" "}
                  <span className="text-danger">
                    {char.toUpperCase(data[i][3])}
                  </span>{" "}
                  appear multiple.
                </b>
              </p>
            </p>
          </Message>,
          { placement: "topEnd", duration: 7000 }
        );
        setLoading(false);
        break;
      } else if (!respData.includes(data[i][3])) {
        toaster.push(
          <Message showIcon type={"error"} header={"Error"} closable>
            <p className="h6">
              <p className="fw-bold fs-14">Oooh Sorry...!</p>
              <p className="text-dark fs-12">Error in row {i}.</p>
              <p>
                <b>
                  Student with reg{" "}
                  <span className="text-danger">
                    {char.toUpperCase(data[i][3])}
                  </span>{" "}
                  is unknown.
                </b>
              </p>
            </p>
          </Message>,
          { placement: "topEnd", duration: 7000 }
        );
        setLoading(false);
        break;
      } else if (i === data.length - 1) {
        setStudentValid(true);
        setLoading(false);
        break;
      }
    }
  });
}
