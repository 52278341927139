import { useEffect, useRef, useState } from "react";
import { Divider, Message, Modal, useToaster } from "rsuite";
import { gatewayCRUD } from "../../../actions/gateway";
import moment from "moment";
import { Loading, char, number } from "../../../utils";
import { useReactToPrint } from "react-to-print";
import { MoreOutlined } from "@ant-design/icons";
import { PaymentPrint } from "./print/payment";
import { Button, Dropdown, Space } from "antd";

export const InvoicePayment = (props) => {
  const { open, setOpen, dataId } = props;
  const toaster = useToaster();
  const dataRef = useRef(null);
  const [data, setData] = useState([]);
  const [printOpen, setPrintOpen] = useState(false);
  const [printId, setPrintId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (dataId) {
      setLoading(true);
      setTimeout(() => {
        gatewayCRUD
          .loadInvoicePayment(dataId)
          .then((resp) => {
            setData(resp.data);
          })
          .catch((error) => {
            toaster.push(
              <Message showIcon type={"error"} header={"Error"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Oooh Sorry...!</p>
                  <p className="text-dark fs-12">
                    Something went wrong, Check your network connection.
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }, 500);
    }
  }, [dataId]);

  const handleGeneratePdf = useReactToPrint({
    content: () => dataRef.current,
  });

  return (
    <>
      <PaymentPrint open={printOpen} setOpen={setPrintOpen} dataId={printId} />
      <Modal open={open} onClose={() => setOpen(false)} size="full">
        <Modal.Header>
          <Modal.Title>
            <div className="row">
              <div className="col-md-6 col-sm-12">Invoice Payment</div>
              {/* <div className="col-md-6 col-sm-12 d-flex flex-row-reverse">
                <button
                  className="btn btn-soft-success btn-sm waves-effect waves-light"
                  onClick={handleGeneratePdf}
                >
                  <i className="bi bi-printer-fill m-r-5" /> Print
                </button>
              </div> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive" ref={dataRef}>
                  <table className="table table-bordered table-sm">
                    <thead className="table-head">
                      <tr>
                        <th style={{ width: 100 }}>#</th>
                        <th>Date</th>
                        <th>Payer Name</th>
                        <th>Control Number</th>
                        <th>Receipt Number</th>
                        <th>Currency</th>
                        <th>Bank Name</th>
                        <th>Bank Account</th>
                        <th className="text-end">Amount Due</th>
                        <th className="text-end">Amount Paid</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((dt, i) => (
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            {dt.trxdttm
                              ? moment(dt.trxdttm).format("D MMM YYYY")
                              : "N/A"}
                          </td>
                          <td>
                            {dt.pyrname ? char.toUpperCase(dt.pyrname) : "N/A"}
                          </td>
                          <td>{dt.logs ? dt.logs.control_number : "N/A"}</td>
                          <td>
                            {dt.pspreceiptnumber ? dt.pspreceiptnumber : "N/A"}
                          </td>
                          <td>
                            {dt.logs
                              ? char.toUpperCase(dt.logs.bill_currency)
                              : "N/A"}
                          </td>
                          <td>{dt.pspname ? dt.pspname : "N/A"}</td>
                          <td>{dt.ctraccnum ? dt.ctraccnum : "N/A"}</td>
                          <td className="text-end">
                            {dt.billamount
                              ? number.fNumberDoublePoint(dt.billamount)
                              : number.fNumberDoublePoint(0)}
                          </td>
                          <td className="text-end">
                            {dt.paidamount
                              ? number.fNumberDoublePoint(dt.paidamount)
                              : number.fNumberDoublePoint(0)}
                          </td>
                          <td className="text-center">
                            <Dropdown
                              menu={{
                                items: [
                                  {
                                    label: "Print",
                                    key: "print",
                                    icon: (
                                      <i className="ri-printer-line fs-6" />
                                    ),
                                  },
                                ],
                                onClick: (event) => {
                                  switch (event.key) {
                                    case "print":
                                      setPrintOpen(true);
                                      setPrintId(dt.id);
                                      break;
                                    default:
                                  }
                                },
                              }}
                              trigger={["click"]}
                              placement="bottomRight"
                              arrow={{ pointAtCenter: true }}
                            >
                              <Space>
                                <Button
                                  type="dashed"
                                  icon={<MoreOutlined />}
                                  size={"middle"}
                                />
                              </Space>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">{loading && <Loading />}</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
