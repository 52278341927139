import { useLocation } from "react-router-dom";
import { GeneralCollection } from "./data";

export const CollectionReportContent = (props) => {
  let location = useLocation();

  return (
    <>
      <div className="container-fluid">
        <div className="row mb-2" style={{ marginTop: -30 }}>
          <div className="col-12">
            <p className="h6 fs-18 fw-bold text-dark">Collection Report</p>
          </div>
          <hr className="mt-3" />
        </div>
        {location?.state?.page === "general" ? (
          <GeneralCollection />
        )  : null}
      </div>
    </>
  );
};
