import { useEffect, useState } from "react";
import { selector } from "../../../api";
import { gatewayCRUD } from "../../../actions/gateway";
import { TableShared } from "../../../shared";
import { char, number } from "../../../utils";
import { Button, Dropdown, Space, Tooltip } from "antd";
import {
  DollarOutlined,
  MoreOutlined,
  PrinterOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Message, useToaster } from "rsuite";
import { RequestReco } from "./add";

export const ReconciliationContent = () => {
  const [open, setOpen] = useState(false);
  const [customerOpen, setCustomerOpen] = useState(false);
  const [printOpen, setPrintOpen] = useState(false);
  const [dataId, setDataId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [postsPerPage, setePostPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(0);
  const [value, setValue] = useState("");
  const [index, setIndex] = useState(0);
  const [sortColumn, setSortColumn] = useState("");
  const [sortType, setSortType] = useState("");

  const toaster = useToaster();

  const user = selector.User();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      gatewayCRUD
        .loadInvoiceReconciliation(postsPerPage, nextPage, value, "-id")
        .then((resp) => {
          setData(resp.data.results);
          setCount(resp.data.count);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">
                  Something went wrong, Check your network connection.
                </p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  }, [page, postsPerPage, value, index, nextPage]);


  const defaultColumns = [
    {
      key: "sn",
      title: "#",
      dataIndex: "sn",
      fixed: true,
      width: 70,
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "payer",
      title: "Payer Name",
      dataIndex: "payer",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "control_number",
      title: "Control No",
      dataIndex: "control_number",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "pay_ref",
      title: "Pay Ref ID",
      dataIndex: "pay_ref",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "pay_channel",
      title: "Pay Channel",
      dataIndex: "pay_channel",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "psp",
      title: "PsP Name",
      dataIndex: "psp",
      sortable: true,
      width: 150,
    },
    {
      key: "account",
      title: "Account No.",
      dataIndex: "account",
      sortable: true,
      width: 100,
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      sortable: true,
      width: 100,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sortable: true,
      width: 100,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => (
        <Dropdown
          menu={{
            items: [
              {
                label: "Edit",
                key: "edit",
                icon: <i />,
              },
              {
                label: "View",
                key: "view",
                icon: <i />,
              },
              {
                label: "Delete",
                key: "delete",
                icon: <i />,
              },
            ],
            onClick: (event) => {
              switch (event.key) {
                case "1":
                  setOpen(true);
                  break;
                default:
              }
            },
          }}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <Space>
            <Button type="dashed" icon={<MoreOutlined />} size={"middle"} />
          </Space>
        </Dropdown>
      ),
    },
  ];

  const dataItem = data?.map((dt, i) => ({
    id: dt.id,
    sn: i + 1,
    date: dt.TrxDtTm ? moment(dt.TrxDtTm).format("D MMM YYYY") : "N/A",
    payer: dt.DptName ? char.toUpperCase(dt.DptName) : "N/A",
    control_number: dt.BillCtrNum ? dt.BillCtrNum : "N/A",
    pay_ref: dt.PayRefId ? dt.PayRefId : "N/A",
    pay_channel: dt.UsdPayChnl ? dt.UsdPayChnl : "N/A",
    psp: dt.PspName ? dt.PspName : "N/A",
    account: dt.CtrAccNum ? dt.CtrAccNum : "N/A",
    amount: dt.PaidAmt
      ? number.fNumberDoublePoint(dt.PaidAmt)
      : number.fNumberDoublePoint(0),
    status: (
      <span
        className="badge badge-soft-info"
        style={{ cursor: "pointer" }}
        onClick={() =>
          toaster.push(
            <Message showIcon type="info" closable>
              {dt.Remarks}
            </Message>,
            { placement: "topCenter", duration: 9000 }
          )
        }
      >
        status
      </span>
    ),
  }));

  const button = (
    <button
      className="btn btn-primary bg-gradient btn-border"
      onClick={() => setOpen(true)}
    >
      <span className="bi bi-plus-circle la-lg"></span> &nbsp; New Request
    </button>
  );

  return (
    <>
      <RequestReco open={open} setOpen={setOpen} />
      <div className="container-fluid">
        <div className="row mb-2" style={{ marginTop: -30 }}>
          <div className="col-12">
            <p className="h6 fs-18 fw-bold text-dark">Reconciliation</p>
          </div>
          <hr />
        </div>
        <TableShared
          setOpen={setOpen}
          loading={loading}
          data={dataItem}
          setData={setData}
          page={page}
          setPage={setPage}
          count={count}
          postsPerPage={postsPerPage}
          setePostPerPage={setePostPerPage}
          setNextPage={setNextPage}
          value={value}
          setValue={setValue}
          defaultColumns={defaultColumns}
          button={button}
          link={"#"}
          showButton={true}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortType={sortType}
          setSortType={setSortType}
          setLoading={setLoading}
        />
      </div>
    </>
  );
};
