import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToaster, Message, Modal } from "rsuite";

import { dictionaryCRUD } from "../../../actions/dictionary";
import { selector } from "../../../api";
import { Divider, Input, Select, notification } from "antd";
import { char, formComponent } from "../../../utils";

const dictionarySchema = Yup.object().shape({
  dictionary: Yup.string().required("This field is required"),
  dictionary_item_name: Yup.string().required("This field is required"),
});

const initialValues = {
  dictionary_item_code: "",
  dictionary: "",
  dictionary_item_name: "",
};

export const DictionaryItemAdd = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [dictionary, setDictionary] = useState([]);
  const [value, setValue] = useState("");

  useEffect(() => {
    setTimeout(() => {
      dictionaryCRUD
        .loadDictionary(20, 0, value, "dictionary_name")
        .then((resp) => {
          setDictionary(resp.data.results);
        })
        .catch(() => {});
    });
  }, [value]);

  const formik = useFormik({
    initialValues,
    validationSchema: dictionarySchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { dictionary_item_code, dictionary_item_name, dictionary } = values;
      const recorded_by = user.id;
      const data = {
        dictionary_item_code,
        dictionary_item_name,
        dictionary,
        recorded_by,
      };
      setLoading(true);
      setTimeout(() => {
        dictionaryCRUD
          .addDictionaryItem([data])
          .then(() => {
            api.success({
              message: "Thank you",
              description:
                "🎉 Congratulations! Your data has been successfully saved!",
              placement: "topRight",
            });
            setIndex(index + 1);
            resetForm();
            setLoading(false);
            setSubmitting(false);
            setOpen(false);
          })
          .catch((error) => {
            setLoading(false);
            setSubmitting(false);
            api.error({
              message: error.message,
              description:
                "Oops! It looks like there is some errors. Please correct your error and try again",
              placement: "topRight",
            });
          });
      }, 2000);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      {contextHolder}
      <Modal open={open} onClose={handleClose} size="md">
        <Modal.Header>
          <Modal.Title>New Dictionary Item</Modal.Title>
        </Modal.Header>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">Dictionary Item Code</label>
                    <Input
                      {...formik.getFieldProps("dictionary_item_code")}
                      placeholder="Dictionary Item Code"
                      size="large"
                    />
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <label className="form-label">
                    Dictionary <span className="text-danger">*</span>
                  </label>
                  <br />
                  <Select
                    size="large"
                    style={{ width: 230 }}
                    showSearch
                    placeholder="Select dictionary"
                    optionFilterProp="children"
                    onChange={(e) => formik.setFieldValue("dictionary", e)}
                    onSearch={(e) => setValue(e)}
                    filterOption={filterOption}
                    options={dictionary?.map((dt) => ({
                      value: dt.id,
                      label: char.toUpperCaseFirst(dt.dictionary_name),
                    }))}
                    status={
                      formik.touched.dictionary &&
                      formik.errors.dictionary &&
                      "error"
                    }
                  />
                  {formik.touched.dictionary && formik.errors.dictionary && (
                    <div className="fw-light mt-1" style={{ fontSize: "14px" }}>
                      <span role="alert" className="text-danger">
                        {formik.errors.dictionary}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-md-4 col-sm-12">
                  <label className="form-label">
                    Dictionary Item Name <span className="text-danger">*</span>
                  </label>
                  <Input
                    {...formik.getFieldProps("dictionary_item_name")}
                    placeholder="Dictionary Item Name"
                    size="large"
                    status={
                      formik.touched.dictionary_item_name &&
                      formik.errors.dictionary_item_name &&
                      "error"
                    }
                  />
                  {formik.touched.dictionary_item_name &&
                    formik.errors.dictionary_item_name && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "14px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.dictionary_item_name}
                        </span>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Divider style={{ marginTop: -10 }} />
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                  <span className="fs-18 text-danger"> * &nbsp;</span> Required
                  field
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                  <button
                    onClick={handleClose}
                    type="button"
                    className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                  >
                    <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Save
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
