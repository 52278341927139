import { useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useToaster, Message, Modal, Divider } from "rsuite";

import { selector } from "../../../api";
import { configurationCRUD } from "../../../actions/configuration";

const financialSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
});

const initialValues = {
  name: "",
};

export const FinancialAdd = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();

  const formik = useFormik({
    initialValues,
    validationSchema: financialSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { name } = values;
      const created_by = user.id;
      const data = {
        name,
        created_by,
      };
      setLoading(true);
      setTimeout(() => {
        configurationCRUD
          .addFinancialYear(data)
          .then(() => {
            toaster.push(
              <Message type="success" showIcon header={"Success"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Thank you...!</p>
                  <p className="text-dark fs-12">
                    Financial year has been added successfully.
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
            setIndex(index + 1);
            resetForm();
            setLoading(false);
            setSubmitting(false);
            setOpen(false);
          })
          .catch((error) => {
            setLoading(false);
            setSubmitting(false);
            if (error.message === "Network Error") {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">{error.message}</p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            } else if (error.response.data.code) {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">
                      {error.response.data.code[0]}
                    </p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            } else {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">{error.message}</p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            }
          });
      }, 2000);
    },
  });

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="sm">
        <Modal.Header>
          <Modal.Title>New Financila Year</Modal.Title>
        </Modal.Header>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Financial year name"
                      {...formik.getFieldProps("name")}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="fw-light mt-1 fs-15">
                        <span role="alert" className="text-danger">
                          {formik.errors.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-3 col-sm-12 d-flex justify-content-start">
                  <span className="fs-18 text-danger"> * &nbsp;</span> Required
                  field
                </div>
                <div className="col-md-9 col-sm-12 d-flex justify-content-end">
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                  >
                    <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                    disabled={formik.isSubmitting}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Save
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
