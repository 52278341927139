import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { selector } from "../../../../api";
import { TableShared } from "../../../../shared";
import { char } from "../../../../utils";
import {
  Breadcrumb,
  Button,
  ConfigProvider,
  Dropdown,
  Space,
  message,
  notification,
} from "antd";
import {
  PlusCircleOutlined,
  MoreOutlined,
  DeleteOutlined,
  EyeOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { invoiceCRUD } from "../../../../actions/invoice";
import { CollectionItemAdd } from "./add";
import { Message, useToaster } from "rsuite";

const items = [
  {
    label: "Edit",
    key: "edit",
    icon: <EditOutlined />,
  },
  {
    label: "View",
    key: "view",
    icon: <EyeOutlined />,
  },
  {
    label: "Delete",
    key: "delete",
    icon: <DeleteOutlined />,
  },
];

export const CollectionItem = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [postsPerPage, setePostPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(0);
  const [value, setValue] = useState("");
  const [index, setIndex] = useState(0);

  const toaster = useToaster();

  let location = useLocation();

  const centerId = location.state && location.state.center;

  const user = selector.User();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      invoiceCRUD
        .loadCollectionItem(postsPerPage, nextPage, centerId, value, "-id")
        .then((resp) => {
          setData(resp.data.results);
          setCount(resp.data.count);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">
                  Something went wrong, Check your network connection.
                </p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  }, [page, postsPerPage, value, index, nextPage]);

  const defaultColumns = [
    {
      key: "sn",
      title: "#",
      dataIndex: "sn",
      fixed: true,
      width: 150,
    },
    {
      key: "code",
      title: "Code",
      dataIndex: "code",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "gfs_code",
      title: "GFS Code",
      dataIndex: "gfs_code",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      sortable: true,
      flexGrow: 1,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => (
        <Dropdown
          menu={{
            items,
            onClick: (event) => {
              switch (event.key) {
                case "1":
                  setOpen(true);
                  break;
                default:
              }
            },
          }}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
          trigger={["click"]}
        >
          <Space>
            <Button type="dashed" icon={<MoreOutlined />} size={"middle"} />
          </Space>
        </Dropdown>
      ),
    },
  ];

  const dataItem = data?.map((dt, i) => ({
    id: dt.id,
    sn: i + 1,
    code: dt.code ? char.toUpperCase(dt.code) : "N/A",
    name: dt.name ? char.toTitleCase(dt.name) : "N/A",
    gfs_code: dt.account ? dt.account.gfs_code : "N/A",
    description: dt.description ? char.toUpperCaseFirst(dt.description) : "N/A",
  }));

  const button = (
    <button
      className="btn btn-primary bg-gradient btn-border"
      onClick={() => setOpen(true)}
    >
      <span className="bi bi-plus-circle la-lg"></span> &nbsp; New Collection
      Items
    </button>
  );

  return (
    <>
      <CollectionItemAdd
        setOpen={setOpen}
        open={open}
        index={index}
        setIndex={setIndex}
        centerId={centerId}
      />
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-12">
            <p className="h6 fs-18 fw-bold text-dark">Collection Item</p>
          </div>
          <hr className="mt-3" />
        </div>
        <TableShared
          loading={loading}
          data={dataItem}
          setData={setData}
          page={page}
          setPage={setPage}
          count={count}
          postsPerPage={postsPerPage}
          setePostPerPage={setePostPerPage}
          setNextPage={setNextPage}
          setValue={setValue}
          defaultColumns={defaultColumns}
          button={button}
        />
      </div>
    </>
  );
};
