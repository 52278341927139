import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  Divider,
  Modal,
  useToaster,
  Message,
  DatePicker,
  SelectPicker,
  TagPicker,
} from "rsuite";
import * as Yup from "yup";
import { selector } from "../../../api";
import { adjustmentCRUD } from "../../../actions/adjustment";
import moment from "moment";
import { configurationCRUD } from "../../../actions/configuration";
import { accountingCRUD } from "../../../actions/accounting";
import { char } from "../../../utils";

const journalVoucherSchema = Yup.object().shape({
  description: Yup.string().required("This field is required"),
  amount: Yup.number().required("This field is required"),
  currency: Yup.string().required("This field is required"),
  date: Yup.date()
    .max(new Date(), "Date can't be more than today")
    .required("This field is required"),
  account_debit: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .test(
      "has-multiple-values",
      "Only one field between debit and credi can have multiple values",
      function (value) {
        const array2Length = this.parent.account_credt
          ? this.parent.account_credt.length
          : 0;
        return value.length > 1 ? array2Length === 1 : true;
      }
    )
    .test(
      "unique-items",
      "Accounts must be unique between debit and credit",
      function (value) {
        const account_credt = this.parent.account_credt || [];
        const isUnique = value.every((item) => !account_credt.includes(item));
        return isUnique;
      }
    ),

  account_credt: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .test(
      "has-multiple-values",
      "Only one field between debit and credi can have multiple values",
      function (value) {
        const array1Length = this.parent.account_debit
          ? this.parent.account_debit.length
          : 0;
        return value.length > 1 ? array1Length === 1 : true;
      }
    )
    .test(
      "unique-items",
      "Accounts must be unique between debit and credit",
      function (value) {
        const account_debit = this.parent.account_debit || [];
        const isUnique = value.every((item) => !account_debit.includes(item));
        return isUnique;
      }
    ),
});

const initialValues = {
  description: "",
  amount: "",
  date: "",
  currency: "",
  account_debit: [],
  account_credt: [],
};

export const JournalAdd = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [currencyData, setCurrencyData] = useState([]);
  const [currencySearch, setCurrencySearch] = useState("");
  const [journal, setJournal] = useState([]);
  const [financial, setFinancial] = useState({});
  const [debit, setDebit] = useState([]);
  const [debitSearch, setDebitSearch] = useState("");
  const [credit, setCredit] = useState([]);
  const [creditSearch, setCreditSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [rate, setRate] = useState({});
  const toaster = useToaster();

  useEffect(() => {
    setTimeout(() => {
      configurationCRUD
        .loadCurrency(15, 0, currencySearch, "code")
        .then((resp) => {
          setCurrencyData(resp.data.results);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type="error" closable>
              {error.message}
            </Message>,
            { placement: "topCenter", duration: 7000 }
          );
        });
      accountingCRUD
        .loadAccount(100, 0, debitSearch, "account_code")
        .then((resp) => {
          setDebit(resp.data.results);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type="error" closable>
              {error.message}
            </Message>,
            { placement: "topCenter", duration: 7000 }
          );
        });
      accountingCRUD
        .loadAccount(100, 0, creditSearch, "account_code")
        .then((resp) => {
          setCredit(resp.data.results);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type="error" closable>
              {error.message}
            </Message>,
            { placement: "topCenter", duration: 7000 }
          );
        });
      configurationCRUD
        .loadActiveFinancialYear()
        .then((resp) => {
          setFinancial(resp.data);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type="error" closable>
              {error.message}
            </Message>,
            { placement: "topCenter", duration: 7000 }
          );
        })
        .finally(() => {});
      adjustmentCRUD
        .loadAllocationJournal(1, 0, "", "-id")
        .then((resp) => {
          setJournal(resp.data.results);
        })
        .catch(() => {});
    }, 50);
  }, [currencySearch, debitSearch, creditSearch]);

  const formik = useFormik({
    initialValues,
    validationSchema: journalVoucherSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoading(true);
      const {
        description,
        amount,
        date,
        currency,
        account_credt,
        account_debit,
      } = values;

      if (account_credt !== account_debit) {
        const dataJournal = {
          name: journal.length
            ? "GJ." + (journal[0].id + 1) + "." + financial.name
            : "GJ." + 1 + "." + financial.name,
          description,
          amount,
          equivalent_amount: amount * parseFloat(rate.rate),
          date,
          currency,
          exchange_rate: rate.id,
          created_by: user.id,
        };

        console.log(account_credt);
        console.log(account_debit);

        // adjustmentCRUD
        //   .addJournal(dataJournal)
        //   .then((resp) => {
        //     const journalId = resp.data.id;

        //     const debitData = {
        //       journal: journalId,
        //       description,
        //       amount,
        //       equivalent_amount: amount * parseFloat(rate.exchange_rate),
        //       account: account_debit,
        //       created_by: user.id,
        //     };

        //     const creditData = {
        //       journal: journalId,
        //       description: description,
        //       amount,
        //       equivalent_amount: amount * parseFloat(rate.exchange_rate),
        //       account: account_credt,
        //       created_by: user.id,
        //     };

        //     const transactionCreditData = {
        //       journal: journalId,
        //       transaction_type: 2,
        //       currency: currency,
        //       exchange_rate: rate.id,
        //       description,
        //       amount,
        //       equivalent_amount: amount * parseFloat(rate.exchange_rate),
        //       account: account_credt,
        //       financial_year: financial.id,
        //       created_by: user.id,
        //     };

        //     const transactionDebitData = {
        //       journal: journalId,
        //       transaction_type: 1,
        //       currency: 1,
        //       exchange_rate: 1,
        //       description: description,
        //       amount,
        //       equivalent_amount: amount * parseFloat(rate.exchange_rate),
        //       account: account_debit,
        //       financial_year: financial.id,
        //       created_by: user.id,
        //     };

        //     adjustmentCRUD.addCreditJournal([creditData]).then(() => {});
        //     adjustmentCRUD
        //       .addJournalTransaction([transactionCreditData])
        //       .then(() => {});
        //     adjustmentCRUD.addDebitJournal([debitData]).then(() => {});
        //     adjustmentCRUD
        //       .addJournalTransaction([transactionDebitData])
        //       .then(() => {});

        //     toaster.push(
        //       <Message showIcon type={"success"} closable>
        //         Thank you...
        //         <b>Journal entry has been added successfully</b>
        //       </Message>,
        //       { placement: "topCenter", duration: 10000 }
        //     );

        //     setLoading(false);
        //     setSubmitting(false);
        //     setIndex(index + 1);
        //     setOpen(false);
        //     resetForm();
        //   })
        //   .catch((error) => {
        //     toaster.push(
        //       <Message showIcon type="error" closable>
        //         {error.message}
        //       </Message>,
        //       { placement: "topCenter", duration: 7000 }
        //     );
        //     setLoading(false);
        //     setSubmitting(false);
        //   });
      } else {
        toaster.push(
          <Message showIcon type="error" closable>
            <p>Sorry...! Debit Account must be different with Credit Account</p>
          </Message>,
          { placement: "topCenter", duration: 7000 }
        );
        setLoading(false);
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (formik.values.currency) {
      setTimeout(() => {
        configurationCRUD
          .loadCurrencyExchangeRate(formik.values.currency)
          .then((resp) => {
            setRate(resp.data);
          })
          .catch(() => {
            toaster.push(
              <Message showIcon type="error" closable>
                <p>Selected currency doesn't has active exchange rate</p>
              </Message>,
              { placement: "topCenter", duration: 7000 }
            );
          });
      }, 50);
    }
  }, [formik.values.currency]);

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="lg">
        <Modal.Header>
          <Modal.Title>New Journal</Modal.Title>
        </Modal.Header>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label>
                      Date <span className="text-danger">*</span>
                    </label>
                    <DatePicker
                      oneTap={true}
                      block={true}
                      cleanable={false}
                      onChange={(e) =>
                        e &&
                        formik.setFieldValue(
                          "date",
                          moment(e).format("yyyy-MM-DD")
                        )
                      }
                    />
                    {formik.touched.date && formik.errors.date && (
                      <div className="fw-light mt-1 fs-15">
                        <span role="alert" className="text-danger">
                          {formik.errors.date}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label>
                      Currency <span className="text-danger">*</span>
                    </label>
                    <SelectPicker
                      data={currencyData?.map((dt) => ({
                        value: dt.id,
                        label: char.toUpperCase(dt.code) + " --- " + dt.name,
                      }))}
                      placeholder="Select currency"
                      onChange={(e) => {
                        formik.setFieldValue("currency", e);
                      }}
                      onSearch={(e) => setCurrencySearch(char.toUpperCase(e))}
                      block
                    />
                    {formik.touched.currency && formik.errors.currency && (
                      <div className="fw-light mt-1 fs-15">
                        <span role="alert" className="text-danger">
                          {formik.errors.currency}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label>
                      Amount <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Amount"
                      {...formik.getFieldProps("amount")}
                    />
                    {formik.touched.amount && formik.errors.amount && (
                      <div className="fw-light mt-1 fs-15">
                        <span role="alert" className="text-danger">
                          {formik.errors.amount}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>
                      Debit Account <span className="text-danger">*</span>
                    </label>
                    <TagPicker
                      data={debit?.map((dt) => ({
                        value: dt.id,
                        label: dt.account_code,
                      }))}
                      placeholder="Select debit account"
                      onChange={(e) => {
                        formik.setFieldValue("account_debit", e);
                      }}
                      onSearch={(e) => setDebitSearch(e)}
                      block
                    />
                    {formik.touched.account_debit &&
                      formik.errors.account_debit && (
                        <div className="fw-light mt-1 fs-15">
                          <span role="alert" className="text-danger">
                            {formik.errors.account_debit}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label>
                      Credit Account <span className="text-danger">*</span>
                    </label>
                    <TagPicker
                      data={credit?.map((dt) => ({
                        value: dt.id,
                        label: dt.account_code,
                      }))}
                      placeholder="Select credit account"
                      onChange={(e) => {
                        formik.setFieldValue("account_credt", e);
                      }}
                      onSearch={(e) => setCreditSearch(e)}
                      block
                    />
                    {formik.touched.account_credt &&
                      formik.errors.account_credt && (
                        <div className="fw-light mt-1 fs-15">
                          <span role="alert" className="text-danger">
                            {formik.errors.account_credt}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12 col-sm-12">
                  <div className="form-group">
                    <label>
                      Description <span className="text-danger">*</span>
                    </label>
                    <textarea
                      placeholder="Journal description"
                      className="form-control"
                      rows={5}
                      {...formik.getFieldProps("description")}
                    />
                    {formik.touched.description &&
                      formik.errors.description && (
                        <div className="fw-light mt-1 fs-15">
                          <span role="alert" className="text-danger">
                            {formik.errors.description}
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Divider />
          <Modal.Footer>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                  <span className="text-danger">*</span> &nbsp; Required field
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-soft-danger me-3"
                    onClick={() => formik.resetForm()}
                  >
                    <span className="bi bi-slash-circle"></span> Clear
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success"
                    disabled={formik.isSubmitting}
                  >
                    {!loading ? (
                      <span className="indicator-label">
                        <span className="bi bi-check-circle"></span> Save
                      </span>
                    ) : (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait.....
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
