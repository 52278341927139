import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { selector } from "../../../api";
import { Divider, Input, Select, notification } from "antd";
import { char } from "../../../utils";
import { configurationCRUD } from "../../../actions/configuration";
import { Message, Modal, useToaster } from "rsuite";

const dictionarySchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  currency: Yup.string().required("This field is required"),
  rate: Yup.number().required("This field is required"),
});

const initialValues = {
  name: "",
  currency: "",
  rate: "",
};

export const ExchangeRateAdd = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [value, setValue] = useState("");
  const toaster = useToaster();

  useEffect(() => {
    setTimeout(() => {
      configurationCRUD
        .loadCurrency(20, 0, value, "name")
        .then((resp) => {
          setCurrency(resp.data.results);
        })
        .catch(() => {});
    }, 500);
  }, [value]);

  const formik = useFormik({
    initialValues,
    validationSchema: dictionarySchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { name, currency, rate } = values;
      const created_by = user.id;
      const data = {
        name,
        currency,
        is_active: true,
        rate,
        created_by,
      };
      setLoading(true);
      setTimeout(() => {
        configurationCRUD
          .addExchangeRate([data])
          .then(() => {
            toaster.push(
              <Message type="success" showIcon header={"Success"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Thank you...!</p>
                  <p className="text-dark fs-12">
                    Exchange rate has been added successfully.
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
            setIndex(index + 1);
            resetForm();
            setLoading(false);
            setSubmitting(false);
            setOpen(false);
          })
          .catch((error) => {
            setLoading(false);
            setSubmitting(false);
            if (error.message === "Network Error") {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">{error.message}</p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            } else if (error.response.data.code) {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">
                      {error.response.data.code[0]}
                    </p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            } else {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">{error.message}</p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            }
          });
      }, 2000);
    },
  });

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="md">
        <Modal.Header>
          <Modal.Title>New Exchange Rate</Modal.Title>
        </Modal.Header>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label className="form-label">
                    Currency <span className="text-danger">*</span>
                  </label>
                  <br />
                  <Select
                    size="large"
                    style={{
                      width: "100%",
                    }}
                    showSearch
                    placeholder="Select currency"
                    optionFilterProp="children"
                    onChange={(e) => formik.setFieldValue("currency", e)}
                    onSearch={(e) => setValue(e)}
                    filterOption={filterOption}
                    options={currency?.map((dt) => ({
                      value: dt.id,
                      label: char.toUpperCaseFirst(dt.name),
                    }))}
                    status={
                      formik.touched.currency &&
                      formik.errors.currency &&
                      "error"
                    }
                  />
                  {formik.touched.currency && formik.errors.currency && (
                    <div className="fw-light mt-1" style={{ fontSize: "14px" }}>
                      <span role="alert" className="text-danger">
                        {formik.errors.currency}
                      </span>
                    </div>
                  )}
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <Input
                      {...formik.getFieldProps("name")}
                      placeholder="Name"
                      className="form-control"
                      size="large"
                      status={
                        formik.touched.name && formik.errors.name && "error"
                      }
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "14px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <label className="form-label">
                    Rate <span className="text-danger">*</span>
                  </label>
                  <br />
                  <Input
                    type="number"
                    {...formik.getFieldProps("rate")}
                    className="form-control"
                    placeholder="Exchange rate"
                    size="large"
                    min={1}
                    style={{
                      width: "100%",
                    }}
                    status={
                      formik.touched.rate && formik.errors.rate && "error"
                    }
                  />
                  {formik.touched.rate && formik.errors.rate && (
                    <div className="fw-light mt-1" style={{ fontSize: "14px" }}>
                      <span role="alert" className="text-danger">
                        {formik.errors.rate}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Divider style={{ marginTop: -10 }} />
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                  <span className="fs-18 text-danger"> * &nbsp;</span> Required
                  field
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                  >
                    <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                    disabled={formik.isSubmitting}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Save
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
