import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { selector } from "../../../api";
import { Divider, Input } from "antd";
import { char } from "../../../utils";
import { accountingCRUD } from "../../../actions/accounting";
import { Message, Modal, useToaster } from "rsuite";

const collectionSchema = Yup.object().shape({
  name: Yup.string().required("This field is required"),
  acronym: Yup.string().required("This field is required"),
});

const initialValues = {
  name: "",
  acronym: "",
  is_active: true,
};

export const CollectionCenterAdd = (props) => {
  const { open, setOpen, index, setIndex } = props;
  const user = selector.User();
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();

  const formik = useFormik({
    initialValues,
    validationSchema: collectionSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { name, acronym, is_active } = values;
      const data = {
        name,
        acronym,
        code: acronym,
        is_active,
        created_by: user.id,
      };
      setLoading(true);
      setTimeout(() => {
        accountingCRUD
          .addCollectionCenter(data)
          .then(() => {
            toaster.push(
              <Message showIcon type={"success"} header={"Sucess"} closable>
                <p className="h6">
                  <p className="fw-bold fs-14">Thank you...!</p>
                  <p className="text-dark fs-12">
                    Collection center has been added successfully.
                  </p>
                </p>
              </Message>,
              { placement: "topEnd", duration: 7000 }
            );
            setIndex(index + 1);
            resetForm();
            setLoading(false);
            setSubmitting(false);
            setOpen(false);
          })
          .catch((error) => {
            setLoading(false);
            setSubmitting(false);
            if (error.message === "Network Error") {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">{error.message}</p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            } else if (error.response.data.code) {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">
                      {error.response.data.code[0]}
                    </p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            } else {
              toaster.push(
                <Message showIcon type={"error"} header={"Error"} closable>
                  <p className="h6">
                    <p className="fw-bold fs-14">Oooh Sorry...!</p>
                    <p className="text-dark fs-12">{error.message}</p>
                  </p>
                </Message>,
                { placement: "topEnd", duration: 7000 }
              );
            }
          });
      }, 2000);
    },
  });

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)} size="md">
        <Modal.Header>
          <Modal.Title>New Collection Center</Modal.Title>
        </Modal.Header>
        <Divider />
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <label className="form-label">
                      Abbreviationn <span className="text-danger">*</span>
                    </label>
                    <Input
                      {...formik.getFieldProps("acronym")}
                      placeholder="Center Abbreviation"
                      className="form-control"
                      size="large"
                      status={
                        formik.touched.acronym &&
                        formik.errors.acronym &&
                        "error"
                      }
                    />
                    {formik.touched.acronym && formik.errors.acronym && (
                      <div
                        className="fw-light mt-1"
                        style={{ fontSize: "14px" }}
                      >
                        <span role="alert" className="text-danger">
                          {formik.errors.acronym}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <label className="form-label">
                    Center Name <span className="text-danger">*</span>
                  </label>
                  <Input
                    {...formik.getFieldProps("name")}
                    placeholder="Center Name"
                    className="form-control"
                    size="large"
                    status={
                      formik.touched.name && formik.errors.name && "error"
                    }
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="fw-light mt-1" style={{ fontSize: "14px" }}>
                      <span role="alert" className="text-danger">
                        {formik.errors.name}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Divider style={{ marginTop: -10 }} />
          <Modal.Footer>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 d-flex justify-content-start">
                  <span className="fs-18 text-danger"> * &nbsp;</span> Required
                  field
                </div>
                <div className="col-md-6 col-sm-12 d-flex justify-content-end">
                  <button
                    onClick={() => setOpen(false)}
                    type="button"
                    className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                  >
                    <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success btn-label waves-effect waves-light rounded-pill"
                    disabled={formik.isSubmitting}
                  >
                    {!loading && (
                      <span className="indicator-label">
                        <i class="ri-check-double-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                        Save
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
