import { useEffect, useState } from "react";
import { TransactionTable } from "../../../shared";
import { invoiceCRUD } from "../../../actions/invoice";
import moment from "moment";
import { Button, ConfigProvider, notification, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { configurationCRUD } from "../../../actions/configuration";
import { Modal } from "rsuite";

export const AccountTransaction = (props) => {
  const { open, setOpen, dataId, account, accDate } = props;
  const [debit, setDebit] = useState([]);
  const [credit, setCredit] = useState([]);
  const [financialYear, setFinancialYear] = useState([]);
  const [yearSearch, setYearSearch] = useState("");
  const [postPerPage] = useState(1000);
  const [date, setDate] = useState(null);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [financial, setFinancial] = useState("");
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [transact, setTransact] = useState(null);
  const [journal, setJournal] = useState(null);
  const [transactOpen, setTransactOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const new_date = moment(accDate, "yyyy-MM-DD").add(1, "d");
  const date1 = accDate ? moment(accDate).format("yyyy-MM-DD") : "";
  const date2 = accDate ? moment(new_date).format("yyyy-MM-DD") : "";

  useEffect(() => {
    setTimeout(() => {
      configurationCRUD
        .loadFinancialYear(10, 0, yearSearch, "")
        .then((resp) => {
          setFinancialYear(resp.data.results);
        })
        .catch(() => {});
    }, 500);
  }, [yearSearch]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      if (dataId !== null) {
        invoiceCRUD
          .loadAccountTransaction(
            postPerPage,
            0,
            dataId,
            3,
            financial,
            accDate ? date1 : start,
            accDate ? date2 : end,
            "",
            ""
          )
          .then((resp) => {
            setDebit(resp.data.results);
          })
          .catch((error) => {
            api.error({
              message: error.message,
              description:
                "Oops! It looks like you're not connected to the network. Please check your network connectivity and try again",
              placement: "topRight",
            });
          })
          .finally(() => {
            setLoading(false);
          });
        invoiceCRUD
          .loadAccountTransaction(
            postPerPage,
            0,
            dataId,
            4,
            financial,
            accDate ? date1 : start,
            accDate ? date2 : end,
            "",
            ""
          )
          .then((resp) => {
            setCredit(resp.data.results);
          })
          .catch((error) => {
            api.error({
              message: error.message,
              description:
                "Oops! It looks like you're not connected to the network. Please check your network connectivity and try again",
              placement: "topRight",
            });
          })
          .finally(() => {
            setLoading(false);
          });

        if (account === "customer") {
          invoiceCRUD
            .loadCustomerAccountId(dataId)
            .then((resp) => {
              setInfo(resp.data);
            })
            .catch((error) => {
              api.error({
                message: error.message,
                description:
                  "Oops! It looks like you're not connected to the network. Please check your network connectivity and try again",
                placement: "topRight",
              });
            })
            .finally(() => {
              setLoading(false);
            });
        } else if (account === "institution") {
          invoiceCRUD
            .loadInstitutionAccountId(dataId)
            .then((resp) => {
              setInfo(resp.data);
            })
            .catch((error) => {
              api.error({
                message: error.message,
                description:
                  "Oops! It looks like you're not connected to the network. Please check your network connectivity and try again",
                placement: "topRight",
              });
            })
            .finally(() => {
              setLoading(false);
            });
        }
      }
    }, 500);
  }, [dataId, start, end, financial, accDate]);

  return (
    <>
      {contextHolder}
      <Modal open={open} onClose={() => setOpen(false)} size="full">
        <Modal.Header>
          <Modal.Title>Account Transactions</Modal.Title>
        </Modal.Header>
        <Divider />
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <TransactionTable
                  debit={debit}
                  credit={credit}
                  info={info}
                  loading={loading}
                  account={account}
                  setOpen={setOpen}
                  setTransactOpen={setTransactOpen}
                  setJournal={setJournal}
                  setTransact={setTransact}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Divider style={{ marginTop: -10 }} />
        <Modal.Footer>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-11 col-sm-12 d-flex justify-content-start"></div>
              <div className="col-md-1 col-sm-12 d-flex justify-content-end">
                <button
                  onClick={() => setOpen(false)}
                  type="button"
                  className="btn btn-soft-danger btn-label waves-effect waves-light rounded-pill me-3"
                >
                  <i class="ri-close-circle-line label-icon align-middle rounded-pill fs-16 me-2"></i>{" "}
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
