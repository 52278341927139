import { useLocation } from "react-router-dom";
import { CollectionCenterContent } from "../../components/accounting";
import {
  InstitutionAccount,
  CollectionItem,
} from "../../components/accounting/collectionCenter/item";
import { CenterFeeContent } from "../../components/accounting/collectionCenter/fee";

export default function CollectionCenter() {
  let location = useLocation();

  return (
    <>
      {location.state ? (
        location.state.page === "account" ? (
          <InstitutionAccount />
        ) : location.state.page === "item" ? (
          <CollectionItem />
        ) : location.state.page === "fee" ? (
          <CenterFeeContent />
        ) : (
          <CollectionCenterContent />
        )
      ) : (
        <CollectionCenterContent />
      )}
    </>
  );
}
