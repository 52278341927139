import { useEffect, useState } from "react";
import { TableShared } from "../../../shared";
import { adjustmentCRUD } from "../../../actions/adjustment";
import { Message, Notification, useToaster } from "rsuite";
import { char, number } from "../../../utils";
import moment from "moment";
import { Button, Dropdown, Space } from "antd";
import { MoreOutlined, DollarOutlined, EyeOutlined } from "@ant-design/icons";
import { AllocationUpload } from "./add/upload";
import { useNavigate } from "react-router-dom";

export const AllocationContent = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const [search, setSearch] = useState("");
  const [postPerPage, setPostPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [nextPage, setNextPage] = useState(0);
  const [index, setIndex] = useState(0);
  const toaster = useToaster();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      adjustmentCRUD
        .loadAllocation(postPerPage, nextPage, search, "-id")
        .then((resp) => {
          setData(resp.data.results);
          setCount(resp.data.count);
        })
        .catch((error) => {
          toaster.push(
            <Message showIcon type={"error"} header={"Error"} closable>
              <p className="h6">
                <p className="fw-bold fs-14">Oooh Sorry...!</p>
                <p className="text-dark fs-12">
                  Something went wrong, Check your network connection.
                </p>
              </p>
            </Message>,
            { placement: "topEnd", duration: 7000 }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500);
  }, [index, postPerPage, nextPage, page, search]);

  const defaultColumns = [
    {
      key: "sn",
      title: "#",
      dataIndex: "sn",
      fixed: true,
      width: 150,
    },
    {
      key: "date",
      title: "Date",
      dataIndex: "date",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "academic",
      title: "Academic Year",
      dataIndex: "academic",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "board",
      title: "Loan Board",
      dataIndex: "board",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "batch_no",
      title: "Batch No.",
      dataIndex: "batch_no",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "amount",
      title: "Amount",
      dataIndex: "amount",
      sortable: true,
      flexGrow: 1,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      sortable: true,
      width: 100,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 100,
      render: (record) => (
        <Dropdown
          menu={{
            items: record.invoice
              ? [
                  {
                    label: "View",
                    key: "view",
                    icon: <EyeOutlined />,
                  },
                ]
              : [
                  {
                    label: "Invoice",
                    key: "invoice",
                    icon: <DollarOutlined />,
                  },
                  {
                    label: "View",
                    key: "view",
                    icon: <EyeOutlined />,
                  },
                ],
            onClick: (event) => {
              switch (event.key) {
                case "view":
                  navigate("/allocation", {
                    state: { page: "item", dataId: record.id },
                  });
                  break;
                default:
              }
            },
          }}
          trigger={["click"]}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <Space>
            <Button type="dashed" icon={<MoreOutlined />} size={"middle"} />
          </Space>
        </Dropdown>
      ),
    },
  ];

  const dataItem = data?.map((dt, i) => ({
    id: dt.id,
    sn: i + 1,
    invoice: dt.is_invoiced,
    date: dt.date ? moment(dt.date).format("ddd. DD MM YYYY") : "N/A",
    academic: dt.academic_year
      ? char.toUpperCase(dt.academic_year.name)
      : "N/A",
    board: dt.loan_board ? char.toUpperCase(dt.loan_board.acronym) : "N/A",
    batch_no: dt.batch_no ? dt.batch_no : "N/A",
    amount: dt.amount
      ? number.fNumberDoublePoint(dt.amount)
      : number.fNumberDoublePoint(0.0),
    status: dt.is_invoiced ? (
      <span class="badge badge-soft-success badge-border">
        {char.toTitleCase("invoiced")}
      </span>
    ) : (
      <span class="badge badge-soft-danger badge-border">
        {char.toTitleCase("not invoiced")}
      </span>
    ),
  }));

  const button = (
    <button
      className="btn btn-primary bg-gradient btn-border"
      onClick={() => setOpenUpload(true)}
    >
      <span className="bi bi-plus-circle la-lg"></span> &nbsp; New Allocation
    </button>
  );

  return (
    <>
      {/* <AllocationAdd
        open={open}
        setOpen={setOpen}
        index={index}
        setIndex={setIndex}
      /> */}
      <AllocationUpload
        open={openUpload}
        setOpen={setOpenUpload}
        index={index}
        setIndex={setIndex}
      />
      {/* <AllocationItemContent open={view} setOpen={setView} dataId={dataId} /> */}
      <div className="container-fluid">
        <div className="row mb-2" style={{ marginTop: -30 }}>
          <div className="col-12">
            <p className="h6 fs-18 fw-bold text-dark">Allocations</p>
          </div>
          <hr />
        </div>
        <TableShared
          setOpen={setOpen}
          loading={loading}
          data={dataItem}
          setData={setData}
          page={page}
          setPage={setPage}
          count={count}
          postsPerPage={postPerPage}
          setePostPerPage={setPostPerPage}
          setNextPage={setNextPage}
          value={search}
          setValue={setSearch}
          defaultColumns={defaultColumns}
          button={button}
          link={"#"}
          showButton={true}
          setLoading={setLoading}
        />
      </div>
    </>
  );
};
